const PANDORA_PERSIST_KEY = 'persist:pandora-root';

export const isMobile = () => {
  let userAgentInfo = navigator.userAgent;
  // 去除ipad
  let Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod"];
  let flag = false;
  for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = true;
          break;
      }
  }
  return flag;
}


export const formValuesValid = (values: any) => {
  return !Boolean(Object.keys(values).some(value => !value));
}

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(PANDORA_PERSIST_KEY);
    if (serializedState === null) {
      return {};
    } else {
      return JSON.parse(serializedState);
    }
  } catch (err) {
    return {};
  }
};

export const wrapText = (text:string, width:number) => {
  if(!text){
    return '';
  }
  const splitLabel = text.split(' ');
  const maxCodeLength = Math.ceil(width/12);
  const codeGroup = splitLabel.reduce((all:Array<any>, current, index) => {
    if(all.length){
      if(all[all.length-1].join(' ').length + current.length > maxCodeLength ){
        return [...all, [current]];
      }else{
        const allValues = all;
        const latestOne = allValues.pop();
        return [
          ...all,
          latestOne.concat(current)
       ]
      }
    }else{
      return [[current]];
    }
  },[[]])
  return codeGroup.map(item => item.join(' ')).join('\n')
}

export const createMarkup = (htmlString: string) => {
  return {__html: htmlString};
}

export const parseString = (str: string) => {
  if(str) {
    return JSON.parse(str);
  }
  return {}
}

export const isSupportBrowsers = () => {
  const browserWhiteLists= ['Chrome', 'Firefox', 'Safari'];
  return browserWhiteLists.some(item => window.navigator.userAgent.indexOf(item) > -1);
}