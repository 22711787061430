import { createSlice, createSelector, createAsyncThunk, Reducer } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import pandora from '../../../api'
import {checkToken} from '../../user/reducer';
import moment from 'moment';

interface DailyFlashProps {
  dailyFlashData: {
    [key:string]: any[]
  },
  loading: boolean,
  detailsLoading: boolean,
  details: any,
  date: string,
  newsId:number,
  userTimePoints: Array<string>, 
}

const initialState: DailyFlashProps = {
  dailyFlashData: {},
  loading: false,
  detailsLoading: false,
  details: {},
  date: moment().format('YYYY-MM-DD'),
  userTimePoints: [],
  newsId:0
}

export const fetchDailyFlash = createAsyncThunk(
  'dailyFlash/fetchDailyFlashNews',
  async (params:any, thunkAPI) => {
    const {date} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchDailyFlashNews(params);
      return {
        date,
        list: result
      }
    })
  } 
);

export const fetchUserTimePoints = createAsyncThunk(
  'daliy-flash/fetch-user-time-points',
  (args: void, thunkAPI) => thunkAPI.dispatch(checkToken()).then(() => pandora.fetchUserDailyFlashTimePoints())
)

export const fetchDailyFlashNewsDetailById = createAsyncThunk(
  'daliy-flash/fetchDailyFlashNewsDetailById',
  async (params:{id:string}, thunkAPI) => {
    const {id} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchDetailByNewsId({id:id});
      return {
        id,
        ...result
      }
    })
  }
)

export const addTimePoint = createAsyncThunk(
  'daliy-flash/add-time-point',
  (times:Array<string>, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => pandora.addUserDailyFlashTimePoint(times)).then(resp => {
      return thunkAPI.dispatch(fetchUserTimePoints())
    })
  }
)

export const dailyFlash = createSlice({
  name:'dailyFlash',
  initialState,
  reducers: {
    setDate: (state, {payload}) => {
      state.date = payload;
    },
    setNewsId: (state, {payload}) => {
      state.newsId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDailyFlash.pending, (state) => {
      state.loading = true;
    }).addCase(fetchDailyFlash.fulfilled, (state, {payload}) => {
      state.loading = false;
      const {date, list} = payload
      state.dailyFlashData[date] = list;
    }).addCase(fetchDailyFlashNewsDetailById.pending, (state) => {
      state.detailsLoading = true;
    }).addCase(fetchDailyFlashNewsDetailById.fulfilled, (state, {payload}) => {
      state.detailsLoading = false;
      const {data,code,id} =payload;
      if(!code){
        state.details[id] = data;
      }
    }).addCase(fetchUserTimePoints.fulfilled, (state, {payload}) => {
      if(!payload.code){
        state.userTimePoints = payload.result;
      }
    });
  },
})

export const {setNewsId, setDate} = dailyFlash.actions;

const selectBaseState = (state: RootState) => state.dailyFlash;
export const selectFetchLoadingStatus = createSelector(selectBaseState, state => state.loading)
export const selectNewsId = createSelector(selectBaseState, state => state.newsId)
export const selectFetchDetailsLoadingStatus = createSelector(selectBaseState, state => state.detailsLoading)
export const selectDailyFlashNews = (date:string) => createSelector(selectBaseState, state => {
  return state.dailyFlashData[date] || []
});
export const selectUserTimePoints = createSelector(selectBaseState, state => {
  return state.userTimePoints || []
})
export const selectNewsDetail = (id:string) => createSelector(selectBaseState, state => state.details[id] || {})
export const selectFetchDate = createSelector(selectBaseState, state => state.date)

export default dailyFlash.reducer as Reducer<typeof initialState>;
