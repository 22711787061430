import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import pandora from '../../../api';
import {checkToken} from '../../user/reducer';

interface PriceEventProps {
  loading: boolean,
  activeRoom: string,
  roomsActiveSymbols: {
    [key: string]: {
      symbol: string,
      companyId: number
    };
  },
  events: {
    [key:string]: any
  },
  filters: any,
}

const initialState: PriceEventProps = {
  loading: false,
  roomsActiveSymbols:{},
  activeRoom:'',
  events: {},
  filters: {
    risk: true, 
    opportunity:true,
    direct: true, 
    indirect: true
  }
}

export const fetchCompanyStockPrice = createAsyncThunk(
  'price-event/fetch-company-stock-event',
  async (params:any, thunkAPI) => {
    const {symbol, ...restParams} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchCompanyStockPrice(restParams);
      return {
        ...result.data,
        symbol
      }
    })
  }
)
export const fetchCompanyStockEvent = createAsyncThunk(
  'price-event/fetch-company-stock-event',
  async (params:any, thunkAPI) => {
    const {symbol, page, size,  ...restParams} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchCompanyStockEvent({...restParams, page, size});
      return {
        ...result.data,
        symbol,
        page,
        size
      }
    })
  }
)

export const priceEvent = createSlice({
  name:'priceEvent',
  initialState,
  reducers: {
    setActiveSymbol: (state, {payload}) => {
      const {symbol, room, companyId} = payload;
      state.roomsActiveSymbols[room] = {
        symbol: symbol,
        companyId: companyId
      }
    },
    setActiveRoom: (state, {payload}) => {
      state.activeRoom = payload;
    },
    updateFitlers: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        ...payload
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompanyStockEvent.pending, (state) => {
      state.loading = true;
    }).addCase(fetchCompanyStockEvent.fulfilled, (state, {payload}) => {
      const {symbol, page, eventSummaryList, size} = payload;
      if(page > 0){
        state.events[symbol] = {
          dataList: state.events[symbol].dataList.concat(eventSummaryList),
          page: page + 1, 
          hasMore: !Boolean(eventSummaryList.length < size)
        };
      }else{
        state.events[symbol] = {
          dataList: eventSummaryList,
          page: page + 1, 
          hasMore: !Boolean(eventSummaryList.length < size)
        };
      }
      state.loading = false;
    })
  }
})

export const {setActiveSymbol, setActiveRoom, updateFitlers} = priceEvent.actions;

const selectBaseState =  (state: RootState)  => state.priceEvent;
export const selectActiveRoom = createSelector(selectBaseState, state => state.activeRoom);
export const selectLoadingStatus = createSelector(selectBaseState, state => state.loading);
export const selectActiveSymbol = (room:string) => createSelector(selectBaseState, baseState => {
  if(!baseState.roomsActiveSymbols[room]){
    return ''
  }
  return baseState.roomsActiveSymbols[room].symbol || ''
});
export const selectActiveCompanyId = (room:string) => createSelector(selectBaseState, baseState => {
  if(!baseState.roomsActiveSymbols[room]){
    return ''
  }
  return baseState.roomsActiveSymbols[room].companyId || ''
});
export const selectFilters = createSelector(selectBaseState, state => state.filters);
export const selectEventBySymbol = (symbol:string) => createSelector(selectBaseState, (state) => {
  if(!symbol || !state.events[symbol]){
    return [];
  }
  return state.events[symbol].dataList || [];
})
export const selectEventOptionsBySymbol = (symbol:string) => createSelector(selectBaseState, (state) => {
  if(!symbol || !state.events[symbol]){
    return {
      page: 0, 
      hasMore : true
    };
  }
  const {dataList, ...restProps} = state.events[symbol]
  return restProps || {};
})

export default priceEvent.reducer;
