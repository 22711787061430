import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import pandora from '../../../api';
import {checkToken} from '../../user/reducer';

interface StateProps {
  isSearch: boolean,
  loading: {
    [key: string]: boolean;
  },
  orgInfo:any,
  searchList: Array<string>
  investmentTop: Array<any>, 
  industry: Array<any>,
  subdomain: Array<any>,
  investmentRotation: Array<any>,
  investmentList: {
    dataList:Array<any>,
    total:number
  }
  listClass: string,
  companyInfo:any,
  thisRoundInvestor:Array<any>,
  eventHistory:Array<any>,
  recommend:Array<any>,
  industryCompare:{
    dataList:Array<any>,
    total:number
  },
  companyIndustry:Array<any>
}

const initialState: StateProps = {
  isSearch: false,
  loading: {},
  searchList: [],
  investmentTop: [],
  orgInfo:{
    orgId:'',
    orgName:'',
  },
  industry: [],
  subdomain: [],
  investmentRotation: [],
  investmentList:{
    dataList:[],
    total:0
  },
  listClass:'',
  companyInfo:{},
  thisRoundInvestor:[],
  eventHistory:[],
  recommend:[],
  industryCompare:{
    dataList:[],
    total:0
  },
  companyIndustry:[]
}

export const searchInvestmentOrg = createAsyncThunk(
  '/investment-financing-monitor/fetch-search-investment-org',
  async (params:any, thunkAPI) => thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchSearchInvestmentOrg({...params,size:20,page:0}))
)

export const fetchGetInvestmentList = createAsyncThunk(
  '/investment-financing-monitor/fetch-get-investment-list',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetInvestmentList(params);
      return {
        ...result
      }
    })
  }
)
export const fetchGetTop10 = createAsyncThunk(
  '/investment-financing-monitor/fetch-get-yop10',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetTop10(params);
      return {
        ...result
      }
    })
  }
)
export const fetchGetIndustry = createAsyncThunk(
  '/investment-financing-monitor/fetch-get-industry',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetIndustry(params);
      return {
        ...result
      }
    })
  }
)
export const fetchGetCompanyIndustry = createAsyncThunk(
  '/investment-financing-monitor/fetch-get-company-industry',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetIndustry(params);
      return {
        ...result
      }
    })
  }
)
export const fetchGetSubdomain = createAsyncThunk(
  '/investment-financing-monitor/fetch-get-subdomain',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetSubdomain(params);
      return {
        ...result
      }
    })
  }
)
export const fetchGetInvestmentRotation = createAsyncThunk(
  '/investment-financing-monitor/fetch-get-investment-rotation',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetInvestmentRotation(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetCompanyInfoById = createAsyncThunk(
  '/investment-financing-monitor/fetch-get-company-info-by-id',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyInfoById(params);
      return {
        ...result
      }
    })
  }
)
export const fetchGetThisRoundInvestor = createAsyncThunk(
  '/investment-financing-monitor/fetch-get-this-round-investor',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetThisRoundInvestor(params);
      return {
        ...result
      }
    })
  }
)
export const fetchGetEventHistory = createAsyncThunk(
  '/investment-financing-monitor/fetch-get-event-history',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetEventHistory(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetRecommend = createAsyncThunk(
  '/investment-financing-monitor/fetch-get-recommend',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetRecommend(params);
      return {
        ...result
      }
    })
  }
)
export const fetchGetIndustryCompare = createAsyncThunk(
  '/investment-financing-monitor/fetch-get-industry-compare',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetIndustryCompare(params);
      return {
        ...result
      }
    })
  }
)

export const investmentFinancingMonitor = createSlice({
  name:'investmentFinancingMonitor',
  initialState,
  reducers: {
    setSearchStatus: (state, {payload}) => {
      state.isSearch = payload;
    },
    setOrgInfo : (state, {payload}) => {
      state.orgInfo = payload;
    },
    setListClass : (state, {payload}) => {
      state.listClass = payload;
    },
  },
  extraReducers:(builder) => {
    builder.addCase(searchInvestmentOrg.pending, (state) => {
      state.loading.searchWord = true;
    }).addCase(searchInvestmentOrg.fulfilled, (state, {payload}) => {
      const {data, code} = payload;
      if(code === '0'){
        state.searchList = data.dataList
      }
      state.loading.searchWord = false;
    }).addCase(fetchGetTop10.fulfilled, (state, {payload}) => {
      const {data, code} = payload;
      if(code === '0'){

        state.investmentTop =  (data as Array<any>).map(item => ({
          ...item, 
          type:'Top 10 投资机构，最近3个月投资公司数量',
          num: item.num, 
          name: item.orgName,
        })).flat();

      }
    }).addCase(fetchGetIndustry.fulfilled, (state, {payload}) => {
      const {data, code} = payload;
      if(code === '0'){
        state.industry = data.filter((item:any) => item !== null)
      }
    }).addCase(fetchGetCompanyIndustry.fulfilled, (state, {payload}) => {
      const {data, code} = payload;
      if(code === '0'){
        state.companyIndustry = data.filter((item:any) => item !== null)
      }
    }).addCase(fetchGetSubdomain.fulfilled, (state, {payload}) => {
      const {data, code} = payload;
      if(code === '0'){
        state.subdomain = data.filter((item:any) => item !== null)
      }
    }).addCase(fetchGetInvestmentRotation.fulfilled, (state, {payload}) => {
      const {data, code} = payload;
      if(code === '0'){
        state.investmentRotation = data
      }
    }).addCase(fetchGetInvestmentList.pending, (state) => {
      state.loading.fetchGetInvestmentList = true;
    }).addCase(fetchGetInvestmentList.fulfilled, (state, {payload}) => {
      const {data, code} = payload;
      if(code === '0'){
        data.dataList.forEach((item:any) => {
          item.orgListName = [] as any
          item.orgList.forEach((orgListItem:any) => {
            item.orgListName.push(orgListItem.name)
          });
        });
        state.investmentList = {
          dataList:data.dataList,
          total:data.total
        }
      }
      state.loading.fetchGetInvestmentList = false;
    }).addCase(fetchGetCompanyInfoById.pending, (state) => {
      state.loading.fetchGetCompanyInfoById = true;
    }).addCase(fetchGetCompanyInfoById.fulfilled, (state, {payload}) => {
      const {data, code} = payload;
      if(code === '0'){
        state.companyInfo = data
      }
      state.loading.fetchGetCompanyInfoById = false;
    }).addCase(fetchGetThisRoundInvestor.fulfilled, (state, {payload}) => {
      const {data, code} = payload;
      if(code === '0'){
        state.thisRoundInvestor = data
      }
    }).addCase(fetchGetEventHistory.pending, (state) => {
      state.loading.fetchGetEventHistory = true;
    }).addCase(fetchGetEventHistory.fulfilled, (state, {payload}) => {
      const {data, code} = payload;
      if(code === '0'){
        data.forEach((item:any) => {
          item.orgListName = [] as any
          item.orgList.forEach((orgListItem:any) => {
            item.orgListName.push(orgListItem.name)
          });
        });
        state.eventHistory = data
        state.loading.fetchGetEventHistory = false;
      }
    }).addCase(fetchGetRecommend.fulfilled, (state, {payload}) => {
      const {data, code} = payload;
      if(code === '0'){
        state.recommend = data
      }
    }).addCase(fetchGetIndustryCompare.fulfilled, (state, {payload}) => {
      const {data, code} = payload;
      if(code === '0'){
        state.industryCompare = {
          dataList:data.dataList,
          total :Number(data.total)
        }
      }
    })
    
  }
})



export const {setListClass, setOrgInfo, setSearchStatus} = investmentFinancingMonitor.actions;

const selectBaseState = (state: RootState)  => state.investmentFinancingMonitor;
export const selectLoadingByType = (type:string) => createSelector(selectBaseState, state => state.loading[type] || false);
export const selectSearchList = createSelector(selectBaseState, state => state.searchList || [])
export const selectInvestmentTop = createSelector(selectBaseState, state => state.investmentTop || [])
export const selectSearchingStatus = createSelector(selectBaseState, state => state.isSearch)
export const selectOrgInfo = createSelector(selectBaseState, state => state.orgInfo)
export const selectIndustry= createSelector(selectBaseState, state => state.industry)
export const selectInvestmentRotation = createSelector(selectBaseState, state => state.investmentRotation)
export const selectInvestmentList = createSelector(selectBaseState, state => state.investmentList || {dataList:[],total:0})
export const selectSubdomain = createSelector(selectBaseState, state => state.subdomain || [])
export const selectListClass = createSelector(selectBaseState, state => state.listClass || '')
export const selectCompanyInfo = createSelector(selectBaseState, state => state.companyInfo)
export const selectThisRoundInvestor = createSelector(selectBaseState, state => state.thisRoundInvestor || [])
export const selectEventHistory = createSelector(selectBaseState, state => state.eventHistory || [])
export const selectRecommend = createSelector(selectBaseState, state => state.recommend || [])
export const selectIndustryCompare = createSelector(selectBaseState, state => state.industryCompare || {dataList:[],total:0})
export const selectCompanyIndustry = createSelector(selectBaseState, state => state.companyIndustry || [])

export default investmentFinancingMonitor.reducer;
