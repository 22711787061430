import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import pandora from '../../../api';
import {checkToken} from '../../user/reducer';

interface StateProps {
  loading: {
    [key:string]: boolean
  },
  searchOptions:any,
  searchList: Array<any>,
  isSearching: boolean,
  listedCompany:Array<any>,
  unlistedCompany:Array<any>,
  searchNews:Array<any>,
  wordExplain:any,
  product:Array<any>,
  technology:Array<any>,
}

const initialState: StateProps = {
  loading: {},
  searchList: [],
  isSearching: false,
  listedCompany:[],
  unlistedCompany:[],
  searchNews:[],
  wordExplain:null,
  searchOptions:null,
  product:[],
  technology:[],
}


export const fetchSearchHint = createAsyncThunk(
  'huakong/fetch-search-hint',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchSearchHint(params))
  }
)

export const fetchKeyword = createAsyncThunk(
  'huakong/fetch-keyword',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchKeyword(params))
  }
)
//非行业搜上市
export const fetchKeywordSearchListedCompany = createAsyncThunk(
  'huakong/fetch-keyword-search-listed-company',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchKeywordSearchListedCompany(params))
  }
)
//行业搜上市
export const fetchSearchListedCompany = createAsyncThunk(
  'huakong/fetch-search-listed-company',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchSearchListedCompany(params))
  }
)
//非上市
export const fetchKeywordSearchCompany = createAsyncThunk(
  'huakong/fetch-keyword-search-company',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchKeywordSearchCompany(params))
  }
)

export const fetchSearchNews = createAsyncThunk(
  'huakong/fetch-search-news',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchSearchNews(params))
  }
)
//技术获取 技术前沿
export const fetchGetTechnologyCompany = createAsyncThunk(
  'huakong/fetch-get-technology-company',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetTechnologyCompany(params))
  }
)
//产品获取技术前沿
export const fetchGetProductCompany = createAsyncThunk(
  'huakong/fetch-get-product-company',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetProductCompany(params))
  }
)
//公司获取技术前沿
export const fetchGetCompanyTechnology = createAsyncThunk(
  'huakong/fetch-get-company-technology',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetCompanyTechnology(params))
  }
)
//公司获取产品
export const fetchGetCompanyProduct = createAsyncThunk(
  'huakong/fetch-get-company-product',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetCompanyProduct(params))
  }
)
//行业获取技术前沿
export const fetchGetIndustryTechnology = createAsyncThunk(
  'huakong/fetch-get-industry-technology',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetIndustryTechnology(params))
  }
)
//行业获取产品
export const fetchGetIndustryProduct = createAsyncThunk(
  'huakong/fetch-get-industry-product',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchGetIndustryProduct(params))
  }
)

export const fetchSaveKeyword = createAsyncThunk(
  'huakong/fetch-save-keyword',
  async (params:any) => pandora.fetchSaveKeyword(params)
);


export const huakong = createSlice({
  name:'huakong',
  initialState,
  reducers: {
    setSearching: (state, { payload }) => {
      state.isSearching = payload
    },
    setSearchOptions: (state, { payload }) => {
      state.searchOptions = payload
    },
    setProduct: (state, { payload }) => {
      state.product = payload
    },
    setTechnology: (state, { payload }) => {
      state.technology = payload
    },
  },
  extraReducers:(builder) => {
    builder.addCase(fetchSearchHint.fulfilled, (state, {payload}) => {
      const {data,code} = payload
      if(code === '0'){
        state.searchList = data
      }
    }).addCase(fetchKeyword.pending, state => {
      state.loading.fetchKeyword = true
      state.product = []
      state.technology = []
      state.listedCompany= []
      state.unlistedCompany = []
      state.searchNews = []
    }).addCase(fetchKeyword.fulfilled, (state, {payload}) => {
      const {data,code} = payload
      if(code === '0'){
        state.wordExplain = data
        state.loading.fetchKeyword = false
      }
    }).addCase(fetchKeywordSearchListedCompany.fulfilled, (state, {payload}) => {
      const {data,code} = payload
      if(code === '0'){
        state.listedCompany = data
      }
    }).addCase(fetchSearchListedCompany.fulfilled, (state, {payload}) => {
      const {data,code} = payload
      if(code === '0'){
        state.listedCompany = data
      }
    }).addCase(fetchKeywordSearchCompany.fulfilled, (state, {payload}) => {
      const {data,code} = payload
      if(code === '0'){
        state.unlistedCompany = data
      }
    }).addCase(fetchSearchNews.fulfilled, (state, {payload}) => {
      const {data,code} = payload
      if(code === '0'){
        state.searchNews = data.dataList
      }
    }).addCase(fetchGetProductCompany.fulfilled, (state, {payload}) => {
      const {data,code} = payload
      if(code === '0'){
        state.technology = data
      }
    }).addCase(fetchGetTechnologyCompany.fulfilled, (state, {payload}) => {
      const {data,code} = payload
      if(code === '0'){
        state.technology = data
      }
    }).addCase(fetchGetIndustryProduct.fulfilled, (state, {payload}) => {
      const {data,code} = payload
      if(code === '0'){
        state.product = data
      }
    }).addCase(fetchGetIndustryTechnology.fulfilled, (state, {payload}) => {
      const {data,code} = payload
      if(code === '0'){
        state.technology = data
      }
    }).addCase(fetchGetCompanyProduct.fulfilled, (state, {payload}) => {
      const {data,code} = payload
      if(code === '0'){
        state.product = data
      }
    }).addCase(fetchGetCompanyTechnology.fulfilled, (state, {payload}) => {
      const {data,code} = payload
      if(code === '0'){
        state.technology = data
      }
    })
  }
})

export const {setProduct,setTechnology,setSearchOptions,setSearching} = huakong.actions;

const selectBaseState = (state: RootState)  => state.huakong;
export const selectLoadingByType = (type:string) =>  createSelector(selectBaseState, state => state.loading[type] || false);
export const selectSearchList = createSelector(selectBaseState, state => state.searchList || [])
export const selectIsSearching = createSelector(selectBaseState, state => state.isSearching)
export const selectListedCompany = createSelector(selectBaseState, state => state.listedCompany)
export const selectUnlistedCompany = createSelector(selectBaseState, state => state.unlistedCompany)
export const selectSearchNews = createSelector(selectBaseState, state => state.searchNews)
export const selectWordExplain = createSelector(selectBaseState, state => state.wordExplain)
export const selectSearchOptions = createSelector(selectBaseState, state => state.searchOptions)
export const selectTechnology = createSelector(selectBaseState, state => state.technology)
export const selectProduct = createSelector(selectBaseState, state => state.product)



export default huakong.reducer;
