import {createTheme, alpha} from '@material-ui/core/styles';

const modalDivider = '#444650';
const borderColor = '#373C4D';
const primaryColor = '#FF9900'
const theme = createTheme({
  palette:{
    primary:{
      main:primaryColor,
      dark: "rgb(255, 153, 0, 0.3)",
      contrastText: '#fff',
    },
    secondary:{
      main:'#048739'
    },
    background:{
      default:'#0F0F0F',
      paper: '#212426',
    },
    text:{
      primary:'#D1D4DC',
      secondary:'#787B86',
      hint:'#4B4E4F',
      disabled:'#8c8c8c'
    },
    error:{
      main:'#D03C38'
    },
    success:{
      main:'#048739',
    },
    warning:{
      main:'#E38B34'
    },
    info:{
      main:'#4499CE'
    },
    action:{
      disabled:'#8c8c8c',
      disabledBackground:'#43464D',
      hover:'#272B3D',
      selectedOpacity: 0.5
    },
    divider: modalDivider,
    grey:{
      100: borderColor,
      400: '#CFD0D0'
    },
  },
  typography:{
    h6:{
      fontSize: '1.125rem',  
    }
  },
  overrides:{
    MuiInputBase:{
      root:{
        lineHeight: 1
      },
      input: {
        padding: '6px 0',
        height: 'auto',
        fontSize: 14,
      }
    },
    MuiTooltip:{
      tooltip: {
        color: '#D1D4DC',
        backgroundColor: '#272B3D',
        fontSize:'12px',
        padding: '8px'
      },
      arrow:{
        color: '#272B3D',
      }
    },
    MuiTableCell:{
      root: {
        borderBottom: 0, 
        padding: `8px 4px`
      }
    },
    MuiDrawer:{
      paper: {
        width: 300,
        padding: '16px',
        boxSizing: 'border-box',
      }
    },
    MuiCardContent:{
      root:{
        padding: 8
      }
    },
    MuiButton:{
      root:{
        whiteSpace: 'nowrap',
        textTransform: 'capitalize'
      },
      startIcon:{
        marginRight: 4
      },
      contained: {
        backgroundColor: '#212426', 
        border: '1px solid #373C4D',
        color: 'white',
        '&:hover':{
          backgroundColor: alpha('#212426', 0.05), 
        }
      },
      containedPrimary:{
        borderWidth: 0,
      }
    },
    MuiContainer:{
      root:{
        width: 1000
      }
    },
    MuiTabs:{
      root: {
        minHeight: 24
      },
      scrollButtons:{
        width: 'auto'
      }
    },
    MuiTab:{
      root:{
        minWidth: 'auto !important',
        maxWidth: 'auto',
        padding: '6px 12px',
        textTransform: 'capitalize',
        minHeight:'auto'
      }
    },
    MuiChip:{
      root:{
        color: '#D1D4DC'
      },
      label:{
        paddingLeft: 0, 
        paddingRight: 0
      },
      outlined:{
        border: `1px solid ${borderColor}`
      }
    },
    MuiInput:{
      underline:{
        '&::before':{
          borderBottomColor: '#8C8E8F'
        }
      }
    },
    MuiListSubheader:{
      gutters:{
        paddingLeft: 8,
        paddingRight: 8
      }
    },
    MuiDialog:{
      paper: {
        background: '#212426', 
        minWidth: 256,
      },
      paperWidthSm:{
        maxWidth: 750
      }
    },
    MuiDialogTitle:{
      root: {
       borderBottom: `1px solid ${modalDivider}` ,
       padding: '8px 16px'
      },
    },
    MuiDialogContent:{
      root: {
        padding: '16px 24px',
      }
    },
    MuiDialogActions:{
      root: {
        borderTop: `1px solid ${modalDivider}` ,
        padding: '8px 16px',
      }
    },
    MuiSvgIcon:{
      fontSizeSmall:{
        fontSize: '1rem' 
      },
    },
    MuiOutlinedInput:{
      root:{
        border: `1px solid ${borderColor}`,
        '&:hover':{
          border: `1px solid ${borderColor}`,
        }
      }
    },
    MuiMenuItem:{
      root:{
        backgroundColor: 'transparent',
        fontSize: '0.875rem',
        '&$selected': {
          color: primaryColor
        }
      },
    }
  }
})

export default theme;