import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import pandora from '../../api';
import {checkToken} from '../user/reducer';

interface SearchProps {
  loading: boolean,
  relatedNewsDetails: {
    [key: string]: any
  },
  newsDetails: {
    [key: string]: any
  },
  portNewsDetails: {
    [key: string]: any
  }
}

const initialState: SearchProps = {
  loading: false,
  relatedNewsDetails: {},
  newsDetails: {},
  portNewsDetails: {}
}


export const fetchRelatedNewsDetails = createAsyncThunk(
  '/bigTheme/fetch-related-news-details',
  async (params:any, thunkAPI) => {
    const {newsId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchRelatedNewsDetails(params);
      return {
        ...result,
        newsId,
      }
    })
  }
)


export const fetchNewsDetails = createAsyncThunk(
  '/bigTheme/fetch-news-details',
  async (params:any, thunkAPI) => {
    const {newsId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchRelatedNewsDetails(params);
      return {
        ...result,
        newsId,
      }
    })
  }
)
export const fetchGetNewsDetail = createAsyncThunk(
  '/bigTheme/fetch-port-news-details',
  async (params:any, thunkAPI) => {
    const {newsId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetNewsDetail(params);
      return {
        ...result,
        newsId,
      }
    })
  }
)
export const fetchGetCompanyNameListByNewsIdOrEventId = createAsyncThunk(
  '/portfolio-dashboard/fetch-get-company-name-list',
  async (params:any, thunkAPI) => {
    const {newsIdList} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyNameListByNewsIdOrEventId(params);
      return {
        ...result,
        newsIdList,
      }
    })
  }
)

export const fetchGetNesCompany = createAsyncThunk(
  '/portfolio-dashboard/fetch-get-news-company',
  async (params:any, thunkAPI) => {
    const {newsIdList} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyNameListByNewsIdOrEventId(params);
      return {
        ...result,
        newsIdList,
      }
    })
  }
)



export const news = createSlice({
  name:'search',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRelatedNewsDetails.fulfilled, (state, {payload})=>{
      const {code, data, newsId} = payload;
      if(!code){
        state.relatedNewsDetails[newsId] = data;
      }
    }).addCase(fetchNewsDetails.fulfilled, (state, {payload})=>{
      const {code, data, newsId} = payload;
      if(!code){
        state.newsDetails[newsId] = data;
      }
    }).addCase(fetchGetNewsDetail.fulfilled, (state, {payload})=>{
      const {code, data, newsId} = payload;
      if(code === '0'){
        data.companyList = []
        state.portNewsDetails[newsId] = data;
      }
    }).addCase(fetchGetCompanyNameListByNewsIdOrEventId.fulfilled, (state, {payload})=>{
      const {code, data, newsIdList} = payload;
      if(code === '0'){
        state.portNewsDetails[newsIdList[0]].companyList = data;
      }
    })
  }
})

const selectBaseState = (state: RootState)  => state.news;
export const selectRelatedNewsDetailsById = (id: string) =>  createSelector(selectBaseState, state => {
  if(!id){
    return {};
  }
  return state.relatedNewsDetails[id]
});
export const selectNewsDetailsById = (id: string) =>  createSelector(selectBaseState, state => {
  if(!id){
    return {};
  }
  return state.newsDetails[id]
});
export const selectPortNewsDetailsById = (id: string) =>  createSelector(selectBaseState, state => {
  if(!id){
    return {};
  }
  return state.portNewsDetails[id]
});


export default news.reducer;
