import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import pandora from '../../../api';
import {checkToken} from '../../user/reducer';
import moment from 'moment';
interface StateProps {
  loading: {
    [key: string]: boolean;
  },
  activeCompanyId: number,
  activeEventId: number,
  activeTab: string,
  news:{
    [key:number]: any;
  },
  riskCompany:{
    dataList: Array<any>,
    total: number,
    risk?: number,
    opportunity?:number, 
    hasMore?: boolean
  },
  trendData:any, 
  topTen:Array<any>,
  hotTrend:Array<any>,
  topTenNews:Array<any>,
  topTenNewsOptions:{
    dataList: Array<any>,
    total: number, 
    hasMore?: boolean,
    page?: number
  },
  hotNews:{
    [key: string]: any
  },
  historyNews: {
    [key:string]: any
  },
  sentimentData: {
    [key: string]: any
  },
  influenceCompanyList:{
    [key: string]: Array<any>
  },
  wordCloud: Array<any>
}

const initialState: StateProps = {
  loading: {},
  activeCompanyId: 0,
  activeEventId: 0,
  activeTab: 'event',
  news:{},
  riskCompany: {
    dataList: [],
    total: 0, 
    risk: 0, 
    hasMore: true
  },
  trendData:{},
  topTen:[],
  hotTrend:[],
  topTenNews:[],
  topTenNewsOptions:{
    dataList: [],
    total: 0, 
    hasMore: true,
    page:1
  },
  hotNews:{},
  historyNews: {},
  sentimentData: {},
  influenceCompanyList: {},
  wordCloud: []
}

export const fetchNewsByCompanyId = createAsyncThunk(
  'risk-mangement/fetch-news-by-company-id', 
  (params:any, thunkAPI) => {
    const {companyId} = params;
    return thunkAPI.dispatch(checkToken()).then(async resp => {
      const result = await pandora.fetchNewsByCompanyId(params)
      return {
        data: result.data,
        companyId
      }
    })
  }
)

export const fetchPandemicData = createAsyncThunk(
  'risk-mangement/fetch-pandemic-data',
  (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async resp => {
      const result = await pandora.fetchPandemicData(params)
      return {
        data: result.data,
        ...params,
      }
    })
  }
)

export const fetchCountryRelationData = createAsyncThunk(
  'risk-mangement/fetch-country-relation-data',
  (params:any, thunkAPI) => {
    const {topicName, ...restParams} = params
    return thunkAPI.dispatch(checkToken()).then(async resp => {
      const result = await pandora.fetchCountryRelation(restParams)
      return {
        data: result.data,
        topicName
      }
    })
  }
)

export const fetchPortfolioRiskCompany = createAsyncThunk(
  'risk-mangement/fetch-portfolio-risk-company',
  (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {data} = await pandora.fetchPortfolioRiskCompany(params);
      return {
        ...data,
        ...params
      }
    })
  }
)


export const fetchTopicHotNews = createAsyncThunk(
  'risk-mangement/fetch-topic-hot-news',
  (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {data} = await pandora.fetchTopicHotNews(params);
      return {
        data: data,
        ...params
      }
    })
  }
)
export const fetchTopicHistoryNews = createAsyncThunk(
  'risk-mangement/fetch-topic-history-news',
  (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {data} = await pandora.fetchTopicHistoryNews(params);
      return {
        data: data,
        ...params
      }
    })
  }
)

export const fetchTopicTopTenNews = createAsyncThunk(
  'risk-mangement/fetch-topic-topTen-news',
  (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {data} = await pandora.fetchTopicTopTenNews(params);
      return {
        data: data,
        ...params
      }
    })
  }
)

export const fetchTopTen = createAsyncThunk(
  'risk-mangement/fetch-topic-ten',
  (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {data} = await pandora.fetchTopicCloud(params);
      return {
        data: data,
        ...params
      }
    })
  }
)

export const fetchTopicHotTrend = createAsyncThunk(
  'risk-mangement/fetch-topic-hot-trend',
  (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {data} = await pandora.fetchTopicHotTrend(params);
      return {
        data: data
      }
    })
  }
)

export const fetchTopicCloud = createAsyncThunk(
  'risk-mangement/fetch-topic-cloud',
  (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {data} = await pandora.fetchTopicCloud(params);
      return {
        data: data,
        ...params
      }
    })
  }
)

export const fetchTopicSentimentTrend = createAsyncThunk(
  'risk-mangement/fetch-topic-sentiment-trend',
  (params:any, thunkAPI) => {
    const {topicName} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {data} = await pandora.fetchTopicSentimentTrend(params);
      return {
        data: data,
        topicName,
      }
    })
  }
)

export const fetchCompanyInfluenceByTopic = createAsyncThunk(
  'risk-mangement/fetch-company-influence-by-topic',
  (params:any, thunkAPI) => {
    const {topicName} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {data} = await pandora.fetchCompanyInfluenceByTopic(params);
      return {
        data: data,
        topicName,
      }
    })
  }
)

export const riskManagement = createSlice({
  name:'riskManagement',
  initialState,
  reducers: {
    setActiveTab: (state, {payload}) => {
      state.activeTab = payload;
    },
    restTopTenNewsOptions:(state, {payload}) => {
      state.topTenNewsOptions= payload
    },
  },
  extraReducers:(builder) => {
    builder.addCase(fetchNewsByCompanyId.pending, state => {
      state.loading.newsLoading = true
    }).addCase(fetchNewsByCompanyId.fulfilled, (state, {payload}) => {
      const {companyId, data} = payload
      const newsList = data.dataList;
      const newArr = newsList.length ? newsList.map((item:any) => ({...item, TimeRanges:  moment(item.publishDateZh).format('MMM DD')})) : []
      
      let tempArr = [];
      let afterData = [];
      for (let i = 0; i < newArr.length; i++) {
        if (tempArr.indexOf(newArr[i].TimeRanges) === -1) {
            afterData.push({
            TimeRanges: newArr[i].TimeRanges,
            time: newArr[i].publishDateZh,
            news: [newArr[i]]
            });
          tempArr.push(newArr[i].TimeRanges);
        } else {
          for (let j = 0; j < afterData.length; j++) {
            if (afterData[j].TimeRanges === newArr[i].TimeRanges) {
            afterData[j].news.push(newArr[i]);
              break;
            }
          }
        }
      }
      console.log('newArr',afterData)
      data.dataList = afterData
      state.news[companyId] = data
    }).addCase(fetchPandemicData.pending, state => {
      state.loading.fetchPandemicData = true
    }).addCase(fetchPandemicData.fulfilled, (state, {payload}) => {
      const {code, data, pandemicName} = payload;
      const refactorData = Object.entries(data || {})?.map(([key, value]:[string, any]) => ({
        timeSpot: value?.timeSpot,
        Global: value?.Global?.confirmed || 0,
        US: value?.US?.confirmed || 0,
      }))
      if(!code){
        state.trendData[pandemicName] = refactorData;
      }
      state.loading.fetchPandemicData = false
    }).addCase(fetchCountryRelationData.pending, state => {
      state.loading.fetchCountryRelationData = true
    }).addCase(fetchCountryRelationData.fulfilled, (state, {payload}) => {
      const {data, topicName} = payload;
      state.trendData[topicName] = data;
      state.loading.fetchCountryRelationData = false
    }).addCase(fetchPortfolioRiskCompany.pending, state => {
      state.loading.fetchPortfolioRiskCompany = true
    }).addCase(fetchPortfolioRiskCompany.fulfilled, (state, {payload}) => {
      const {companyList, size, ...restProps} = payload;
      state.riskCompany = {
        ...restProps,
        dataList: companyList,
        hasMore: !Boolean(companyList.length < size)
      };
      state.loading.fetchPortfolioRiskCompany = false
    }).addCase(fetchTopicHotTrend.pending, state => {
      state.loading.fetchTopicHotTrend = true
    }).addCase(fetchTopicHotTrend.fulfilled, (state, {payload}) => {
      const {data} = payload;
      state.hotTrend = data
    }).addCase(fetchTopicHotNews.pending, state => {
      state.loading.fetchTopicHotNews = true
    }).addCase(fetchTopicHotNews.fulfilled, (state, {payload}) => {
      const {topicName, data} = payload;
      state.hotNews[topicName] = data;
      state.loading.fetchTopicHotNews = false
    }).addCase(fetchTopicHistoryNews.pending, state => {
      state.loading.fetchTopicHotNews = true
    }).addCase(fetchTopicHistoryNews.fulfilled, (state, {payload}) => {
      const {topicName, data} = payload;
      state.historyNews[topicName] = data;
      state.loading.fetchTopicHistoryNews = false
    }).addCase(fetchTopicTopTenNews.pending, state => {
      state.loading.fetchTopicTopTenNews = true
    }).addCase(fetchTopicTopTenNews.fulfilled, (state, {payload}) => {
      const {data} = payload;
      const {topTopicNewsList, newsAmount, ...restProps} = data;
      const newTopTopicNewsList = topTopicNewsList.length ? topTopicNewsList.map((item:any) => ({...item, TimeRanges:  moment(Number(item.newsTimestamp)).format('MMM DD')})) : []
      
      let tempArr = [];
      let afterData = [];
      for (let i = 0; i < newTopTopicNewsList.length; i++) {
        if (tempArr.indexOf(newTopTopicNewsList[i].TimeRanges) === -1) {
            afterData.push({
            TimeRanges: newTopTopicNewsList[i].TimeRanges,
            time: newTopTopicNewsList[i].newsTimestamp,
            news: [newTopTopicNewsList[i]]
            });
          tempArr.push(newTopTopicNewsList[i].TimeRanges);
        } else {
          for (let j = 0; j < afterData.length; j++) {
            if (afterData[j].TimeRanges === newTopTopicNewsList[i].TimeRanges) {
            afterData[j].news.push(newTopTopicNewsList[i]);
              break;
            }
          }
        }
      }

      state.topTenNewsOptions = {
        ...restProps,
        dataList:state.topTenNewsOptions.dataList.length ? state.topTenNewsOptions.dataList.concat(afterData):afterData,
        total:newsAmount,
        hasMore: !Boolean(topTopicNewsList.length < 60),
        page: state.topTenNewsOptions.page? state.topTenNewsOptions.page + 1 : 1,
      }
      state.topTenNews = data.topTopicNewsList;
      state.loading.fetchTopicTopTenNews = false
    }).addCase(fetchTopTen.pending, state => {
      state.loading.fetchTopicCloud = true
    }).addCase(fetchTopTen.fulfilled, (state, {payload}) => {
      const { data} = payload;
      state.topTen = data;
      state.loading.fetchTopicCloud = false
    }).addCase(fetchTopicCloud.pending, state => {
      state.loading.fetchTopicCloud = true
    }).addCase(fetchTopicCloud.fulfilled, (state, {payload}) => {
      const {data} = payload;
      state.wordCloud= data;
      state.loading.fetchTopicCloud = false
    }).addCase(fetchTopicSentimentTrend.pending, state => {
      state.loading.fetchTopicSentimentTrend = true
    }).addCase(fetchTopicSentimentTrend.fulfilled, (state, {payload}) => {
      const {topicName, data} = payload;
      state.sentimentData[topicName] = data?.filter((item:any) => (item.naturalScore > 0 || item.optimismScore> 0 || item.pessimisticScore > 0));
      state.loading.fetchTopicSentimentTrend = false
    }).addCase(fetchCompanyInfluenceByTopic.pending, state => {
      state.loading.fetchCompanyInfluenceByTopic = true
    }).addCase(fetchCompanyInfluenceByTopic.fulfilled, (state, {payload}) => {
      const {topicName, data} = payload;
      state.influenceCompanyList[topicName] = data;
      state.loading.fetchCompanyInfluenceByTopic = false
    })
  }
})

export const {setActiveTab,restTopTenNewsOptions} = riskManagement.actions;

const selectBaseState = (state: RootState)  => state.riskManagement;
export const selectNewsById = (id: number) => createSelector(selectBaseState, state => {
  if(!id){
    return []
  }
  return state.news[id]?.dataList || []
})
export const selectRiskCompany = createSelector(selectBaseState, state => state.riskCompany.dataList);
export const selectTopTen = createSelector(selectBaseState, state => state.topTen);
export const selectTopTenNews = createSelector(selectBaseState, state => state.topTenNewsOptions.dataList);
export const selectHotTrend = createSelector(selectBaseState, state => state.hotTrend || []);
export const historyNewsFetchMoreOptions = createSelector(selectBaseState, state => {
  const {dataList, ...othersProps} = state.topTenNewsOptions;
  return othersProps;
}) 
export const selectRiskCompanyFetchMoreOptions = createSelector(selectBaseState, state => {
  const {dataList, opportunity, risk, ...restProps} = state.riskCompany;
  return restProps;
})
export const selectLoadingByType = (type:string) => createSelector(selectBaseState, state => state.loading[type] || false);
export const selectTrendDataByName = (name:string) => createSelector(selectBaseState, state => {
  return state.trendData[name] || [];
})
export const selectTopicHotNews = (topicName:string) => createSelector(selectBaseState, state => {
  return state.hotNews[topicName] || []
})
export const selectHistoryNews = (topicName:string) => createSelector(selectBaseState, state => {
  return state.historyNews[topicName] || {}
})
export const selectSentimentData = (topicName:string) => createSelector(selectBaseState, state => {
  return state.sentimentData[topicName] || []
})
export const selectInfluenceCompanyListByTopic = (topicName:string) => createSelector(selectBaseState, state => {
  return state.influenceCompanyList[topicName] || []
})
export const selectWordCloud = createSelector(selectBaseState, state => state.wordCloud || [])

export default riskManagement.reducer;
