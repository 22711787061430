import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import pandora from '../../api';
import { RootState } from '../../app/store';
import dailyFlashSketch from '../../assets/daliy-flash.png';
import financialReportSketch from '../../assets/financial-report.png';
import bigThemeSketch from '../../assets/big-theme.png';
import priceEventSketch from '../../assets/price-event.png';
import hejunSketch from '../../assets/hejun.png';
import investmentFinancingMonitorSketch from '../../assets/investment-financing-monitor.png';
import policyAnalysisSketch from '../../assets/policy-analysis.png';
import portfolioDashboardSketch from '../../assets/portfolio.png';
import riskManagementSketch from '../../assets/risk-management.png';
import hejunNewsSketch from '../../assets/hejun-news.png';
import noyaxeSketch from '../../assets/noyaxe.png';
import supplyChainSketch from '../../assets/supply-chain.png';
import marketOpinionSketch from '../../assets/market-opinion.png';
import huakongSketch from '../../assets/huakong.png';
import {checkToken} from '../user/reducer';

const iconMap:{[key:string]: any} = {
  bigTheme: bigThemeSketch,
  financialReport:financialReportSketch,
  priceEvent: priceEventSketch,
  dailyFlash: dailyFlashSketch,
  hejun: hejunSketch,
  portfolioDashboard: portfolioDashboardSketch,
  riskManagement: riskManagementSketch,
  heJunNews: hejunNewsSketch,
  noyaxe: noyaxeSketch,
  supplyChain: supplyChainSketch,
  investmentFinancingMonitor: investmentFinancingMonitorSketch,
  policyAnalysis:policyAnalysisSketch,
  marketOpinion:marketOpinionSketch,
  huakong:huakongSketch,
  
}


interface LobbyProps {
  supportedFunctions: any[],
  roomName:String,
  subscribedRoom: any
}

const initialState: LobbyProps = {
  roomName:'',
  supportedFunctions: [],
  subscribedRoom:{
    dataList: [],
    total: 0
  }
}

export const roomSubscribe = createAsyncThunk(
  'header/room-subscribe',
  async (values:any) => pandora.roomSubscribe(values)
);

export const getRoomData = createAsyncThunk(
  'room/get-data',
  async (args:void, thunkAPI) =>{
    return thunkAPI.dispatch(checkToken()).then(async (resp) =>{
      const result = await pandora.getRoomData()
        return {
          ...result,
        }
    })
  }
)
export const getRoomDetail = createAsyncThunk(
  'lobby/get-room-details',
  async (values:any) => pandora.getRoomDetail(values)
);


export const addRoom = createAsyncThunk(
  'lobby/add-room-data',
  async (values:any) => pandora.addRoom(values)
);


export const getSubscribeRoom = createAsyncThunk(
  'lobby/get-subscribe-room',
  async (payload, thunkAPI):Promise<any> => {
    const params:any = {
      size:30, 
      page:0,
    }
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.getSubscribeRoom(params);
      return {
        ...result
      }
    })
  }
)

export const lobby = createSlice({
  name:'lobby',
  initialState,
  reducers: {
    setRoomName:(state, {payload}) => {
      state.roomName = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRoomData.fulfilled, (state,{ payload }) => {
      const {code,data} = payload;
      const whiteList = ['bigTheme', 'dailyFlash', 'financialReport', 'priceEvent', 'hejun', 'heJunNews','portfolioDashboard', 'riskManagement', 'noyaxe', 'supplyChain', 'investmentFinancingMonitor','policyAnalysis','marketOpinion','huakong']
      if(!code){
        const refactorData = data.filter((item:any) => whiteList.includes(item.key)).map((item:any) => ({
          ...item,
          sketchIcon: iconMap[item.key]
        }))
        state.supportedFunctions = refactorData
      }
    }).addCase(getSubscribeRoom.fulfilled, (state, {payload}) => {
      const { data } = payload;
      state.subscribedRoom = data
    })
  }
})

export const {setRoomName} = lobby.actions;
const selectBaseState =  (state: RootState)  => state.lobby;
export const selectSupportedFunction = createSelector(selectBaseState, lobby => lobby.supportedFunctions)
export const selectActiveThemeName = createSelector(selectBaseState, lobby => lobby.roomName)
export const selectUserRoom = createSelector(selectBaseState, state => state.subscribedRoom?.dataList || [])
export const selectDetailsById = (id:string) => createSelector(selectSupportedFunction, lobbys => {
  return lobbys.find(item => item.id === id) || {};
})

export default lobby.reducer;
