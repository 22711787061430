import { createSlice, createSelector, createAsyncThunk, Reducer } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import pandora from '../../api';
import {LoginFormValuesProps,LoginPhoneFormValuesProps} from '../../types';
import qs from 'qs';

interface UserActionProps {
  loading:boolean,
  verifycodeImg: string,
  loginVerified: boolean,
  activeDialogOpen: boolean,
  isRecomend:boolean,
}

const initialState: UserActionProps = {
  loading: false,
  verifycodeImg: '',
  loginVerified: false,
  activeDialogOpen: false,
  isRecomend:true,
}

export const toLogin = createAsyncThunk(
  'user/login',
  async (values:LoginFormValuesProps, thunkAPI) => pandora.login({...values,...{source:""}})
);



export const phoneLogin = createAsyncThunk(
  'user/phone-login',
  async (values:LoginPhoneFormValuesProps, thunkAPI) => pandora.login({...values,...{source:""}})
);
export const getVerifycode = createAsyncThunk(
  'user/get-verifycode',
  async (value:{email: string}, thunkAPI) => pandora.getverifycode(qs.stringify(value))
);
export const checkVerifyCode = createAsyncThunk(
  'user/check-verifycode',
  async (value:any, thunkAPI) => pandora.checkverifycode(qs.stringify(value))
)

export const toRegister = createAsyncThunk(
  'user/register',
  async (values:any) => pandora.register(values)
);
export const tocSignIn = createAsyncThunk(
  'user/tocSignIn',
  async (values:any) => pandora.tocSignIn(values)
);
export const tocLogin = createAsyncThunk(
  'user/tocLogin',
  async (values:any) => pandora.tocLogin(values)
);

export const changePw = createAsyncThunk(
  'user/changePw',
  async (values:any) => pandora.changePw(values)
);

export const tocSendSMSCode = createAsyncThunk(
  'user/tocSendSMSCode',
  async (values:any) => pandora.tocSendSMSCode(values)
);


export const sendActiveCode = createAsyncThunk(
  'user/sen-active-code',
  async (values:any) => pandora.getActiveCode(values)
);

export const resetSendmail = createAsyncThunk(
  'user/reset-send-mail',
  async (values:any) => pandora.resetSendmail(values)
);

export const resetCheckcode = createAsyncThunk(
  'user/reset-check-code',
  async (values:any) => pandora.resetCheckcode(values)
);
export const toResetpasswod = createAsyncThunk(
  'user/reset-password',
  async (values:any) => pandora.resetPassword(values)
);

export const getCaptcha = createAsyncThunk(
  'user/get-captcha',
  async (values:any) => pandora.getCaptcha(values)
);
export const getCaptchaErrorNum = createAsyncThunk(
  'user/get-captcha-error-num',
  async (values:any) => pandora.getCaptchaErrorNum(values)
);
export const checkSendSMSCode = createAsyncThunk(
  'user/checkSendSMSCode',
  async (values:any) => pandora.checkSendSMSCode(values)
);
export const checkCaptchaImage = createAsyncThunk(
  'user/checkCaptchaImage',
  async (values:any) => pandora.checkCaptchaImage(values)
);


export const userActions = createSlice({
  name:'user',
  initialState,
  reducers: {
    clearVerifyCode: (state) => {
      state.verifycodeImg = '';
      state.loginVerified = false;
    },
    setActiveDialogOpen: (state, {payload}) => {
      state.activeDialogOpen = payload;
    },
    setUserGuideStatus:(state, {payload}) => {
      state.isRecomend = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(toLogin.pending, (state) => {
      state.loading = true;
    }).addCase(toLogin.fulfilled, (state, action) => {
      state.loading = false;
      const {code=0,success, result} = action.payload as any;
      if(success){
        state.isRecomend = result.is_recomend;
      }
      if(code === 101){
        state.loginVerified = true
      }
    }).addCase(getVerifycode.fulfilled, (state, action) => {
      const {success, result} = action.payload as any;
      if(success){
        state.verifycodeImg = result.data.base64;
      }
    }).addCase(checkVerifyCode.fulfilled, (state, action) => {
      const { success } = action.payload as any;
      if(success){
        state.verifycodeImg = '';
        state.loginVerified = false
      }
    }).addCase(toRegister.pending, (state) => {
      state.loading = true;
    }).addCase(toRegister.fulfilled, (state, action) => {
      state.loading = false;
    }).addCase(getCaptcha.fulfilled, (state, action) => {
      const { code,data} = action.payload as any;
      if(code === '0'){
        state.verifycodeImg = data;
      }
    })
  },
})

export const {clearVerifyCode, setActiveDialogOpen, setUserGuideStatus} = userActions.actions;

const selectBaseState = (state: RootState) => state.userActions;
export const selectVerifycodeImg = createSelector(selectBaseState, user => user.verifycodeImg);
export const selectIsRecomend = createSelector(selectBaseState, user => user.isRecomend);
export const selectLoginVerified = createSelector(selectBaseState, user => user.loginVerified);
export const selectActiveDialog = createSelector(selectBaseState, user => user.activeDialogOpen);
export const selectLoading = createSelector(selectBaseState, user => user.loading);

export default userActions.reducer as Reducer<typeof initialState>;
