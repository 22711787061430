import { createSlice, createSelector, createAsyncThunk, Reducer } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import pandora from '../../../api'
import {checkToken} from '../../user/reducer';

interface SelfServiceProps {
  userOptionalStockList: Array<string>, 
  loading: {
    [key: string]: boolean;
  },
}

const initialState: SelfServiceProps = {
  userOptionalStockList: [],
  loading: {},
}


export const getUserOptionalStockList = createAsyncThunk(
  'self-service/getUserOptionalStockList',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.getUserOptionalStockList(params))
  }
)

export const selfService = createSlice({
  name:'selfService',
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder.addCase(getUserOptionalStockList.pending, (state) => {
      state.loading.userOptionalStockList = true;
      state.userOptionalStockList = [];
    }).addCase(getUserOptionalStockList.fulfilled, (state, {payload}) => {
      const {code, data} = payload;
      if(code==='0'){
        state.userOptionalStockList = data;
      }
      state.loading.userOptionalStockList = false;
    });
  },
})

// export const {} = selfService.actions;

const selectBaseState = (state: RootState) => state.selfService;
export const selectLoadingByType = (type:string) => createSelector(selectBaseState, state => state.loading[type] || false);
export const selectUserOptionalStockList = createSelector(selectBaseState, state => { return state.userOptionalStockList || []})


export default selfService.reducer as Reducer<typeof initialState>;
