import { createSlice, createSelector, Reducer } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface DialogProps {
  dialogs: {
    [key: string]: boolean
  }
}

const initialState: DialogProps = {
  dialogs: {
  },
}

export const dialog = createSlice({
  name:'dialog',
  initialState,
  reducers: {
    openDialog: (state, {payload}) => {
      state.dialogs[payload] = true;
    },
    closeDialog: (state, {payload}) => {
      state.dialogs[payload] = false;
    }
  }
})


export const {closeDialog, openDialog} = dialog.actions;


const selectBaseState = (state: RootState)  => state.dialog;
export const selectDialogStatusByName = (name:string) => createSelector(selectBaseState, state => {
  return state.dialogs[name] || false;
}) 

export default dialog.reducer as Reducer<typeof initialState>;
