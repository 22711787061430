import { createSlice, createSelector, createAsyncThunk, Reducer } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import pandora from '../../../api'
import {PAGE_SIZE} from '../../../types/constant';
import {enqueueSnackbar} from '../../snackbar/reducer';
import {checkToken} from '../../user/reducer';

interface DataProps {
  activedBusinessId:number,
  activedTagsBusinessId: Array<any>,
  activedCompanyId: number,
}

interface InitialStateProps {
  selectType: string;
  isBusinessSearching: boolean;
  isCompanySearching: boolean;
  searchList:{
    company: {
      dataList: Array<any>,
      watchlist: any
    },
    business: {
      dataList: Array<any>,
    },
    searchActiveOption: any,
    searchTagsActiveOption: Array<any>
  },
  isSearching: boolean,
  company:{
    activedBusinessId: number;
    activedCompanyId: number;
    activedTagsBusinessId: Array<any>,
    dataList:{
      [key: number]: {
        dataList: Array<any>,
        total: number,
        page: number,
        hasMore?: boolean
      }
    },
  },
  reportDetails: {
    [key: string]: any
  },
  dataType: string,
  business:{
    activedBusinessId: number,
    activedCompanyId: number,
    activedTagsBusinessId: Array<any>,
    dataList: {
      dataList: Array<any>,
      total: number,
      page: number,
      hasMore?: boolean
    }
  },
  searchLoading: boolean,
  searchStatus: boolean,
  listShow:boolean
}

const initialState: InitialStateProps = {
  searchList: {
    company: {
      dataList: [],
      watchlist: null
    },
    business:{
      dataList: []
    },
    searchActiveOption:{},
    searchTagsActiveOption:[]
  },
  reportDetails: {},
  company: {
    activedBusinessId: 0,
    activedCompanyId: 0,
    activedTagsBusinessId: [],
    dataList: {},
  },
  dataType: 'company',
  isSearching: false,
  isBusinessSearching: false,
  isCompanySearching: false,
  business:{
    activedTagsBusinessId: [],
    activedBusinessId: 0,
    activedCompanyId: 0,
    dataList:  {
      dataList: [],
      total: 0,
      page: 0
    }
  },
  searchLoading: false,
  searchStatus: false,
  selectType:'',
  listShow:false
}

export const searchReportCompany = createAsyncThunk(
  '/finance-report/search-comapny',
  async (params:any, thunkAPI) => thunkAPI.dispatch(checkToken()).then(resp => pandora.searchCompany({...params, page: 0,  size: PAGE_SIZE*2}))
)

export const searchBusiness = createAsyncThunk(
  '/finance-report/search-business',
  async (params:any) => pandora.searchBusiness({...params, size: PAGE_SIZE*2, })
)

export const fetchCompanyBusinessList = createAsyncThunk(
  'finance-report/fetch-business-list', 
  async (params:any, thunkAPI):Promise<any> => {
    const rootState = thunkAPI.getState() as RootState;
    const {page, size} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {data} = await pandora.fetchCompanyBusinessList({...params, companyId: rootState.financeReport.company.activedCompanyId});
      return {
        ...data,
        companyId: rootState.financeReport.company.activedCompanyId,
        page,
        hasMore: !Boolean(data.dataList < size)
      }
    })
  }
)

export const fetchCompanyByBusiness = createAsyncThunk(
  'finance-report/fetch-company-by-business',
  async (params: any, thunkAPI):Promise<any> => {
    const rootState = thunkAPI.getState() as RootState;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchCompanyByBusiness({...params, businessId: rootState.financeReport.business.activedBusinessId});
      return {
        ...result, 
        businessId: rootState.financeReport.business.activedBusinessId,
        page: params.page
      } 
    })
  }
)



export const getBusinessList = createAsyncThunk(
  'finance-report/fetch-financial-report',
  async (params: any, thunkAPI):Promise<any> => {
    const rootState = thunkAPI.getState() as RootState;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {data} = await pandora.fetchBusinessList({...params, keywords: rootState.financeReport.business.activedTagsBusinessId});
      return {
        ...data, 
        page: params.page,
        hasMore: !Boolean(data.dataList.length < params.size)
      } 
    })
  }
)


export const fetchBusinessReport = createAsyncThunk(
  'finance-report/fetch-business-report',
  async (params:any, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const dataType = rootState.financeReport.dataType;
    if(dataType === 'company'){
      params.companyId = rootState.financeReport.company.activedCompanyId
    }
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchBusinsessReport(params)
      return {
        ...result,
        ...params
      }; 
    })
  } 
)

export const addComment = createAsyncThunk(
  'finance-report/add-comment',
  async (params:any, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const dataType = rootState.financeReport.dataType;
    const companyId = rootState.financeReport[dataType === 'company' ? 'company' : 'business'].activedCompanyId;
    const businessId = rootState.financeReport[dataType === 'company' ? 'company' : 'business'].activedBusinessId;
    return thunkAPI.dispatch(checkToken()).then(resp => {
      return pandora.addComment({...params, companyId: companyId}).then((resp:any) => {
        thunkAPI.dispatch(enqueueSnackbar({message: 'Add Comment Success', key:'addCommentSuccess',options:{variant: 'success'}}))
        return thunkAPI.dispatch(fetchBusinessReport({companyId: companyId, businessTypeId: businessId}))
      })
    })
  }
)

export const deleteComment = createAsyncThunk(
  'finance-repiort/delete-comment',
  async(params: any, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const dataType = rootState.financeReport.dataType;
    const companyId = rootState.financeReport[dataType === 'company' ? 'company' : 'business'].activedCompanyId;
    const businessId = rootState.financeReport[dataType === 'company' ? 'company' : 'business'].activedBusinessId;
    return thunkAPI.dispatch(checkToken()).then(resp => {
      return pandora.deleteComment({...params}).then((resp:any) => {
        thunkAPI.dispatch(enqueueSnackbar({message: 'remove Comment Success', key:'removeCommentSuccess',options:{variant: 'success'}}))
        return thunkAPI.dispatch(fetchBusinessReport({companyId: companyId, businessTypeId: businessId}))
      })
    })
  }
)

export const financeReport = createSlice({
  name:'financeReport',
  initialState,
  reducers: {
    setSearchStatus: (state, {payload}) => {
      state.isSearching = payload;
    },
    setBusinessSearchStatus: (state, {payload}) => {
      state.isBusinessSearching = payload;
    },
    setCompanySearchStatus: (state, {payload}) => {
      state.isCompanySearching = payload;
    },
    setActiveCompany: (state, {payload}) => {
      state.company.activedCompanyId = payload;
    },
    setActiveId: (state, {payload}) => {
      const {dataType, id ,businessTypeId} = payload;
      if(dataType === 'company'){
        state.company.activedBusinessId = id
      }else{
        state.business.activedBusinessId = businessTypeId
        state.business.activedCompanyId = id
      }
    },
    setSearchActiveItem: (state, {payload}) => {
      state.searchList.searchActiveOption = payload
    },
    setSearchTagsActiveItem: (state, {payload}) => {
      state.searchList.searchTagsActiveOption.push(payload)
    },
    setSearchId: (state, {payload}) => {
      const {dataType, id} = payload;
      if(dataType === 'company'){
        state.company.activedCompanyId = id
      }else{
        state.business.activedTagsBusinessId = id
      }
    },
    setSearchType: (state, {payload}) => {
      state.dataType = payload;
    },
    setSelectType: (state, {payload}) => {
      state.selectType = payload;
    },
    setNewSearchStatus: (state, {payload}) => {
      state.searchStatus = payload;
    },
    setBusinessList: (state, {payload}) => {
      state.business.dataList = payload
    },
    setCompanyList: (state, {payload}) => {
      state.company.dataList = payload
    },
    setListShow:(state, {payload}) => {
      state.listShow = payload
    },
    
  },
  extraReducers: (builder) => {
    builder.addCase(searchReportCompany.pending, (state) => {
      state.searchLoading = true;
    }).addCase(searchReportCompany.fulfilled, (state, {payload}) => {
      const {data, code} = payload;
      if(!code){
        state.searchList.company = {
          ...data,
          dataList: data.companyList
        }
      }
      state.searchLoading = false;
    }).addCase(searchBusiness.pending, (state) => {
      state.searchLoading = true;
    }).addCase(searchBusiness.fulfilled, (state, {payload}) => {
      const {code, data} = payload;
      if(!code){
        const {dataList, ...restDataProps} = data;
        const normlizedData = dataList.map((item:any) => ({...item, companyNameEn: item.nameEn || '', companyNameZh: item.nameZh || ''}))
        state.searchList.business = {
          ...restDataProps,
          dataList: normlizedData
        };
      }
      state.searchLoading = false;
    }).addCase(fetchCompanyBusinessList.fulfilled, (state, {payload}) => {
      const {code, dataList, companyId, page, total, hasMore} = payload;
      if(!code){
        if(page > 0) {
          state.company.dataList[companyId] = {
            dataList: state.company.dataList[companyId].dataList.concat(dataList),
            total: total,
            page,
            hasMore
          }
        }else{
          state.company.dataList[companyId] = {
            dataList,
            page,
            total,
            hasMore
          }
        }
      }
    }).addCase(getBusinessList.fulfilled, (state, {payload}) => {
      const {code, dataList, page, hasMore, total} = payload;
      if(!code){
        if(page > 0){
          state.business.dataList = {
            dataList: state.business.dataList.dataList.concat(dataList), 
            page, 
            hasMore, 
            total 
          } 
        }else{
          state.business.dataList = {dataList, page, hasMore, total } 
        }
      }
    }).addCase(fetchBusinessReport.fulfilled, (state, {payload}) => {
      const {companyId, businessTypeId, data} = payload;
      state.reportDetails[`report-${companyId}-${businessTypeId}`] = data
    })
  },
})

export const {setListShow, setCompanyList, setBusinessList, setSelectType, setNewSearchStatus,setBusinessSearchStatus, setCompanySearchStatus, setSearchStatus, setActiveCompany, setActiveId, setSearchType, setSearchId, setSearchActiveItem, setSearchTagsActiveItem} = financeReport.actions;

const selectBaseState = (state: RootState) => state.financeReport;
export const selectSearchType = createSelector(selectBaseState, state => state.dataType || '')
export const selectListShow = createSelector(selectBaseState, state => state.listShow || false)
export const selectType = createSelector(selectBaseState, state => state.selectType || '')
export const selectSearchActiveOption = createSelector(selectBaseState, state => state.searchList.searchActiveOption)
export const selectSearchTagsActiveOption = createSelector(selectBaseState, state => state.searchList.searchTagsActiveOption)
export const selectSearchingStatus = createSelector(selectBaseState, state => state.isSearching || false)
export const selectFetchId = createSelector(selectBaseState, selectSearchType,  (state, dataType) => dataType === 'company' ?  state.company.activedCompanyId : state.business.activedTagsBusinessId)
export const selectActivedCompanyId = createSelector(selectBaseState,  state => state.company.activedCompanyId || '')
export const selectActivedTagsBusinessId = createSelector(selectBaseState, state => state.business.activedTagsBusinessId || [])
export const selectCompanyBusinessFetchOptions = createSelector(selectBaseState, selectSearchType,  (state, type) => {
  const data = type === 'company' ? state.company.dataList[state.company.activedCompanyId] : state.business.dataList;
  if(data){
    const {dataList, ...fetchOptions} = data
    return fetchOptions
  }
  return {
    page: 0, 
    total: 1
  };
})
export const selectListDataByDataType = createSelector(
  selectBaseState, 
  selectSearchType,  
  (state, type)=> {
    if(!type){
      return {
        activedTagsBusinessId:[],
        activedBusinessId:0,
        activedCompanyId: 0,
      } as DataProps;
    }
    const {dataList, ...listData} = type === 'company' ? state.company : state.business;
    return listData
  }
);
export const selectDataListById = createSelector(
  selectBaseState, 
  selectSearchType, 
  (state, type)=> {
    if(!type){
      return [];
    }
    if(type === 'company'){
      return state.company.dataList[state.company.activedCompanyId]?.dataList || []
    }else{
      return state.business.dataList?.dataList || [];
    }
  }
)
export const selectSearchOptions = createSelector(
  selectBaseState, 
  selectSearchType, 
  (state, type) => {
    if(!type){
      return  [];
    }
    if(type === 'company'){
      return state.searchList.company.dataList;
    }else{
      return  state.searchList.business.dataList
    }
  }
)
export const selectBusinessReport = (companyId: number, businessTypeId: number) => createSelector(selectBaseState, state => state.reportDetails[`report-${companyId}-${businessTypeId}`])
export const selectSearchLoading = createSelector(selectBaseState, state => state.searchLoading)

export default financeReport.reducer as Reducer<typeof initialState>;
