import { createSlice, createSelector, createAsyncThunk, Reducer } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import pandora from '../../../api'
import {checkToken} from '../../user/reducer';

interface ListProps {
  data_list: Array<any>,
  page?: number,
  pageSize?: number,
  total?: number,
  total_pages?: number,
}

interface UserData {
  activedThemeId: number,
  activedThemeName?: string,
  activedCompany: number,
  activedEvent: number,
  activeTab: string
}
interface BigThemeProps {
  themes: ListProps
  lastestEvents: {
    [key:string]: any
  }
  themeDetails: any,
  eventDetails: any,
  opportunities: any,
  newActiveCompanyId: string
  currentRoom: '',
  rooms: {
    [key:string]: UserData
  },
  newsDetails:any,
  loading: {
    [key:string]: boolean
  }
}

const initialState: BigThemeProps = {
  themes: {
    data_list:[]
  },
  lastestEvents:{
    all: {
      dataList:[],
    }
  },
  themeDetails:{},
  currentRoom:'',
  eventDetails:{},
  opportunities: {},
  newActiveCompanyId: '',
  rooms:{},
  newsDetails:{},
  loading:{}
}

export const fetchThemes = createAsyncThunk(
  '/bigTheme/fetch-themes',
  async (args:void, thunkAPI) => thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchThemes())
)

export const fetchRelatedNewsDetails = createAsyncThunk(
  '/bigTheme/fetch-related-news-details',
  async (params:any, thunkAPI) => {
    const {newsId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchRelatedNewsDetails(params);
      return {
        ...result,
        newsId,
      }
    })
  }
)

export const fetchCompanyListByNewsId = createAsyncThunk(
  '/bigTheme/fetch-related-news-details-related-company-list',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.getCompanyListByNewsId(params);
      return {
        ...result
      }
    })
  }
)


export const fetchThemeById = createAsyncThunk(
  '/bigTheme/fetch-theme-by-id',
  (params:any, thunkAPI): Promise<any> => {
    const {id, thumbnailsId, page, size, ...restProps} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {code, data} = await pandora.fetchThemeById({...restProps,themeId: id, size: size, page:page})
      if(code === '0'){
        return {
          success: true,
          id,
          data,
          page,
          size
        }
      }
      return {
        success: false,
        id, 
        data: {},
        page,
        size
      }
    })
  }
)

export const fetchCompanyOpportunities = createAsyncThunk(
  '/bigTheme/fetch-company-opportunities',
  (id: number, thunkAPI):Promise<any> => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const { success, result } = await pandora.fetchCompanyOpportunities(id);
      if(success){
        return {
          success: true,
          id,
          result
        }
      }
      return {
        success: false,
        id, 
        result: {}
      }
    })
  }
)
export const fetchNewCompanyIdByOldCompanyId = createAsyncThunk(
    '/bigTheme/fetch-company-newid',
    async (params:any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(async (resp) => {
        const result = await pandora.fetchNewCompanyIdByOldCompanyId(params);
        return {
          ...result,
        }
      })
    }
  )

export const fetchEventSummary = createAsyncThunk(
  '/bigTheme/fetch-event-summary',
  (params: {company_id: number, event_id: number}, thunkAPI): Promise<any> => {
    const {event_id, company_id} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const [entities, graph, relateNews] = await pandora.fetchEventSummary({id:event_id, company_id: company_id});
      const result:any = {};
      if(!entities.code){
        result.entities = entities;
      }
      if(graph.success){
        result.graph = graph.result;
      }
      if(relateNews.success){
        result.relateNews = relateNews.result;
      }
      return {
        id: event_id, 
        success: true,
        result
      }
    })
  }
)

export const fetchLatestEvents = createAsyncThunk(
  '/bigTheme/fetch-latest-events',
  async (params: any, thunkAPI):Promise<any> => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchLatestEvents(params);
      return {
        ...result, 
        theme: 'all',
        direction: params.direction,
        page: params.page,
        hasMore: !Boolean(result.data.length < params.size)
      }
    })
  }
)

export const bigTheme = createSlice({
  name:'bigTheme',
  initialState,
  reducers: {
    setCurrentRoom:(state, {payload}) => {
      state.currentRoom = payload;
    },
    setThumbnailActiveTab: (state, {payload}) => {
      const {room, value} = payload;
      state.rooms[room] = {
        ...state.rooms[room],
        activeTab: value
      };
    },
    updateEventWatchLists: (state, {payload}) => {
      const {eventSpace = '', type = '', id} = payload;
      if(!eventSpace || !type){
        return;  
      }
      state.lastestEvents[eventSpace].watchlist = type === 'remove' ? state.lastestEvents[eventSpace].watchlist.filter((watchedId:number) => watchedId !== Number(id)) : [...state.lastestEvents[eventSpace].watchlist, id]
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchThemes.pending, (state) => {
      state.loading.fetchThemesLoading = true
    }).addCase(fetchThemes.fulfilled, (state, {payload}) => {
      const {success, result} = payload;
      if(success){
        state.themes = result
      }
      state.loading.fetchThemesLoading = false
    }).addCase(fetchThemeById.pending, (state) => {
      state.loading.fetchThemeLoading = true
    }).addCase(fetchThemeById.fulfilled, (state, {payload}) => {
      const {success, data, id, page, size} = payload;
      if(success) {
        const refactorList = data?.dataList.map((item:any) => ({...item, impactScore: item.companyImpactScoreData.impactScore}))
        state.themeDetails[id] = {
          dataList: page > 0 ? state.themeDetails[id].dataList.concat(refactorList) : refactorList,
          total: data.total,
          page: page + 1,
          hasMore: !Boolean(refactorList.length < size)
        }
      }else{
        state.themeDetails[id] = {
          dataList: [],
          total: 0,
          page: 0,
          hasMore: false
        }
      }
      state.loading.fetchThemeLoading = false
    }).addCase(fetchCompanyOpportunities.pending, (state) => {
      state.loading.fetchCompanyEventsLoading = true
    }).addCase(fetchCompanyOpportunities.fulfilled, (state, {payload}) => {
      const {success, result, id} = payload;
      if(success){
        state.opportunities[id] = result?.data;
      }
      state.loading.fetchCompanyEventsLoading = false
    }).addCase(fetchNewCompanyIdByOldCompanyId.pending, (state) => {
        // state.loading.fetchCompanyEventsLoading = true
      }).addCase(fetchNewCompanyIdByOldCompanyId.fulfilled, (state, {payload}) => {
        if( !payload.data){
          state.newActiveCompanyId = ''
        }else{
          state.newActiveCompanyId = payload.data.newCompanyId;

        }
        // state.loading.fetchCompanyEventsLoading = false
      })
    .addCase(fetchEventSummary.pending, (state) => {
      state.loading.fetchEventDetailLoading = true
    }).addCase(fetchEventSummary.fulfilled, (state, {payload}) => {
      const {success, result, id} = payload;
      if(success){
        const {entities, ...restResult} = result;
        const {data, ...restEntitiesProps} = entities;
        const normlizedData = data.reduce((all: {[key: string]: any}, dataItem: any) => {
          const {entities:currentEntities, entity_type} = dataItem;
          return {
                ...all,
                [entity_type]: currentEntities.map((item:any) => ({...item, company_name_en: item.name_en, company_name_zh: item.name_zh})),
              }
        }, {});
        state.eventDetails[id] = {
          ...restResult,
          entities:{
            ...restEntitiesProps,
            data_list: normlizedData
          }
        };
      }
      state.loading.fetchEventDetailLoading = false
    }).addCase(fetchLatestEvents.pending, (state) => {
      state.loading.fetchLatestEvents = true;
    }).addCase(fetchLatestEvents.fulfilled, (state, {payload}) => {
      const { data, theme = 'all', direction, page, hasMore } = payload;
      console.log('datadatadata=',data)
      if(data && data.length){
        const refactorSource = data.map((eventItem:any) => {
          return {
            ...eventItem,
            company_map: {
              themeList:eventItem.themeList,
              companyList: eventItem.companyList.map((item:any) => ({
                companyId: item.companyId,
                companyName: item.companyNameEn,
                companyNameEn: item.companyNameEn,
                companyNameZh: item.companyNameZh, 
                star: item.isWatchList
              }))
            }
          }
        })
        state.lastestEvents[theme] = {
          dataList: direction !== 'up' ? 
            state.lastestEvents[theme].dataList.concat(refactorSource) 
            : 
            refactorSource.concat(state.lastestEvents[theme].dataList),
          page,
          hasMore,
        };
        console.log('refactorSource',refactorSource)
      }else{
        state.lastestEvents[theme] = {
          ...state.lastestEvents[theme], 
          hasMore: false, 
          page
        };
      }
      state.loading.fetchLatestEvents = false;
    }).addCase(fetchRelatedNewsDetails.fulfilled, (state, {payload})=>{
      const {code, data, newsId} = payload;
      if(!code){
        state.newsDetails[newsId] = data;
      }
    })

  },
})

export const {setCurrentRoom, setThumbnailActiveTab, updateEventWatchLists  }  =  bigTheme.actions;

const selectBaseState = (state: RootState) => state.bigTheme;
export const selectThemeList = createSelector(selectBaseState, state => state.themes || []);
export const selectThemeDetailsById = (id: number) => createSelector(selectThemeList, state => state?.data_list?.find((item:any) => item.theme_id === id) || {})
export const selectThemeDetailById = (id: number) => createSelector(
  selectBaseState, state => state.themeDetails[id] || {dataList: [], page: 0, hasMore: true}
)

export const selectOpportunitiesDetailById = (id: number) => createSelector(
  selectBaseState, state => state.opportunities[id] || [] 
)
// export const selectNewCompanyId = createSelector(selectBaseState, state => state.newCompanyId || '' )
export const selectNewCompanyIdByOldCompanyId = (id: number) => createSelector(
    selectBaseState, state => state.newActiveCompanyId || ''
  )
export const selectEventDetailById = (id: number) => createSelector(
  selectBaseState, state => state.eventDetails[id] || {}
)
export const selectActiveThemeName = (activeTheme:number) => createSelector(selectThemeList , (themeList) => {
  if(!activeTheme || !themeList.data_list.length){
    return ''
  }
  return themeList.data_list.find((item:any) => item.theme_id === activeTheme)?.theme_name
});
export const selectLatestEvents = (theme:string) => createSelector(selectBaseState, (state) => state.lastestEvents[theme].dataList || [])
export const selectLatestEventsFetchOptions = (theme:string) => createSelector(selectBaseState, (state) => {
  const {dataList, ...restProps} = state.lastestEvents[theme];
  return restProps || {page: 0, hasMore: true}
})
export const selectWatcedList = (theme:string) => createSelector(selectBaseState, state => state.lastestEvents[theme].watchlist || []);
export const selectThumbnailActiveTab = (room:string) => createSelector(selectBaseState, state => {
  return (state.rooms[room] && state.rooms[room].activeTab) ? state.rooms[room].activeTab : 'theme'
})
export const selectLoadingByType = (type:string) => createSelector(selectBaseState, state => {
  if(!type){
    return false;
  }
  return state.loading[type] || false
}) 

export default bigTheme.reducer as Reducer<typeof initialState>;
