import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import pandora from '../../../api';
import {checkToken} from '../../user/reducer';
import moment from 'moment';
interface StateProps {
  mailStatus: any,
  newsDetail:any,
  activeWeekly:any,
  weeklyReports:Array<any>,
  loading: boolean,
  heJunNewsOptions:{
    dataList: Array<any>,
    total: number, 
    hasMore?: boolean,
    page?: number
  },
  heJunNewsThOptions:{
    dataList: Array<any>,
    total: number, 
    hasMore?: boolean,
    page?: number
  },
}

const initialState: StateProps = {
  mailStatus:{
    isSend:false,
    roomPermissionId:'',
  },
  newsDetail:{},
  activeWeekly:{},
  weeklyReports:[],
  loading: false,
  heJunNewsOptions:{
    dataList: [],
    total: 0, 
    hasMore: true,
    page:1
  },
  heJunNewsThOptions:{
    dataList: [],
    total: 0, 
    hasMore: true,
    page:1
  },
}

export const getSendMailStatus = createAsyncThunk(
  'hejun/get-Send-MailStatus',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchSendMailStatus({...params}))
  }
)
export const fetchSaveSendMailStatus = createAsyncThunk(
  'hejun/save-mail-status',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchSaveSendMailStatus({...params}))
  }
)

export const fetchHeJunNews = createAsyncThunk(
  'hejun/fetch-hejun-news',
  (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {data} = await pandora.fetchHeJunNews(params);
      return {
        data: data,
        ...params
      }
    })
  }
)
export const fetchHeJunNewsTh = createAsyncThunk(
  'hejun/fetch-th-hejun-news',
  (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {data} = await pandora.fetchHeJunNews(params);
      return {
        data: data,
        ...params
      }
    })
  }
)

export const fetchHeJunWeeklyReports = createAsyncThunk(
  'risk-mangement/fetch-hejun-weekly-report',
  (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {data} = await pandora.fetchHeJunWeeklyReports(params);
      return {
        data: data,
        ...params
      }
    })
  }
)



export const hejunNews = createSlice({
  name:'hejunNews',
  initialState,
  reducers: {
    setNewsDetail: (state, {payload}) => {
      state.newsDetail = payload;
    },
    setActiveWeekly : (state, {payload}) => {
      state.activeWeekly = payload;
    },
    restNewsOptions:(state, {payload}) => {
      state.heJunNewsOptions= payload
    },
  },
  extraReducers:(builder) => {
    builder.addCase(getSendMailStatus.fulfilled, (state, {payload}) => {
      state.mailStatus = payload.data
    }).addCase(fetchHeJunNews.pending, state => {
      state.loading = true
    }).addCase(fetchHeJunNews.fulfilled, (state, {payload}) => {
      const {data} = payload;
      const {newsList, total, ...restProps} = data;
      const newNewsList = newsList.length ? newsList.map((item:any) => (
        {...item,
          TimeRanges: moment(Number(item.newsDate)).format('HH:mm') +' '+ moment(Number(item.newsDate)).format('MMM DD'),
          imgType: item.imageUrls? (item.imageUrls.length >= 3 ? 3 : 2) : 1
        }
        )) : []
      state.heJunNewsOptions = {
        ...restProps,
        dataList:state.heJunNewsOptions.dataList.length ? state.heJunNewsOptions.dataList.concat(newNewsList):newNewsList,
        total:total,
        hasMore: !Boolean(newNewsList.length < 10),
        page: state.heJunNewsOptions.page? state.heJunNewsOptions.page + 1 :state.heJunNewsOptions.page ,
      }
      state.loading = false
    }).addCase(fetchHeJunNewsTh.fulfilled, (state, {payload}) => {
      const {data} = payload;
      const {newsList, total, ...restProps} = data;
      const newNewsList = newsList.length ? newsList.map((item:any) => (
        {...item,
          TimeRanges: moment(Number(item.newsDate)).format('HH:mm') +' '+ moment(Number(item.newsDate)).format('MMM DD'),
          imgType: item.imageUrls? (item.imageUrls.length >= 3 ? 3 : 2) : 1
        }
        )) : []
      state.heJunNewsThOptions = {
        ...restProps,
        dataList:state.heJunNewsThOptions.dataList.length ? state.heJunNewsThOptions.dataList.concat(newNewsList):newNewsList,
        total:total,
        hasMore: !Boolean(newNewsList.length < 10),
        page: state.heJunNewsThOptions.page? state.heJunNewsThOptions.page + 1 : state.heJunNewsThOptions.page,
      }
    }).addCase(fetchHeJunWeeklyReports.fulfilled, (state, {payload}) => {
      const {data} = payload;
      data.forEach((item:any) => {
        var total = 0;
        item.weekList.forEach((weekItem:any) => {
          total +=  weekItem.newsCount
        });
        item.total = total
      });
      
      state.weeklyReports = data
    })
  }
})

export const {setNewsDetail, setActiveWeekly, restNewsOptions} = hejunNews.actions;

const selectBaseState = (state: RootState)  => state.hejunNews;
export const selectWeeklyReports = createSelector(selectBaseState, state => state.weeklyReports || [])
export const selectMailStatus = createSelector(selectBaseState, state => state.mailStatus)
export const selectLoadingByType = createSelector(selectBaseState, state => state.loading)
export const selectNewsDetails = createSelector(selectBaseState, state => state.newsDetail)
export const selectActiveWeekly = createSelector(selectBaseState, state => state.activeWeekly)
export const selectHeJunNews = createSelector(selectBaseState, state => state.heJunNewsOptions.dataList);
export const selectHeJunNewsTh = createSelector(selectBaseState, state => state.heJunNewsThOptions.dataList);
export const newsFetchMoreOptions = createSelector(selectBaseState, state => {
  const {dataList, ...othersProps} = state.heJunNewsOptions;
  return othersProps;
}) 
export const newsFetchMoreThOptions = createSelector(selectBaseState, state => {
  const {dataList, ...othersProps} = state.heJunNewsThOptions;
  return othersProps;
}) 
export default hejunNews.reducer;
