import { createSlice, createSelector, createAsyncThunk} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import pandora from '../../api';
import {enqueueSnackbar} from '../snackbar/reducer';
import { checkToken } from '../user/reducer';
import {closeDialog} from '../dialog/reducer';
import {FloorProps as FloorDataProps} from '../../types';
const {Map} = require('immutable');

interface FloorProps {
  userOpenFloorList: any,
  savedFloorList: any, 
  historyFloorList: any, 
  subscribedRoom:any,
  savedIdList: Array<string>, 
  renameFloorProps: any,
  saveFloorProps: any
}

const initialState: FloorProps =  {
  userOpenFloorList: {},
  savedFloorList: {},
  historyFloorList: {},
  savedIdList: [],
  subscribedRoom:{
    dataList:[],
    total: 0,
  },
  renameFloorProps: {
    type:'',
    floorId: ''
  },
  saveFloorProps: {
    type:'',
    floorId: ''
  }
}

export const saveFloor = createAsyncThunk(
  'header/save-floor',
  async (values:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.saveFloor(values)).then(resp => {
      thunkAPI.dispatch(getOpenFloorList())
      thunkAPI.dispatch(getMasterFloorIdList({floorStatus: 'save'}))
      return resp;
    })
  }
);

export const getFloorListByType = createAsyncThunk(
  'header/get-floor-list-by-type',
  (params:any, thunkAPI) => {
    const {floorStatus} = params;
    return thunkAPI.dispatch(checkToken()).then(async resp => {
      const {data} = await pandora.getFloorList(params)
      return {
        floorStatus, 
        data
      }
    })
  }
);

export const deleteFloor = createAsyncThunk(
  'floor/delete-floor',
  async (values:any, thunkAPI) => {
    const {type, ...params} = values
    return thunkAPI.dispatch(checkToken()).then(resp => {
      return pandora.deleteFloor(params);
    }).then(resp => {
      thunkAPI.dispatch(getFloorListByType({floorStatus: type, page: 0, size: 60}))
      return resp;
    })
  }
)

export const duplicateFloor = createAsyncThunk(
  'floor/duplicate-floor',
  async (params:any, thunkAPI) => {
    const {type, ...restParams} = params
    return thunkAPI.dispatch(checkToken()).then(resp => {
      return pandora.duplicateFloor(restParams);
    }).then(resp => {
      thunkAPI.dispatch(getFloorListByType({floorStatus: "save", page: 0, size: 60}))
      return resp;
    })
  }
)


export const saveMasterFloor = createAsyncThunk(
  'floor/save-master-floor',
  async (values:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.saveMasterFloor(values)).then((resp) => {
        const {code = 0, msg='' } = resp
        thunkAPI.dispatch(enqueueSnackbar({
          message: !code ? 'success' : msg,
          options: {
            key: !code ? 'saveOpenFloorSuccess' : 'saveOpenFloorFail',
            variant: !code ? 'success' : 'error',
          },
        }))
        if(!code){
          thunkAPI.dispatch(closeDialog('save-floor'))
        }
        thunkAPI.dispatch(getMasterFloorIdList({floorStatus: 'save'}));
        thunkAPI.dispatch(getOpenFloorList());
      return resp;
    })
  }
)


export const updateFloor = createAsyncThunk(
  'floor/update-floor',
  async (values:any, thunkAPI) => {
    const {type} = values;
    return thunkAPI.dispatch(checkToken()).then(resp => {
      return pandora.updateFloor(values);
    }).then(resp => {
      thunkAPI.dispatch(closeDialog('rename-floor'))
      if(type){
        thunkAPI.dispatch(getFloorListByType({floorStatus: 'save', page: 0, size: 60}))
      }
      thunkAPI.dispatch(getOpenFloorList())
      return resp;
    })
  }
)

export const getOpenFloorList = createAsyncThunk(
  'floor/get-open-floor-list',
  async (payload, thunkAPI):Promise<any> => {
    const params:any = {
      size:30, 
      page:0,
    }
    return thunkAPI.dispatch(checkToken()).then(async (resp) => pandora.getOpenFloorList(params))
  }
)

export const getMasterFloorIdList = createAsyncThunk(
  'header/get-floor-list',
  async (values:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.getMasterFloorIdList(values);
      return {
        ...result
      }
    })
  }
)

export const batchOpenFloor = createAsyncThunk(
  'header/batch-open-floor-list',
  async (values:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => pandora.batchOpenFloor(values)).then(resp => {
      thunkAPI.dispatch(getOpenFloorList());
      return resp;
    })
  }
)

export const addRoomForFloor = createAsyncThunk(
  'header/add-room-for-floor',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => pandora.addFloorRoom(params)).then(resp => {
      thunkAPI.dispatch(getOpenFloorList())
      return resp;
    })
  }
)

export const fetchFloorDetails = createAsyncThunk(
  'header/fetch-floor-details',
  async (params:any, thunkAPI) => {
    const {floorId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => pandora.fetchFloorDetails(params)).then(resp => {
      thunkAPI.dispatch(updateFloorDetailsById({floorId, data: resp.data}))
      return resp;
    })
  }
)
export const updateFloorLayouts = createAsyncThunk(
  'header/update-floor-layout',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => pandora.updateFloorLayouts(params)).then(resp => {
      thunkAPI.dispatch(getOpenFloorList());
      return resp;
    })
  }
)


export const deleteFloorRoom = createAsyncThunk(
  'header/update-floor-layout',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => pandora.deleteFloorRoom(params)).then(resp => {
      thunkAPI.dispatch(getOpenFloorList());
      return resp;
    })
  }
)

export const updateFloorRoomMetadata = createAsyncThunk(
  'floor/update-floor-room-metadata',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => pandora.updateFloorRoomMetadata(params)).then(resp => {
      thunkAPI.dispatch(getOpenFloorList());
      return resp;
    })
  }
)
export const updateSnapshotFloor = createAsyncThunk(
  'floor/update-snapshot-floor',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => pandora.updateSnapshotFloor(params)).then(resp => {
      thunkAPI.dispatch(getOpenFloorList());
      return resp;
    })
  }
)

export const floor = createSlice({
  name:'floor',
  initialState,
  reducers: {
    updateFloorDetailsById: (state, {payload}) => {
    },
    setRenameFloorProps: (state, {payload}) => {
      state.renameFloorProps = payload;
    },
    setSaveFloorProps: (state, {payload}) => {
      state.saveFloorProps = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(deleteFloor.fulfilled, (state, {payload}) => {
    }).addCase(getFloorListByType.fulfilled, (state, {payload}) => {
      const {floorStatus, data} = payload;
      if(floorStatus === 'save'){
        state.savedFloorList = data
      }else{
        state.historyFloorList = data
      }
    }).addCase(getOpenFloorList.fulfilled, (state, {payload}) => {
      const { code, data } = payload;
      if(!code){
        const {dataList = []} = data;
        const refactorDataList = dataList?.map((item:any )=> {
          const {thumbnails} = item;
          interface ThumbnailsProps {
            [key: string]: any
          }
          const refactorThumbnails= thumbnails ? (Object.keys(thumbnails) || [])?.reduce((all:ThumbnailsProps, current:string) => {
            const refactorRoom = thumbnails[current][0]
            return {
              ...all,
              [current]: refactorRoom
            }
          },{}) : {}
          return {
            ...item, 
            layouts: item.layouts ? JSON.parse(item.layouts) : {sm: [], md: [], lg: []}, 
            thumbnails: refactorThumbnails
          }
        })
        state.userOpenFloorList = {
          ...data,
          dataList: refactorDataList
        }
      }
    }).addCase(getMasterFloorIdList.fulfilled, (state, {payload}) => {
      const { data } = payload;
      state.savedIdList = data
    })
  },
})

export const {updateFloorDetailsById, setRenameFloorProps, setSaveFloorProps} = floor.actions;

const selectBaseState = (state: RootState) => state.floor;
export const selectOpenFloorList = createSelector(selectBaseState, state => state.userOpenFloorList.dataList || [])
export const selectFloorListByType = (floorStatus: string) =>  createSelector(selectBaseState, state => {
  if(!floorStatus){
    return [];
  }
  if(floorStatus === 'save'){
    return state.savedFloorList?.dataList || [];
  }else{
    return state.historyFloorList?.dataList || [];
  }
})
export const selectSavedIdList = createSelector(selectBaseState, state => state.savedIdList || [])
export const selectRenameFloorProps = createSelector(selectBaseState, state => state.renameFloorProps || {})
export const selectSaveFloorProps = createSelector(selectBaseState, state => state.saveFloorProps || {})
export const selectFloorIdByKey = (key:string) => createSelector(selectBaseState, state => {
  if(!key || !state.userOpenFloorList.dataList?.find((item:any) => item.key === key)){
    return ''
  }
  return state.userOpenFloorList.dataList?.find((item:any) => item.key === key).id
})
export const selectFloorDataByKey = (key:string) => createSelector(selectBaseState, state => {
  if(!key || !state.userOpenFloorList.dataList?.find((item:any) => item.key === key)){
    return ''
  }
  return state.userOpenFloorList.dataList?.find((item:any) => item.key === key) || {} as FloorDataProps
})
export const selectRoomDataByFloorAndRoomKey = (floor:string, room:string) => createSelector(selectFloorDataByKey(floor),  (floorData) => {
  if(!floor || !room || !floorData.thumbnails){
    return {}
  }
  const roomKey = room.split('-')[0];
  return Map(floorData.thumbnails).getIn([roomKey, room],{});
})

export default floor.reducer; 
