import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import pandora from '../../api';
import {PAGE_SIZE} from '../../types/constant';

interface SearchProps {
  loading: boolean,
  searchWord: string,
  searching: boolean,
  searchType: string,
  searchList: {
    [key: string]: {
      dataList: Array<any>,
      watchlist?: any
    }
  }
}

const initialState: SearchProps = {
  loading: false,
  searchWord: '',
  searching: false,
  searchType: '',
  searchList: {}
}

export const searchCompany = createAsyncThunk(
  'search/search-company',
  async (params:any, thunkAPI):Promise<any> => pandora.searchCompany({...params, page: 0,  size: PAGE_SIZE*2 })
)

export const search = createSlice({
  name:'search',
  initialState,
  reducers: {
    setSearching: (state, {payload}) => {
      state.searching = payload;
    },
    setSearchType: (state, {payload}) => {
      state.searchType = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(searchCompany.pending, (state) => {
      state.loading = true;
    }).addCase(searchCompany.fulfilled, (state, {payload}) => {
      const {data, code} = payload;
      if(!code){
        state.searchList.company = {
          ...data,
          dataList: data.companyList
        }
      }
      state.loading = false;
    })
  }
})

export const {setSearching, setSearchType}  = search.actions;

const selectBaseState = (state: RootState)  => state.search;
const selectSearchType = createSelector(selectBaseState, state => state.searchType);
export const selectSearchStatus = createSelector(selectBaseState, state => state.searching);

export const selectDataList = createSelector(selectBaseState, selectSearchType, (state, searchType) => {
  if(!searchType){
    return [];
  }
  return state.searchList[searchType]?.dataList || [];
})

export default search.reducer;
