import Pandora from './pandora';
import {loadState} from '../utils';

const userState = Object.keys(loadState()).length ? JSON.parse(loadState()?.user) : {};

const pandora = new Pandora({
  token: userState.tokenInfo?.token || '',
  rsToken: userState.tokenInfo?.refresh_token || '',
  tokenInfo: userState.tokenInfo || {},
  language: userState.language || 'en'
});

export default pandora;