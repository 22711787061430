import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import pandora from '../../../api';
import {checkToken} from '../../user/reducer';

interface StateProps {
  loading: boolean,
  companyList: Array<any>,
  generatedList: Array<any>,
  nodataCompanyList: Array<string>
}

const initialState: StateProps = {
  loading: false,
  companyList: [],
  generatedList: [],
  nodataCompanyList:[]
}

export const getCompanyNopat = createAsyncThunk(
  'hejun/get-company-nopat',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.getCompanyNopat(params))
  }
)

export const hejun = createSlice({
  name:'hejun',
  initialState,
  reducers: {
    addSelectedCompany:(state, {payload}) => {
      if(state.companyList.length){
        state.companyList = [
          ...state.companyList.filter(company => company.companyId !== payload.companyId),
          payload
        ] 
      }else{
        state.companyList = [
          ...state.companyList,
          payload
        ];
      }
    },
    removeSelectedCompany:(state, {payload}) => {
      state.companyList = state.companyList.filter(company => company.companyId !== payload);
    },
    setGenerateCompanies: (state, {payload}) => {
      state.generatedList = payload;
    },
    setNodataCompany: (state, {payload}) => {
      state.nodataCompanyList = payload;
    }
  },
  extraReducers:(builder) => {
    builder.addCase(getCompanyNopat.fulfilled, (state, {payload}) => {
    })
  }
})

export const {addSelectedCompany, removeSelectedCompany, setGenerateCompanies, setNodataCompany} = hejun.actions;

const selectBaseState = (state: RootState)  => state.hejun;
export const selectCompanyList = createSelector(selectBaseState, state => state.companyList || [])
export const selectGenerateCompanyList = createSelector(selectBaseState, state => state.generatedList || [])
export const selectNodataCompanyList = createSelector(selectBaseState, state => state.nodataCompanyList || [])

export default hejun.reducer;
