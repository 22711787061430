import { createSlice, createSelector} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {UserSettingsProps} from '../../types';

interface DashboardProps {
  dashboardSettings: UserSettingsProps,
  currentFloor: string,
  activeThumbnail: string,
  headerVisible: boolean,
  navBarVisible: boolean,
}

const initialState: DashboardProps = {
  dashboardSettings: {} as UserSettingsProps,
  currentFloor: '',
  activeThumbnail: '',
  headerVisible: true,
  navBarVisible: true,
}

export const dashboard = createSlice({
  name:'dashboard',
  initialState,
  reducers: {
    setDashboardFloors: (state, {payload}) => {
      if(payload){
        state.dashboardSettings = payload;
        state.currentFloor = Object.keys(payload)[0];
      }
    },
    setCurrentFloor: (state,{payload}) => {
      state.currentFloor = payload;
      state.activeThumbnail = '';
    },
    setFullScreen: (state, {payload}) => {
      if(payload === 'navBar') {
        state.navBarVisible = false;
      }
      if(payload === 'header') {
        state.headerVisible = false;
      }
    },
    exitFullScreen: (state) => {
      state.headerVisible = true;
      state.navBarVisible = true
    }
  },
})

export const { 
  setDashboardFloors, 
  setCurrentFloor, 
  setFullScreen, 
  exitFullScreen 
} = dashboard.actions;

const selectDashboard =  (state: RootState)  => state.dashboard

export const selectDashboardSettings = createSelector(selectDashboard, dashboard => dashboard.dashboardSettings);
export const selectCurrentFloor = createSelector(selectDashboard, dashboard => dashboard.currentFloor);
export const selectActiveThumbnail = createSelector(selectDashboard, dashboard => dashboard.activeThumbnail);
export const selectHeaderVisible = createSelector(selectDashboard, dashboard => dashboard.headerVisible);
export const selectNavBarVisible = createSelector(selectDashboard, dashboard => dashboard.navBarVisible);
export const selectisFullscreen = createSelector(selectHeaderVisible, selectNavBarVisible, (headerVisible, navBarVisible) => !headerVisible && !navBarVisible)

export default dashboard.reducer;
