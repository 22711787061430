import { createSlice, createSelector, createAsyncThunk, Reducer } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import pandora from '../../../api';
import {checkToken} from '../../user/reducer';
import Bignumber from 'bignumber.js';
import moment from 'moment';

const {Map} = require('immutable');
interface InitialStateProps {
  themeMapData:{
    [key:number]: {
      relation_info: any,
      structure_info: any
    }
  },
  exchangeList:{
    [key:number]: Array<any>
  },
  companyProfiles:{
    [key:number]: any
  },
  companyInformations:{
    [key:number]: any
  },
  loading: {
    [key:string]: boolean
  },
  companyInSights: {
    [key:string]: any
  },
  industryDetails:{
    [key:string]: any
  },
  nodeDetails:{
    [key:string]: any
  },
  indexData: Array<any>,
  nodeIndexDetails: {
    typeList: Array<any>,
    detailsList: {
      [key: string]: Array<any>
    }
  },
  opportunities: any,
  opportunitiesTwo: any,
  opportunitiesTotal: any,
  eventDetails: any,
  reportYears:Array<any>,
  reportUrl:any,
  reportFuture:Array<any>,
  reportCoreCompetence:Array<any>,
  companyResearch:{
    project:any,
    employees:any,
    spending:any,
  },
  newCompanyProfile:any,
  newCompanyId:string,
  business:{
    industry:any,
    product:any,
    region:any,
  },
  researchList:Array<any>,
  researchUrl:string,
  reportInvestment: Array<any>,
  reportCAGR:Array<any>,
  reportCrnTab: Array<any>,
  reportCrn: {
    [key: string]: Array<any>;
  },
  policyList:{
    dataList:Array<any>,
    total:number,
  }, 
  policyInfo:any,
  topCompanyEvent:Array<any>,
  treeData: Array<any>,
  wordCloud: Array<any>,
  manyEmpty: any,
  mentionTrends:any,
  stockRatioTrend:Array<any>,
  articleList:Array<any>,
  articleListOptions: {
    articleList: Array<any>,
    total: number,
    hasMore?: boolean,
    size: number,
    page?: number
  },
  businessPerformance:any,
  selectArr:Array<any>,
  barInfo:any,
  companyVs:{
    [key:string]: any
  },
  sliderInfo:{
    max: number,
    min:number,
    activeValue:number,
    marks:Array<any>
  }
  activetimeLine:string,
  swId:string,
  swCompany:Array<any>,
  swFilterIdsValue:Array<any>,
  swFilterIdsObjValue:Array<any>,
  filterObj:any,
  companyCompetitorsData:{
    scatterData:Array<any>
  },
  selectCompanyColor:any,
  businessReportId:string,
  isSearch:boolean,
  searchCompanyList:Array<any>,
  allAgencyName: Array<any>,
  allAgencyType: Array<any>,
  allEventType: Array<any>,
  policyStatistics: Array<any>,
  countCompanyEvent: any,
  articleDetail:any,
  distributingCompanyEventScore:Array<any>,
  newTopCompanyEvent:Array<any>
  reportTextDetail:any,
  newTopCompanyPolicyImportantDetail:any,
  companyPolicyProfile:Array<any>,
  companyPolicyImportant:Array<any>,
  companyReportAnalysisDetail:{
    [key:string]: any
  },
  companyPriceCauseDetail:{
    [key:string]: any
  },
  companyPriceCauseOvertime:Boolean,
  companyReportAnalysisOvertime: Boolean,
  jumpToPage:any,
  jumpActivePage:any,
  aliPayQrInfo:any,
  wechatPayQrInfo:any,
  paymentStatusObj:any,
  companyModel:any,
  userCouponInfo:any,
  userCouponStatus:Boolean,
  announcementList:{
    dataList:Array<any>,
    total:number
  },
  announcementDetail:any,
  companyNewsList:{
    dataList:Array<any>,
    total:number
  },
  companyNewsDetail:any,
  companyNewsEvent:any,
  announcementEvent:any
}

const initialState: InitialStateProps = {
  themeMapData:{},
  exchangeList: {},
  loading:{},
  companyProfiles:{},
  companyInformations:{},
  companyInSights: {},
  industryDetails:{},
  nodeDetails:{},
  indexData: [],
  nodeIndexDetails:{
    typeList:[],
    detailsList: {}
  },
  opportunities: {},
  opportunitiesTwo: {},
  opportunitiesTotal: 0,
  eventDetails: {},
  reportYears:[],
  reportUrl:'',
  reportFuture:[],
  reportCoreCompetence:[],
  companyResearch:{
    project:{},
    employees:{},
    spending:{},
  },
  newCompanyProfile:{},
  newCompanyId:'',
  business:{
    industry:[],
    product:[],
    region:[]
  },
  researchList:[],
  researchUrl:'',
  reportInvestment:[],
  reportCAGR:[],
  reportCrnTab: [],
  reportCrn: {},
  policyList:{
    dataList:[],
    total:0,
  }, 
  policyInfo:{},
  topCompanyEvent:[],
  treeData: [],
  wordCloud:[],
  manyEmpty: {'xAxis': [], 'trend': []},
  mentionTrends:{'xAxis': [], 'trend': [], 'maxNum':10},
  stockRatioTrend:[],
  articleList:[],
  articleListOptions: {
    articleList: [],
    total: 0,
    size: 10,
    hasMore: true,
    page: 0
  },
  businessPerformance:{'xAxis': [], 'revenue': [],'operatingIncome': [],'netIncomeBeforeTax': [],'netIncomeAfterTax': []},
  selectArr:['revenue','operatingIncome','netIncomeBeforeTax','netIncomeAfterTax'],
  barInfo:{barId:'',barName:''},
  companyVs:{},
  sliderInfo:{
    max: 1,
    min:0,
    activeValue:1,
    marks:[]
  },
  activetimeLine:'',
  swId:'',
  swCompany:[],
  swFilterIdsValue:[],
  swFilterIdsObjValue:[],
  filterObj:{
    "beginMarketCap": null,
    "beginPEPercentile": null,
    "beginProfit": null,
    "beginRevenue": null,
    "endMarketCap": null,
    "endPEPercentile": null,
    "endProfit": null,
    "endRevenue": null,
    "profitGrowthRate": null,
    "revenueGrowthRate": null,
    "roe": null,
  },
  companyCompetitorsData:{
    scatterData:[]
  },
  selectCompanyColor:{},
  businessReportId:'',
  isSearch:false,
  searchCompanyList:[],
  allAgencyName: [],
  allAgencyType: [],
  allEventType: [],
  policyStatistics:[],
  articleDetail:{},
  reportTextDetail: '',
  countCompanyEvent:{},
  distributingCompanyEventScore:[],
  newTopCompanyEvent:[],
  newTopCompanyPolicyImportantDetail:{},
  companyPolicyProfile:[],
  companyPolicyImportant:[],
  companyReportAnalysisDetail:{},
  companyPriceCauseDetail:{},
  companyPriceCauseOvertime:false,
  companyReportAnalysisOvertime: false,
  jumpToPage:{page:-1,text:null},
  jumpActivePage:-1,
  aliPayQrInfo:{
    outTradeNo:'',
    qrData:''
  },
  wechatPayQrInfo:{
    outTradeNo:'',
    qrData:''
  },
  paymentStatusObj:{},
  companyModel:{},
  userCouponInfo:{},
  userCouponStatus:false,
  announcementList:{
    dataList:[],
    total:0
  },
  announcementDetail:{},
  companyNewsList:{
    dataList:[],
    total:0
  },
  companyNewsDetail:{},
  companyNewsEvent:{},
  announcementEvent:{}
}

export const fetchExchangeList = createAsyncThunk(
  '/theme-map/fetch-exchange-list',
  async (themeId:number):Promise<any> => {
    const result = await pandora.fetchExchangeList({themeId})
    return {
      ...result.data,
      themeId,
    }
  }
)

export const fetchThemeMapData = createAsyncThunk(
  '/theme-map/fetch-theme-map-data',
  async (params: any):Promise<any> => {
    const {themeId} = params;
    const {result} = await pandora.fetchThemeMap(params);
    return {
      data:result.data,
      themeId,
    }
  }
)

export const fetchCompanyProfile = createAsyncThunk(
  '/theme-map/fetch-company-profile',
  async (params: any):Promise<any> => {
    const { companyId } = params;
    const { data } = await pandora.fetchCompanyProfile(params);
    return {
      data,
      companyId,
    }
  }
)


export const fetchCompanyInformation = createAsyncThunk(
  '/theme-map/fetch-company-information',
  async (params: any):Promise<any> => {
    const { companyId } = params;
    const [{data: companyBasic}, {data: companyInsightsType}] = await pandora.fetchCompanyInformation(params);
    return {
      data: {
        ...companyBasic,
        insightsTypes: companyInsightsType
      },
      companyId,
    }
  }
)

export const fetchCompanyInsightsType = createAsyncThunk(
  '/theme-map/fetch-company-insights-type',
  async (params: any):Promise<any> => {
    const { companyId,  } = params;
    const pageParams = {
      size: 10,
    }
    const { data } = await pandora.fetchCompanyInsightsType({...params, ...pageParams});
    return {
      data,
      companyId,
    }
  }
)
export const fetchCompanyInsights = createAsyncThunk(
  '/theme-map/fetch-company-insights',
  async (params: any):Promise<any> => {
    const { companyId, insightType, ...restProps} = params;
    const pageParams = {
      "endTimestamp": Date.now(),
      "page": 0,
      "size": 10,
    }
    const { data } = await pandora.fetchCompanyInsights({...restProps, companyId, ...pageParams});
    return {
      data,
      companyId,
      insightType,
    }
  }
)

export const fetchIndustryDetails = createAsyncThunk(
  'theme-map/fetch-industry-details',
  (params:any, thunkAPI) => {
    const {themeId} = params;
    return thunkAPI.dispatch(checkToken()).then(async resp => {
      const result = await pandora.fetchIndustryInformation(params);
      return {
        data: result.data,
        themeId
      }
    });
  }
)

export const fetchNodeDetails = createAsyncThunk(
  'theme-map/fetch-node-details',
  (params:any, thunkAPI) => {
    const {nodeId} = params;
    return thunkAPI.dispatch(checkToken()).then(async resp => {
      const result = await pandora.fetchNodeInformation(params);
      return {
        data: result.data,
        nodeId
      }
    });
  }
)


export const fetchNodeIndexCategory = createAsyncThunk(
  'theme-map/fetch-node-index-category',
  (params:void, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchNodeIndexCategory());
  }
)

export const fetchNodeIndexRation = createAsyncThunk(
  'theme-map/fetch-node-index-ration',
  (params:any, thunkAPI) => {
    const {categoryId, nodeId} = params;
    return thunkAPI.dispatch(checkToken()).then(async resp => {
      const result = await pandora.fetchNodeIndexRation(params);
      return {
        data: result.data,
        categoryId,
        nodeId
      }
    });
  }
)

export const fetchIndustryIndex = createAsyncThunk(
  'theme-map/fetch-industry-index',
  (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async resp => {
      const [{data: industryData}, {data: hs300Data}] = await pandora.fetchIndustryIndex(params);
      const refactorData = industryData.reverse().concat(hs300Data.reverse()).filter((item:any) => (item.minPrice > 0 && item.maxPrice)).map((item:any) => ({date: item.date, price: new Bignumber(item.minPrice).plus(item.maxPrice).div(2).toNumber(), indexName: item.indexName}))
      return {
        data: refactorData
      }
    });
  }
)



export const fetchTopCompanyMarketCap = createAsyncThunk(
  'theme-map/fetch-industry-top-company-market-cap',
  (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(resp => pandora.fetchIndustryTopCompanyMarketCap(params));
  }
)

export const fetchGetEventDetail = createAsyncThunk(
  '/supply-chain/fetch-get-event-detail',
  async (params:any, thunkAPI) => {
    const {eventId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetEventDetail(params);
      return {
        ...result,
        eventId,
      }
    })
  }
)

export const fetchGetReportYears = createAsyncThunk(
  '/theme-map/fetch-get-report-years',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetReportYears(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetCompanyProfile = createAsyncThunk(
  '/theme-map/fetch-get-company-profile',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyProfile(params);
      return {
        ...result
      }
    })
  }
)

export const saveCompanySearch = createAsyncThunk(
  '/theme-map/save-company-search',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.saveCompanySearch(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetReportUrl = createAsyncThunk(
  '/theme-map/fetch-get-report-url',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetReportUrl(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetCoreCompetenceAndFuture = createAsyncThunk(
  '/theme-map/fetch-get-core-competence-and-future',
  async (params:any, thunkAPI) => {
    const {type} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCoreCompetenceAndFuture(params);
      return {
        ...result,
        type
      }
    })
  }
)

export const fetchGetCompanyResearch = createAsyncThunk(
  '/theme-map/fetch-get-company-research',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyResearch(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetCompanyIdByStockCode = createAsyncThunk(
  '/theme-map/fetch-get-company-id-by-stock-code',
    async (params:any) => pandora.fetchGetCompanyIdByStockCode(params)
)
export const fetchGetReportTabPage = createAsyncThunk(
  '/theme-map/fetch-get-report-tab-page',
    async (params:any) => pandora.fetchGetReportTabPage(params)
)



export const fetchGetEventDataListByCompany = createAsyncThunk(
  '/theme-map/fetch-get-event-dataList-by-company',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetEventDataListByCompany(params);
      return {
        ...result,
      }
    })
  }
)

export const getEventDataListByCompanyId = createAsyncThunk(
  '/theme-map/get-event-data-list-by-company-id',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.getEventDataListByCompanyId(params);
      return {
        ...result,
      }
    })
  }
)

export const fetchGetEventDataListByCompanyEventOne = createAsyncThunk(
    '/theme-map/fetch-get-event-dataList-by-compan-event',
    async (params:any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(async (resp) => {
        const result = await pandora.fetchGetEventDataListByCompanyEventOne(params);
        return {
          ...result,
          params: params,
        }
      })
    }
  )
export const fetchGetBusiness = createAsyncThunk(
  '/theme-map/fetch-get-business',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetBusiness(params);
      return {
        ...result,
      }
    })
  }
)

export const fetchGetResearchList = createAsyncThunk(
  '/theme-map/fetch-get-research-list',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetResearchList(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetIndicatorList = createAsyncThunk(
  '/theme-map/fetch-get-indicator-list',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetIndicatorList(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetResearchUrl = createAsyncThunk(
  '/theme-map/fetch-get-research-url',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetResearchUrl(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetReportInvestment = createAsyncThunk(
  '/theme-map/fetch-get-report-investment',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetReportInvestment(params);
      return {
        ...result
      }
    })
  }
)
export const fetchGetReportCRn = createAsyncThunk(
  '/theme-map/fetch-get-report-CRn',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetReportCRn(params);
      return {
        ...result
      }
    })
  }
)
export const fetchGetReportCAGR = createAsyncThunk(
  '/theme-map/fetch-get-report-CAGR',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetReportCAGR(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetCompanyPolicyList = createAsyncThunk(
  '/theme-map/fetch-get-company-policy-list',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyPolicyList(params);
      return {
        ...result
      }
    })
  }
)
export const fetchGetPolicyInfo = createAsyncThunk(
  '/theme-map/fetch-get-policy-info',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetPolicyInfo(params);
      return {
        ...result
      }
    })
  }
)
export const fetchGetTopCompanyEvent = createAsyncThunk(
  '/theme-map/fetch-get-top-company-event',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetTopCompanyEvent(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetStockCompanyHeatGraphList = createAsyncThunk(
  '/theme-map/get-stock-company-heat-graph-list',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetStockCompanyHeatGraphList();
      return {
        ...result
      }
    })
  }
)

export const fetchGetCompanyRatioTrend = createAsyncThunk(
  '/theme-map/fetch-get-company-ratio-trend',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyRatioTrend(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetCompanyArticleList = createAsyncThunk(
  '/theme-map/fetch-get-company-article-list',
  async (params:any, thunkAPI) => {
    const { page } = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyArticleList(params);
      return {
        page,
        ...result
      }
    })
  }
)

export const fetchGetCompanyMentionTrend = createAsyncThunk(
  '/theme-map/fetch-get-company-mention-trend',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyMentionTrend(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetIndustryRatioTrend = createAsyncThunk(
  '/theme-map/fetch-get-industry-ratio-trend',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetIndustryRatioTrend(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetIndustryArticleList = createAsyncThunk(
  '/theme-map/fetch-get-industry-article-list',
  async (params:any, thunkAPI) => {
    const { page } = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetIndustryArticleList(params);
      return {
        page,
        ...result
      }
    })
  }
)

export const fetchGetIndustryMentionTrend = createAsyncThunk(
  '/theme-map/fetch-get-industry-mention-trend',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetIndustryMentionTrend(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetWordCloudByCompanyId = createAsyncThunk(
  '/theme-map/fetch-get-word-cloud-by-companyId',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetWordCloudByCompanyId(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetbusinessPerformance = createAsyncThunk(
  '/theme-map/fetch-get-business-performance',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetbusinessPerformance(params);
      return {
        ...result
      }
    })
  }
)
export const fetchGetBarIdByCompanyId = createAsyncThunk(
  '/theme-map/fetch-get-barId-by-company-id ',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetBarIdByCompanyId(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetCompanyVs = createAsyncThunk(
  '/theme-map/fetch-get-company-vs',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyVs(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetSwClassList = createAsyncThunk(
  '//theme-map/fetch-get-sw-class-list ',
  async ()=> {
    const result = await pandora.fetchGetSwClassList();
      return {
        ...result
      }
  }
)

export const fetchGetCompanyBySwId = createAsyncThunk(
  '/theme-map/fetch-get-company-by-swid',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyBySwId(params);
      return {
        ...result
      }
    })
  }
)

export const searchCompanyInfo = createAsyncThunk(
  '/theme-map/search-comapny-info',
  async (params:any, thunkAPI) => thunkAPI.dispatch(checkToken()).then(resp => pandora.searchCompanyInfo({...params, page: 0, size:10}))
)

export const fetchGetPolicyFilterType = createAsyncThunk(
  '/supply-chain/fetch-get-policy-filter-type',
  async ()=> {
    const result = await pandora.fetchGetPolicyFilterType();
      return {
        ...result
      }
  }
)

export const fetchGetEventFilterType = createAsyncThunk(
  '/supply-chain/fetch-get-event-filter-type',
  async ()=> {
    const result = await pandora.fetchGetEventFilterType();
      return {
        ...result
      }
  }
)

export const fetchGetTopicHot = createAsyncThunk(
  'policy-ceshi/fetch-get-topic-hot',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetTopicHot(params);
      return {
        ...result
      }
    })
  }
)
export const fetchTopCompanyEvent = createAsyncThunk(
  'company-porfle/fetch-top-company-event',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchTopCompanyEvent(params);
      return {
        ...result
      }
    })
  }
)
export const fetchCompanyPolicyImportantDetail = createAsyncThunk(
  'company-porfle/fetch-top-company-policy-important-detail',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {policyId} = params;
      const result = await pandora.fetchCompanyPolicyImportantDetail(params);
      return {
        policyId,
        ...result
      }
    })
  }
)
export const fetchCompanyPolicyProfile = createAsyncThunk(
  'company-porfle/fetch-company-policy-profile',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchCompanyPolicyProfile(params);
      return {
        ...result
      }
    })
  }
)
export const fetchCompanyPolicyImportant = createAsyncThunk(
  'company-porfle/fetch-company-policy-important',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchCompanyPolicyImportant(params);
      return {
        ...result
      }
    })
  }
)
export const fetchDistributingCompanyEventScore = createAsyncThunk(
  'company-porfle/fetch-distributing-company-event-score',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchDistributingCompanyEventScore(params);
      return {
        ...result
      }
    })
  }
)
export const fetchCountCompanyEvent = createAsyncThunk(
  'company-porfle/fetch-count-company-event',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchCountCompanyEvent(params);
      return {
        ...result
      }
    })
  }
)
export const fetchCompanyReportAnalysis = createAsyncThunk(
  'company-porfle/fetch-company-report-analysis',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const { companyId } = params;
      const result = await pandora.fetchCompanyReportAnalysis(params);
      return {
        companyId,
        ...result
      }
    })
  }
)
export const fetchCompanyPriceCause = createAsyncThunk(
  'company-porfle/fetch-company-price-cause',
  async (params: any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const { companyId } = params;
      const result = await pandora.fetchCompanyPriceCause(params);
      return {
        companyId,
        ...result
      }
    })
  }
)
export const fetchAliPayQr = createAsyncThunk(
  'company-porfle/fetch-ali-pay-qr',
  async (params: any, thunkAPI) => {
    const {payType} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchAliPayQr(params);
      return {
        payType,
        ...result
      }
    })
  }
)
export const fetchPaymentStatus = createAsyncThunk(
  'company-porfle/fetch-payment-status',
  async (params: any, thunkAPI) => {
    const {payType,uid} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchPaymentStatus(params);
      return {
        payType,
        uid,
        ...result,
      }
    })
  }
)


export const getCompanyModel = createAsyncThunk(
  'company-porfile/fetch-getCompanyModel',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.getCompanyModel(params))
  }
)

export const checkCoupon = createAsyncThunk(
  'company-porfile/check-coupon',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.checkCoupon(params))
  }
)
export const getStockAnnouncementList = createAsyncThunk(
  'company-porfile/fetch-getStockAnnouncementList',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.getStockAnnouncementList(params))
  }
)
export const getCompanyNewsList = createAsyncThunk(
  'company-porfile/fetch-getCompanyNewsList',
  async (params: any, thunkAPI) => {
      return thunkAPI.dispatch(checkToken()).then(resp => pandora.getCompanyNewsList(params))
  }
)
export const getNewsEvent = createAsyncThunk(
  'company-porfile/fetch-getNewsEvent',
  async (params: any, thunkAPI) => {
    const {id, type} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.getNewsEvent(params);
      return {
        ...result,
        id,
        type
      }
    })
  }
)

export const getCompanyNewsDetail = createAsyncThunk(
  'company-porfile/fetch-getCompanyNewsDetail',
  async (params: any, thunkAPI) => {
    const {id} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.getCompanyNewsDetail(params);
      return {
        ...result,
        id
      }
    })
  }
)
export const getStockAnnouncementDetail = createAsyncThunk(
  'company-porfile/fetch-getStockAnnouncementDetail',
  async (params: any, thunkAPI) => {
    const {id} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.getStockAnnouncementDetail(params);
      return {
        ...result,
        id
      }
    })
  }
)


export const themeMap = createSlice({
  name:'themeMap',
  initialState,
  reducers: {
    setCompanyId: (state, {payload}) => {
      state.newCompanyId = payload;
    },
    setSearching: (state, {payload}) => {
      state.isSearch = payload;
    },
    setSelectArr: (state, {payload}) => {
      if(payload.checked){
        var removedArr = state.selectArr
        removedArr.push(payload.name)
        state.selectArr=removedArr
      }else{
        let removedArr = state.selectArr.filter((x) => x !== payload.name);
        state.selectArr=removedArr
      }
    },
    setArticleOptions: (state , {payload}) => {
      state.articleListOptions= {
        articleList: [],
        total: 0,
        size: 10,
        hasMore: true,
        page: 0
      }
    },
    setSwCompany: (state , {payload}) => {
      state.swCompany = state.swCompany.concat(payload)
    },
    setFilterIdsValue: (state , {payload}) => {
      state.swFilterIdsValue = state.swFilterIdsValue.concat(payload)
    },
    setFilterIdsObjValue: (state , {payload}) => {
      state.swFilterIdsObjValue = state.swFilterIdsObjValue.concat(payload)
    },
    delFilterIdsValue: (state , {payload}) => {
      state.swFilterIdsValue = state.swFilterIdsValue.filter(((x:any) => !payload.some((y:any) => y === x)))
    },
    delFilterIdsObjValue: (state , {payload}) => {
      state.swFilterIdsObjValue = state.swFilterIdsObjValue.filter(((x:any) => !payload.some((y:any) => y.stockCode === x.stockCode)))
    },
    setFilterObj: (state , {payload}) => {
      state.filterObj[payload.name] = payload.value
    },
    setAllFilterObj: (state , {payload}) => {
      state.filterObj = {
        "beginMarketCap": null,
        "beginPEPercentile": null,
        "beginProfit": null,
        "beginRevenue": null,
        "endMarketCap": null,
        "endPEPercentile": null,
        "endProfit": null,
        "endRevenue": null,
        "profitGrowthRate": null,
        "revenueGrowthRate": null,
        "roe": null,
      }
    },
    setTimeLine: (state , {payload}) => {
      state.activetimeLine = payload
    },
    setOpportunities: (state , {payload}) => {
      state.opportunities = {}
    },
    setRestResearchList:(state , {payload}) => {
      state.researchList = []
    },
    setActiveValue : (state , {payload}) => {
      state.sliderInfo.activeValue = payload
    },
    refreshChat: (state , {payload}) => {
      const color = ['#FF3B30','#FF2D55','#FF9500','#FFCC00','#34C759','#5AC8FA','#007AFF','#5856D6','#AF52DE']

      const newDataArr: any = []
      const newSeriesDataArr: any = []
      const activeCompanyVs =state.companyVs[state.activetimeLine]
      const maxNum = Math.max.apply(null, activeCompanyVs[payload.dotMeansName])
      for(var i  in activeCompanyVs[payload.xAxisName]){
        newDataArr.push({stockCode:activeCompanyVs.stockCode[i], name:activeCompanyVs.companyNameZh[i],value:[activeCompanyVs[payload.xAxisName][i],activeCompanyVs[payload.yAxisName][i]],marketCap:maxNum?  Math.ceil(activeCompanyVs[payload.dotMeansName][i]/maxNum * 50)+10 : 10})
      }
      newDataArr.forEach((item:any,index:any) => {
        var activeColor = ''
        if(state.selectCompanyColor[item.stockCode]){
          activeColor =state.selectCompanyColor[item.stockCode]
        }else{
          activeColor = color[Math.floor(Math.random()*9)]
        }
        newSeriesDataArr.push({name:item.name,itemStyle:{ color:activeColor},symbolSize:item.marketCap,value:item.value})
      })
      state.companyCompetitorsData.scatterData = newSeriesDataArr
    },
    setArticleDetail : (state , {payload}) => {
      state.articleDetail = payload
    },
    setJumpToPage : (state , {payload}) => {
        console.log("setJumpToPage--",payload)
      state.jumpToPage = payload;
      console.log("state.jumpToPage--",state.jumpToPage)
    },
    setJumpActivePage: (state , {payload}) => {
        console.log("setJumpActivePage--",payload)
      state.jumpActivePage = payload;
      console.log("state.jumpActivePage--",state.jumpActivePage)
    },
    setReportTextDetail : (state , {payload}) => {
      // console.log("state.reportTextDetail",state.reportTextDetail);
      // console.log("payload",payload);

      state.reportTextDetail = state.reportTextDetail + payload
    },
    setCouponInfo : (state , {payload}) => {
      state.userCouponInfo = payload
    },
    setCouponStatus : (state , {payload}) => {
      state.userCouponStatus = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExchangeList.fulfilled, (state, {payload}) => {
      const {themeId, ...exchangeDataList} = payload;
      state.exchangeList[themeId] = exchangeDataList.dataList;
    }).addCase(fetchThemeMapData.pending, state => {
      state.loading.themeMapData = true;
    }).addCase(fetchThemeMapData.fulfilled, (state, {payload}) => {
      const {themeId, ...themeData} = payload;
      state.themeMapData[themeId] = {
        ...themeData.data,
      }
      state.loading.themeMapData = false;
    }).addCase(fetchCompanyProfile.pending, state => {
      state.loading.profile = true;
    }).addCase(fetchCompanyProfile.fulfilled, (state, {payload}) => {
      const {companyId, data} = payload;
      const refactorData = Object.entries(data).reduce((all, current) => {
        return {
          ...all,
          [current[0]]: current[1] !== null ? current[1] : ''
        };
      },{})
      state.companyProfiles[companyId] = refactorData
      state.loading.profile = false;
    }).addCase(fetchCompanyInformation.pending, state => {
      state.loading.information = true;
    }).addCase(fetchCompanyInformation.fulfilled, (state, {payload}) => {
      const {companyId, data} = payload;
      const refactorData = Object.entries(data).reduce((all, current) => {
        return {
          ...all,
          [current[0]]: current[1] !== null ? current[1] : ''
        };
      },{})
      state.companyInformations[companyId] = refactorData
      state.loading.information = false;
    }).addCase(fetchCompanyInsights.pending, state => {
      state.loading.insight = true;
    }).addCase(fetchCompanyInsights.fulfilled, (state,{payload}) => {
      state.loading.insight = false;
      const {companyId, insightType, data} = payload;
      state.companyInSights = {
        ...state.companyInSights,
        [companyId]: {
          ...state.companyInSights[companyId],
          [insightType]: data
        }
      }
    }).addCase(fetchIndustryDetails.pending, state => {
      state.loading.industryDetails = true;
    }).addCase(fetchIndustryDetails.fulfilled, (state,{payload}) => {
      state.loading.industryDetails = false;
      const {data, themeId} = payload;
      state.industryDetails[themeId] = data
    }).addCase(fetchNodeDetails.pending, state => {
      state.loading.nodeDetails = true;
    }).addCase(fetchNodeDetails.fulfilled, (state,{payload}) => {
      state.loading.nodeDetails = false;
      const {data, nodeId} = payload;
      state.nodeDetails[nodeId] = data
    }).addCase(fetchTopCompanyMarketCap.fulfilled, (state,{payload}) => {
      state.loading.industryDetails = false;
      const {data} = payload;
      state.industryDetails = {
        ...state.industryDetails,
        ...data
      }
    }).addCase(fetchIndustryIndex.fulfilled, (state,{payload}) => {
      state.indexData = payload.data;
    }).addCase(fetchNodeIndexCategory.fulfilled, (state, {payload}) => {
      state.nodeIndexDetails.typeList = payload.data;
    }).addCase(fetchNodeIndexRation.fulfilled, (state, {payload}) => {
      const {categoryId, data, nodeId} = payload;
      state.nodeIndexDetails.detailsList[`${nodeId}-${categoryId}`] = data;
    }).addCase(fetchGetEventDetail.pending, (state) => {
      state.loading.fetchEventDetailLoading = true
    }).addCase(fetchGetEventDetail.fulfilled, (state, {payload}) => {
      const {code, data, eventId} = payload;
      if(code==='0'){
        const {eventEntity, entityGraph,  ...restResult} = data;
        let entityGraphObj = {
          children:[] as any[],
          name:'',
          id:''
        }
        entityGraph.forEach((item:any) => {
          if(item.master){
            entityGraphObj.id =item.entityId
            entityGraphObj.name = item.entityValueZh
          }else{
            entityGraphObj.children.push({...item, name:item.entityValueZh,type:'child'})
          }
        });
        const lang  = localStorage.getItem('i18nextLng')
        const normlizedData = eventEntity.reduce((all: {[key: string]: any}, dataItem: any) => {
          const type = lang === "zh-CN" ? dataItem.typeZh : dataItem.type
          const {entityList:currentEntities} = dataItem;
          return {
                ...all,
                [type]: currentEntities.map((item:any) => ({...item, company_name_en: item.entityValueEn, company_name_zh: item.entityValueZh})),
              }
        }, {});
        state.eventDetails[eventId] = {
          ...restResult,
          graph:{
            data:entityGraphObj
          },
          entities:{
            data_list: normlizedData
          }
        };
      }
      state.loading.fetchEventDetailLoading = false
    }).addCase(fetchGetReportYears.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.reportYears = data
      }
    }).addCase(fetchGetReportUrl.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        if(data){
          state.reportUrl = data
        }else{
          state.reportUrl = ''
        }
      }else{
        state.reportUrl = '';
      }
    }).addCase(fetchGetCoreCompetenceAndFuture.pending, state => {
      state.loading.getCoreCompetenceAndFuture = true
    }).addCase(fetchGetCoreCompetenceAndFuture.fulfilled, (state, {payload})=>{
      const {code, type, data} = payload;
      if(code === '0'){
        if(type === 'Future'){
          state.reportFuture = data
        }else{
          state.reportCoreCompetence = data
        }
      }
      state.loading.getCoreCompetenceAndFuture = false
    }).addCase(fetchGetCompanyResearch.pending, state => {
      state.loading.fetchGetCompanyResearch = true
    }).addCase(fetchGetCompanyResearch.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.companyResearch={
          project:{},
          employees:{},
          spending:{},
        }
        data.forEach((item:any) => {
          if(item.type === 'Project'){
            state.companyResearch.project = item
          }
          if(item.type === 'Spending'){
            state.companyResearch.spending = item
          }
          if(item.type === 'Employees'){
            state.companyResearch.employees = item
          }
        });

      }
      state.loading.fetchGetCompanyResearch = false
    }).addCase(fetchGetCompanyProfile.pending, state => {
      state.newCompanyProfile = {}
      state.reportYears=[]
      state.reportUrl=''
      state.reportFuture=[]
      state.reportCoreCompetence=[]
      state.companyResearch={
        project:{},
        employees:{},
        spending:{},
      }
      state.researchList =[]
      state.reportInvestment=[]
      state.reportCAGR=[]
      state.reportCrnTab=[]
      state.reportCrn={}
      state.policyList={
        dataList:[],
        total:0,
      }
      state.policyInfo={}
      state.loading.fetchGetCompanyProfile = true
    }).addCase(fetchGetCompanyProfile.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.newCompanyProfile = data
        state.swId = data.swId
        state.loading.fetchGetCompanyProfile = false
        console.log("state.newCompanyProfile--",state.newCompanyProfile)
      }
    }).addCase(saveCompanySearch.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        console.log("saveCompanySearch--",data)
      }
    })
.addCase(fetchGetCompanyIdByStockCode.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.newCompanyId = data.companyId
      }
    }).addCase(fetchGetEventDataListByCompany.pending, (state) => {
      state.loading.fetchCompanyEventsLoading = true
    }).addCase(fetchGetEventDataListByCompany.fulfilled, (state, {payload}) => {
      const {code, data} = payload;
      if(code === '0'){
        state.opportunities = data;
        state.loading.fetchCompanyEventsLoading = false
      }
    }).addCase(getEventDataListByCompanyId.pending, (state) => {
      state.loading.fetchCompanyEventsLoading = true
    }).addCase(getEventDataListByCompanyId.fulfilled, (state, {payload}) => {
      const {code, data} = payload;
      if(code === '0'){
        state.opportunities = data;
        state.loading.fetchCompanyEventsLoading = false
      }
    }).addCase(fetchGetEventDataListByCompanyEventOne.pending, (state) => {
        state.loading.fetchCompanyEventsLoading = true
      }).addCase(fetchGetEventDataListByCompanyEventOne.fulfilled, (state, {payload}) => {
        const {code, data, params} = payload;
        if(code === '0'){
          state.opportunitiesTwo = data.eventTimeList;
          state.opportunitiesTotal = Math.ceil(data.total / 10);
          state.loading.fetchCompanyEventsLoading = false;
          console.log("fetchGetEventDataListByCompanyEventOne", params);
          if(data.eventTimeList){
            if(data.eventTimeList.length === 0){
              state.eventDetails = {}
            }
          }else {
            state.eventDetails = {}
          }
          
        }
      }).addCase(fetchGetBusiness.pending, (state) => {
      state.loading.fetchGetBusiness = true
    }).addCase(fetchGetBusiness.fulfilled, (state, {payload}) => {
      const {code, data} = payload;
      if(code === '0'){
        state.business={
          industry:[],
          product:[],
          region:[]
        }
        state.businessReportId = ''
        if(data.length){
          state.businessReportId = data[0].reportId
        }
        for(var i in data){
          if(data[i].type === '分行业'){
            const chartData = []
            for(const d in  data[i].dataList){
              chartData.push({value:data[i].dataList[d].turnoverPercent,name:data[i].dataList[d].nameZh,percent:data[i].dataList[d].turnoverPercent,page:data[i].dataList[d].pixel?.page || 0})
            }
            state.business.industry = chartData
          }
          if(data[i].type === '分产品'){
            const chartData = []
            for(const d in  data[i].dataList){
              chartData.push({value:data[i].dataList[d].turnoverPercent,name:data[i].dataList[d].nameZh,percent:data[i].dataList[d].turnoverPercent,page:data[i].dataList[d].pixel?.page || 0})
            }
            state.business.product = chartData
          }
          if(data[i].type === '分地区'){
            const chartData = []
            for(const d in  data[i].dataList){
              chartData.push({value:data[i].dataList[d].turnoverPercent,name:data[i].dataList[d].nameZh,percent:data[i].dataList[d].turnoverPercent,page:data[i].dataList[d].pixel?.page || 0})
            }
            state.business.region = chartData
          }
        }
        state.loading.fetchGetBusiness = false
      }
    }).addCase(fetchGetResearchList.pending, state => {
      state.loading.fetchGetResearchList = true
    }).addCase(fetchGetResearchList.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.researchList = data
      }
      state.loading.fetchGetResearchList = false
    }).addCase(fetchGetIndicatorList.pending, state => {
      state.loading.fetchGetResearchList = true
    }).addCase(fetchGetIndicatorList.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.researchList = data
      }
      state.loading.fetchGetResearchList = false
    })
    .addCase(fetchGetResearchUrl.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        if(data){
          state.researchUrl = data
        }else{
          state.researchUrl = ''
        }
      }
    }).addCase(fetchGetReportInvestment.pending, state => {
      state.loading.fetchGetReportInvestment = true
    }).addCase(fetchGetReportInvestment.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.reportInvestment = data
      }
      state.loading.fetchGetReportInvestment = false
    }).addCase(fetchGetReportCRn.pending, state => {
      state.loading.fetchGetReportCRn = true
    }).addCase(fetchGetReportCRn.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        const tabArr: any = []
        const newObj: any = {}
        data.forEach((item:any) => {
          tabArr.push(item.type)
          newObj[item.type] = item.data
        });
        state.reportCrnTab = tabArr
        state.reportCrn = newObj
      }
      state.loading.fetchGetReportCRn = false
    }).addCase(fetchGetReportCAGR.pending, state => {
      state.loading.fetchGetReportCAGR = true
    }).addCase(fetchGetReportCAGR.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.reportCAGR = data
      }
      state.loading.fetchGetReportCAGR = false
    }).addCase(fetchGetCompanyPolicyList.pending, state => {
      state.loading.fetchGetCompanyPolicyList = true
    }).addCase(fetchGetCompanyPolicyList.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.policyList.dataList = data.dataList ? data.dataList : [];
        state.policyList.total = data.total ? Math.ceil(data.total / 10) : 0;
        if(data.dataList){
          if(data.dataList.length === 0){
            state.policyInfo = {};
          }
        }else{
          state.policyInfo = {};
        }
        
      }
      state.loading.fetchGetCompanyPolicyList = false
    }).addCase(fetchGetPolicyInfo.pending, state => {
      state.loading.fetchGetPolicyInfo = true
    }).addCase(fetchGetPolicyInfo.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){ 
       state.policyInfo = data;
       state.loading.fetchGetPolicyInfo = false;
      }else{
        state.policyInfo = {};
        state.loading.fetchGetPolicyInfo = false;
      }
    }).addCase(fetchGetTopCompanyEvent.pending, state => {
      state.loading.fetchGetTopCompanyEvent = true
    }).addCase(fetchGetTopCompanyEvent.fulfilled, (state, {payload}) => {
      const {code, data} = payload;
      if(code === '0'){
        state.topCompanyEvent = data;
      }
      state.loading.fetchGetTopCompanyEvent = false
    }).addCase(fetchGetStockCompanyHeatGraphList.pending, state => {
      state.loading.treeMapData = true;
    }).addCase(fetchGetStockCompanyHeatGraphList.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        if(!data.length){
          state.treeData = []
          state.loading.treeMapData = false;
          return
        }
        const colorStr = (commentManyEmptyPercentage:number) => {
          if (commentManyEmptyPercentage > 0 && commentManyEmptyPercentage < 50) {
            return '#D54843'
          } else if (commentManyEmptyPercentage > 50 && commentManyEmptyPercentage < 70) {
            return '#C74945'
          } else if (commentManyEmptyPercentage > 70) {
            return '#8A494E'
          } else if (commentManyEmptyPercentage < 0 && commentManyEmptyPercentage > -50) {
            return '#62C165'
          } else if (commentManyEmptyPercentage < -50 && commentManyEmptyPercentage > -70) {
            return '#54A059'
          } else if (commentManyEmptyPercentage < -70 ) {
            return '#467451'
          } else {
            return '#141516'
          }
        }
        var newTreeData = [{
          "value": [
            1226629000,
          ],
          "name": "Company",
          "id": "company-1",
          "discretion": null,
          "children": [] as any
        }]
        data.forEach((item: any) => {
          if(item.companyId === state.newCompanyId){
            newTreeData[0].children.push(
              {
                "value": [2418639651328, item.commentManyEmptyPercentage],
                "name": item.securitiesAbbreviation,
                "id": "company-" + item.companyId + item.stockCode,
                "stockCode":item.stockCode,
                "currentMarket":(item.currentMarket / 100000000 ).toFixed(2),
                "turnoverRate":item.turnoverRate,
                "commentManyNum":item.commentManyNum,
                "commentEmptyNum":item.commentEmptyNum,
                "securitiesAbbreviation":item.securitiesAbbreviation,
                "fullNameOfCompany":item.fullNameOfCompany,
                "companyDescription":item.companyDescription,
                "exchangeEn":item.exchangeEn || '--',
                "website":item.website || '--',
                "discretion": "company",
                itemStyle: {
                  color: colorStr(item.commentManyEmptyPercentage),
                },
              }
            )
          }else{
            newTreeData[0].children.push(
              {
                "value": [item.currentMarket, item.commentManyEmptyPercentage],
                "name": item.securitiesAbbreviation,
                "id": "company-" + item.companyId + item.stockCode,
                "stockCode":item.stockCode,
                "currentMarket":(item.currentMarket / 100000000 ).toFixed(2),
                "turnoverRate":item.turnoverRate,
                "commentManyNum":item.commentManyNum,
                "commentEmptyNum":item.commentEmptyNum,
                "securitiesAbbreviation":item.securitiesAbbreviation,
                "fullNameOfCompany":item.fullNameOfCompany,
                "companyDescription":item.companyDescription,
                "exchangeEn":item.exchangeEn || '--',
                "website":item.website || '--',
                "discretion": "company",
                itemStyle: {
                  color: colorStr(item.commentManyEmptyPercentage),
                },
              }
            )
          }
          
        });
        state.treeData = newTreeData
        state.loading.treeMapData = false;
      }
    }).addCase(fetchGetCompanyMentionTrend.fulfilled, (state, {payload}) => {
      const {code, data} = payload;
      if(code === '0'){
        const xAxis: any = [];
        const trend: any = []
        data.forEach((item: any) => {
          xAxis.push(`${moment(new Date(item.date).getTime()).format('DD')}\n${moment(new Date(item.date).getTime()).format('MMMM')}`)
          trend.push(item.num)
        });
        var maxNum =Math.ceil(Math.max.apply(null, trend) / 10) * 10
        state.mentionTrends = {xAxis:xAxis,trend:trend,maxNum:maxNum}
      }
      
      state.loading.fetchGetCompanyMentionTrend = false
    }).addCase(fetchGetCompanyRatioTrend.fulfilled, (state, {payload}) => {
      const {code, data} = payload;
      if(code === '0'){
        const xAxis: any = [];
        const many: any = [];
        const empty: any = [];
        const irrelevanceNum: any = [];
        
        data.forEach((item: any) => {
          xAxis.push(`${moment(new Date(item.date).getTime()).format('DD')}\n${moment(new Date(item.date).getTime()).format('MMMM')}`)
          empty.push(item.emptyNum)
          irrelevanceNum.push(item.irrelevanceNum)
          many.push(item.manyNum)

        });
        state.manyEmpty = {xAxis:xAxis,trend:[many,irrelevanceNum,empty]}
      }
      
      state.loading.fetchGetCompanyRatioTrend = false
    }).addCase(fetchGetIndustryMentionTrend.fulfilled, (state, {payload}) => {
      const {code, data} = payload;
      if(code === '0'){
        const xAxis: any = [];
        const trend: any = []
        data.forEach((item: any) => {
          xAxis.push(`${moment(new Date(item.date).getTime()).format('DD')}\n${moment(new Date(item.date).getTime()).format('MMMM')}`)
          trend.push(item.num)
        });
        var maxNum =Math.ceil(Math.max.apply(null, trend) / 10) * 10
        state.mentionTrends = {xAxis:xAxis,trend:trend,maxNum:maxNum}
      }
      
      state.loading.fetchGetIndustryMentionTrend = false
    }).addCase(fetchGetIndustryRatioTrend.fulfilled, (state, {payload}) => {
      const {code, data} = payload;
      if(code === '0'){
        const xAxis: any = [];
        const many: any = [];
        const empty: any = [];
        const irrelevanceNum: any = [];
        
        data.forEach((item: any) => {
          xAxis.push(`${moment(new Date(item.date).getTime()).format('DD')}\n${moment(new Date(item.date).getTime()).format('MMMM')}`)
          empty.push(item.emptyNum)
          irrelevanceNum.push(item.irrelevanceNum)
          many.push(item.manyNum)
        });
        state.manyEmpty = {xAxis:xAxis,trend:[many,irrelevanceNum,empty]}
      }
      
      state.loading.fetchGetIndustryRatioTrend = false
    }).addCase(fetchGetIndustryArticleList.fulfilled, (state, {payload}) => {
      const {code, data, page} = payload;
      if(code === '0'){
        for (var i in data.dataList) {
          data.dataList[i].NO = (Number(i) + 1) + Number(page) * 10
          data.dataList[i].manyPercent = data.dataList[i].many 
          data.dataList[i].emptyPercent = data.dataList[i].empty 
          data.dataList[i].irrelevantPercent = data.dataList[i].irrelevant
        }
        state.articleListOptions = {
          articleList: data.dataList,
          total: data.total,
          size: 10,
          hasMore: !Boolean(data.dataList.length < 10),
          page: page
        }
      }
      state.loading.fetchGetCompanyArticleList = false
    }).addCase(fetchGetCompanyArticleList.fulfilled, (state, {payload}) => {
      const {code, data, page} = payload;
      if(code === '0'){
        for (var i in data.dataList) {
          data.dataList[i].NO = (Number(i) + 1) + Number(page) * 10
          data.dataList[i].manyPercent = data.dataList[i].many 
          data.dataList[i].emptyPercent = data.dataList[i].empty 
          data.dataList[i].irrelevantPercent = data.dataList[i].irrelevant
        }
        state.articleListOptions = {
          articleList: data.dataList,
          total: data.total,
          size: 10,
          hasMore: !Boolean(data.dataList.length < 10),
          page: page
        }
      }
      state.loading.fetchGetCompanyArticleList = false
    }).addCase(fetchGetWordCloudByCompanyId.pending, state => {
      state.loading.wordCloud = true;
    }).addCase(fetchGetWordCloudByCompanyId.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        const colorArr = ['#C0E0A7', '#D8E0A7', '#E0CDA7', '#E0BCA7', '#E0A7A7', '#A7E0D3', '#A7ADE0', '#69E192', '#C269E1', '#E19469',]
      
        data.forEach((item: any) => {
          item.color = colorArr[Math.floor(Math.random() * 10)]
          item.num = item.num  * 10000000 + (Math.random() * 1000)
        });
        state.wordCloud = data
        state.loading.wordCloud = false;
      }
    }).addCase(fetchGetbusinessPerformance.pending, state => {
      state.loading.fetchGetbusinessPerformance = true;
    }).addCase(fetchGetbusinessPerformance.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        const xAxis: any = [];
        const revenue: any = [];
        const operatingIncome: any = [];
        const netIncomeBeforeTax: any = [];
        const netIncomeAfterTax: any = [];
        data.forEach((item: any) => {
          xAxis.push( item.quarter === 'Q1' ?  item.year.toString() + ' '+ item.quarter : item.quarter)
          revenue.push(item.revenue || 0)
          operatingIncome.push(item.operatingIncome || 0)
          netIncomeBeforeTax.push(item.netIncomeBeforeTax || 0)
          netIncomeAfterTax.push(item.netIncomeAfterTax || 0)
        })
        state.businessPerformance = {
          xAxis:xAxis,
          revenue:revenue,
          operatingIncome:operatingIncome,
          netIncomeBeforeTax:netIncomeBeforeTax,
          netIncomeAfterTax:netIncomeAfterTax
        }
        state.loading.fetchGetbusinessPerformance = false;
      }
      
    }).addCase(fetchGetBarIdByCompanyId.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        state.barInfo =  data
      }
    }).addCase(fetchGetCompanyVs.pending, state => {
      state.loading.fetchGetCompanyVs = true;
    }).addCase(fetchGetCompanyVs.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      const color = ['#FF3B30','#FF2D55','#FF9500','#FFCC00','#34C759','#5AC8FA','#007AFF','#5856D6','#AF52DE']
      if (code === '0') {
        if(!data.length){
          state.companyVs={}
          state.companyCompetitorsData.scatterData=[]
          state.loading.fetchGetCompanyVs = false;
        }
        var marks: any = [];
        state.sliderInfo.max= data.length-1;
        state.sliderInfo.activeValue = data.length-1;
        data.forEach((item: any,index:any) => {
          marks.push({
            value: index,
            label: item.quarter === 'Q1' ? item.year +'Q1' :item.quarter,
            comapanyVsTime:[item.year + item.quarter]
          })
          const equity: any = [];
          const grossProfitMargin: any = [];
          const incomeTax: any = [];
          const interestExpense: any = [];
          const interestIncome: any = [];
          const investmentIncome: any = [];
          const marketCap: any = [];
          const netIncomeAfterTax: any = [];
          const netProfitMargin: any = [];
          const nopat: any = [];
          const operatingProfit: any = [];
          const peTtm: any = [];
          const peg: any = [];
          const revenue: any = [];
          const revenueGrowthRate: any = [];
          const roe: any = [];
          const totalAssets: any = [];
          const totalProfit: any = [];
          const companyNameZh: any = [];
          const companyAbbreviationZh: any = [];
          const stockCode: any = [];
          item.companyVsResponseList.forEach((listItem: any) => {
            equity.push(Math.ceil(listItem.equity))
            grossProfitMargin.push(listItem.grossProfitMargin)
            incomeTax.push(listItem.incomeTax)
            interestExpense.push(listItem.interestExpense)
            interestIncome.push(listItem.interestIncome)
            investmentIncome.push(listItem.investmentIncome)
            marketCap.push(Math.ceil(listItem.marketCap))
            netIncomeAfterTax.push(listItem.netIncomeAfterTax)
            netProfitMargin.push(listItem.netProfitMargin)
            nopat.push(listItem.nopat)
            operatingProfit.push(listItem.operatingProfit)
            peTtm.push(listItem.peTtm)
            peg.push(listItem.peg)
            revenue.push(Math.ceil(listItem.revenue))
            revenueGrowthRate.push(listItem.revenueGrowthRate)
            roe.push(listItem.roe)
            totalAssets.push(Math.ceil(listItem.totalAssets))
            totalProfit.push(listItem.totalProfit)
            stockCode.push(listItem.stockCode)
            companyNameZh.push(listItem.companyNameZh?listItem.companyNameZh:listItem.companyNameEn)
            companyAbbreviationZh.push(listItem.companyAbbreviationZh?listItem.companyAbbreviationZh:listItem.companyNameZh?listItem.companyNameZh :listItem.companyNameEn)
            
          })
          state.companyVs[item.year + item.quarter] = {
            equity:equity,
            grossProfitMargin:grossProfitMargin,
            incomeTax:incomeTax,
            interestExpense:interestExpense,
            interestIncome:interestIncome,
            investmentIncome:investmentIncome,
            marketCap:marketCap,
            netIncomeAfterTax:netIncomeAfterTax,
            netProfitMargin:netProfitMargin,
            nopat:nopat,
            operatingProfit:operatingProfit,
            peTtm:peTtm,
            peg:peg,
            revenue:revenue,
            revenueGrowthRate:revenueGrowthRate,
            roe:roe,
            totalAssets:totalAssets,
            totalProfit:totalProfit,
            companyNameZh:companyNameZh,
            companyAbbreviationZh:companyAbbreviationZh,
            stockCode:stockCode
          }     
          if(index === data.length - 1){
            const newDataArr: any = []
            const newSeriesDataArr: any = []
            const maxNum = Math.max.apply(null, marketCap)
            state.activetimeLine = item.year + item.quarter
            for(var i  in grossProfitMargin){
              newDataArr.push({stockCode:stockCode[i],name:companyAbbreviationZh[i],value:[grossProfitMargin[i],netProfitMargin[i]],marketCap:maxNum ? Math.ceil(marketCap[i]/ maxNum * 50)+10 :10})
            }

            newDataArr.forEach((item:any,index:any) => {
              var activeColor = ''
              if(state.selectCompanyColor[item.stockCode]){
                activeColor =state.selectCompanyColor[item.stockCode]
              }else{
                activeColor = color[Math.floor(Math.random()*9)]
              }
              state.selectCompanyColor[item.stockCode] = activeColor
              newSeriesDataArr.push({stockCode:item.stockCode,name:item.name,itemStyle:{ color:activeColor},symbolSize:item.marketCap,value:item.value})
            })
            
            state.companyCompetitorsData.scatterData = newSeriesDataArr
            state.loading.fetchGetCompanyVs = false;
          }   
        })
        state.sliderInfo.marks = marks
      }
    }).addCase(fetchGetSwClassList.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        console.log(data)
      }
    }).addCase(fetchGetCompanyBySwId.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        state.swCompany = data.dataList;
        const idsArr: any = [];
        const objsArr: any = [];
        data.dataList.forEach((item: any) => {
          idsArr.push(item.stockCode)
          objsArr.push({stockCode:item.stockCode,name:item.companyNameZh?item.companyNameZh:item.companyNameEn})
        })
        state.swFilterIdsValue = idsArr;
        state.swFilterIdsObjValue=objsArr;
      }
    }).addCase(searchCompanyInfo.fulfilled, (state, {payload}) => {
      const {data, code} = payload;
      if(code === '0'){
        // state.searchCompanyList = Object.entries(data).map((recommend: [string, any]) => (recommend[1] as Array<any>).map(item => ({
        //   ...item, 
        //   type: recommend[0] ==='searchList' ? ''  : '常搜'
        // }))).flat();
        state.searchCompanyList =  data.searchList
      }
      state.loading.searchLoading = false;
    }).addCase(fetchGetPolicyFilterType.fulfilled, (state, {payload}) => {
      const {data, code} = payload;
      if(code === '0'){
        state.allAgencyName = data.names;
        state.allAgencyType = data.reportTypes;
      }
      state.loading.searchLoading = false;
    }).addCase(fetchGetEventFilterType.fulfilled, (state, {payload}) => {
      const {data, code} = payload;
      if(code === '0'){
        console.log("fetchGetEventFilterType", data);
        state.allEventType = data;
      }
      state.loading.searchLoading = false;
    }).addCase(fetchGetTopicHot.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
        if (code === '0') {
          const lineData: any = [];
          data.forEach((item: any) => {
            lineData.push(item.sum)
          });
          var maxNum = Math.max.apply(null, lineData)
          data.forEach((item: any) => {
            if(maxNum === 0){
              item.percentage =  '0%'
            }else{
              let val = (Number(item.sum) / maxNum ) * 100
              item.percentage = val + '%'
            }
            item.barTypeNumList = JSON.parse(JSON.stringify(item.typeNumList)).reverse()
            lineData.push(item.sum)
          });
         
          state.policyStatistics = data
          
        }
    }).addCase(fetchTopCompanyEvent.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        state.newTopCompanyEvent = data.eventsTop
      }
    }).addCase(fetchDistributingCompanyEventScore.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        if(data.eventScore){
          const scoreArr: any = [];
          data.eventScore.forEach((item:any,index:number) => {
            if(index<10){
              scoreArr.push({value: Number(item.count),itemStyle: {color: '#D03C38'}})
            }else if(index>10){
              scoreArr.push({value: Number(item.count),itemStyle: {color: '#048739'}})
            }else{
              scoreArr.push({value: Number(item.count),itemStyle: {color: '#fff'}})
            }
          });
          state.distributingCompanyEventScore = scoreArr
        }
      }
    }).addCase(fetchCountCompanyEvent.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        state.countCompanyEvent = data
       
      }
    }).addCase(fetchCompanyPolicyImportantDetail.pending, state => {
      state.newTopCompanyPolicyImportantDetail = {};
    }).addCase(fetchCompanyPolicyImportantDetail.fulfilled, (state, { payload }) => {
      const { policyId, code, data } = payload;
      if (code === '0') {
        state.newTopCompanyPolicyImportantDetail[policyId] = data;
      }
    }).addCase(fetchCompanyPolicyProfile.pending, state => {
      state.companyPolicyProfile = [];
    }).addCase(fetchCompanyPolicyProfile.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
                  const lineData: any = [];
          data.forEach((item: any) => {
            lineData.push(item.sum)
          });
          var maxNum = Math.max.apply(null, lineData)
          data.forEach((item: any) => {
            if(maxNum === 0){
              item.percentage =  '0%'
            }else{
              let val = (Number(item.sum) / maxNum ) * 100
              item.percentage = val + '%'
            }
            item.barTypeNumList = JSON.parse(JSON.stringify(item.typeNumList)).reverse()
            lineData.push(item.sum)
          });

        state.companyPolicyProfile = data;
      }
    }).addCase(fetchCompanyPolicyImportant.pending, state => {
      state.companyPolicyImportant = [];
    }).addCase(fetchCompanyPolicyImportant.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
          const lineData: any = [];
          data.forEach((item: any) => {
            lineData.push(item.sum)
          });
          var maxNum = Math.max.apply(null, lineData)
          data.forEach((item: any) => {
            if(maxNum === 0){
              item.percentage =  '0%'
            }else{
              let val = (Number(item.sum) / maxNum ) * 100
              item.percentage = val + '%'
            }
            item.barTypeNumList = JSON.parse(JSON.stringify(item.typeNumList)).reverse()
            lineData.push(item.sum)
          });

        state.companyPolicyImportant = data;
      }
    }).addCase(fetchCompanyPriceCause.pending, state => {
        state.companyPriceCauseDetail = {};
        state.companyPriceCauseOvertime = false;
        state.loading.fetchCompanyPriceCause = true;
    }).addCase(fetchCompanyPriceCause.fulfilled, (state, { payload }) => {
        const { companyId,code, data } = payload;
        if (code === 0) {
            state.companyPriceCauseDetail[companyId] = data;
            state.companyPriceCauseOvertime = false;
        }else {
            state.companyPriceCauseOvertime = true;
        }
        state.loading.fetchCompanyPriceCause = false;
    }).addCase(fetchCompanyPriceCause.rejected, (state, { payload }) => {//处理接口报错的情况
        console.log("companyPriceCauseOvertime3--",payload)
        state.companyPriceCauseDetail = {};
        state.companyPriceCauseOvertime = true;
        state.loading.fetchCompanyPriceCause = false;
    }).addCase(fetchCompanyReportAnalysis.pending, state => {
        state.companyReportAnalysisDetail = {};
        state.loading.fetchCompanyReportAnalysis = true;
        state.companyReportAnalysisOvertime = false;
    }).addCase(fetchCompanyReportAnalysis.fulfilled, (state, { payload }) => {
        const { companyId,code, data } = payload;
        if (code === 0) {
            if(data){
                var textContent = '';
                if(data.content){
                    var copyData = data.content;
                    textContent = copyData.replaceAll('\\n', '<br />');
                    data.textContent = textContent;
                }
            }
            state.companyReportAnalysisDetail[companyId] = data;
            state.companyReportAnalysisOvertime = false;
        }else {
          state.companyReportAnalysisOvertime = true;
        }
        state.loading.fetchCompanyReportAnalysis = false;
    }).addCase(fetchCompanyReportAnalysis.rejected, (state, { payload }) => {//处理接口报错的情况
      console.log("companyReportAnalysisOvertime--",payload)
      state.companyReportAnalysisDetail = {};
      state.companyReportAnalysisOvertime = true;
      state.loading.fetchCompanyReportAnalysis = false;
    }).addCase(fetchAliPayQr.pending, state => {
      state.loading.fetchAliAndWechatPayQr = true;
  }).addCase(fetchAliPayQr.fulfilled, (state, { payload }) => {
      const { payType,code, data } = payload;
      if (code === '0') {
        if(payType === '微信'){
          state.wechatPayQrInfo = data;
        }else{
          state.aliPayQrInfo = data;
        }
        state.loading.fetchAliAndWechatPayQr = false;
      }
    }).addCase(fetchPaymentStatus.fulfilled, (state, { payload }) => {
      const { payType,uid,code, data } = payload;
      if (code === '0') {
        if(payType === '微信'){
          let dataWe = {
            wechatExpired: '',
            wechatOrderStatus: '',
          };
          dataWe.wechatExpired = data.expired;
          dataWe.wechatOrderStatus = data.orderStatus;

          state.paymentStatusObj[uid] = dataWe;
        }else {
          let dataAli = {
            aliExpired: '',
            aliOrderStatus: ''
          }
          dataAli.aliExpired = data.expired;
          dataAli.aliOrderStatus = data.orderStatus;

          state.paymentStatusObj[uid] = dataAli;
        }
      }
    }).addCase(getCompanyModel.pending, state => {
      state.loading.getCompanyModel = true;
      state.companyModel = {};
    }).addCase(getCompanyModel.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if (code === '0') {
        if(data){
          state.companyModel = data;
        }else{
          state.companyModel = {};
        }
        state.loading.getCompanyModel = false;
      }
    }).addCase(getCompanyModel.rejected, (state) => {
      state.loading.getCompanyModel = false;
      state.companyModel = {};
    }).addCase(checkCoupon.pending, state => {
      state.loading.checkCoupon = true;
    }).addCase(checkCoupon.fulfilled, (state, { payload }) => {
      state.loading.checkCoupon = false;
    }).addCase(getStockAnnouncementList.pending, state => {
      state.loading.getStockAnnouncementList = true;
    }).addCase(getStockAnnouncementList.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if(code === '0'){
        let newArr = [] as any
        let timeArr = [] as any
        data.dataList.forEach((item:any) => {
          const md =  moment(Number(item.publishTime)).format('MM月DD日')
          if(timeArr.indexOf(md) > -1){
            newArr.forEach((c:any,index:any) => {
              if(c.time === md ){
                c.list.push(item)
              }
            });
          }else{
            timeArr.push(md)
            newArr.push({time:md,list:[item]})
          }
        });
        state.announcementList.dataList = newArr
        state.announcementList.total =  Math.ceil(Number(data.total) /10)
      }
      state.loading.getStockAnnouncementList = false;
    }).addCase(getCompanyNewsList.pending, state => {
      state.loading.getCompanyNewsList = true;
    }).addCase(getCompanyNewsList.fulfilled, (state, { payload }) => {
      const { code, data } = payload;
      if(code === '0'){
        let newArr = [] as any
        let timeArr = [] as any
        data.dataList.forEach((item:any) => {
          const md =  moment(Number(item.newsDate)).format('MM月DD日')
          if(timeArr.indexOf(md) > -1){
            newArr.forEach((c:any,index:any) => {
              if(c.time === md ){
                c.list.push(item)
              }
            });
          }else{
            timeArr.push(md)
            newArr.push({time:md,list:[item]})
          }
        });
        state.companyNewsList.dataList = newArr
        state.companyNewsList.total = Math.ceil(Number(data.total) /10)
      }
      state.loading.getCompanyNewsList = false;
    }).addCase(getNewsEvent.pending, state => {
      state.loading.getNewsEvent = true;
    }).addCase(getNewsEvent.fulfilled, (state, { payload }) => {
      const { code,type, data,id } = payload;
      if(code === '0'){
        if(type === 'NEWS'){
          state.companyNewsEvent[id] = data
        }else{
          state.announcementEvent[id] = data
        }
      }
      state.loading.getNewsEvent = false;
    }).addCase(getStockAnnouncementDetail.pending, state => {
      state.loading.getStockAnnouncementDetail = true;
    }).addCase(getStockAnnouncementDetail.fulfilled, (state, { payload }) => {
      const { code, data,id } = payload;
      if(code === '0'){
        state.announcementDetail[id] = data
      }
      state.loading.getStockAnnouncementDetail = false;
    }).addCase(getCompanyNewsDetail.pending, state => {
      state.loading.getNewsEvent = true;
    }).addCase(getCompanyNewsDetail.fulfilled, (state, { payload }) => {
      const { code,data,id } = payload;
      if(code === '0'){
        state.companyNewsDetail[id] = data
      }
      state.loading.getCompanyNewsDetail = false;
    })
  },
})
export const {setCouponStatus, setCouponInfo,setJumpToPage,setJumpActivePage,setReportTextDetail,setArticleDetail,setRestResearchList,setOpportunities,setSwCompany, setSearching, setAllFilterObj, setActiveValue, setTimeLine, setFilterObj, refreshChat, delFilterIdsValue,delFilterIdsObjValue,setFilterIdsValue,setFilterIdsObjValue,setSelectArr, setArticleOptions, setCompanyId} = themeMap.actions;

const selectBaseState = (state: RootState) => state.themeMap;
export const selectLoadingByType = (type:string) =>  createSelector(selectBaseState, state => state.loading[type] || false);
export const selectDataByThemeId = (themeId: number) => createSelector(selectBaseState, state => state.themeMapData[themeId]?.structure_info || {})
export const selectIndexData = createSelector(selectBaseState, state => state.indexData || [])
export const selectExchangeList = (themeId: number) => createSelector(selectBaseState, state => state.exchangeList[themeId] || [
  {"id":24,"exchangeZh":"深圳证券交易所","exchangeNeo4jId":1464,"exchangeLocation":null,"exchangeEn":"SHZ"},
  {"id":14,"exchangeZh":"上海证券交易所","exchangeNeo4jId":1151,"exchangeLocation":null,"exchangeEn":"SSE"}
])
export const selectCompanyProfile = (companyId: number) => createSelector(selectBaseState, state => {
  if(!companyId){
    return {};
  }
  return state.companyProfiles[companyId];
})
export const selectCompanyInformation = (companyId: number) => createSelector(selectBaseState, state => {
  if(!companyId){
    return {};
  }
  return state.companyInformations[companyId];
})
export const selectInsightList = (companyId:number, insightType: string) => createSelector(selectBaseState, state => {
  if(!companyId || !insightType){
    return []
  }
  return Map(state.companyInSights).getIn([companyId.toString(), insightType, 'companyList'], [])
})
export const selectIndustryDetail = (themeId: number) => createSelector(selectBaseState, state => {
  if(!themeId){
    return {};
  }
  return state.industryDetails[themeId];
})
export const selectNodeDetail = (nodeId: number) => createSelector(selectBaseState, state => {
  if(!nodeId){
    return {};
  }
  return state.nodeDetails[nodeId];
})

export const selectNodeIndexType = createSelector(selectBaseState, state => state.nodeIndexDetails.typeList || [])
export const selectNodeIndexList = (categoryId: number, nodeId: number) => createSelector(selectBaseState, state => {
  if(!categoryId || !nodeId){
    return [];
  }
  return state.nodeIndexDetails.detailsList[`${nodeId}-${categoryId}`] || []
})

export const selectOpportunitiesDetail = createSelector(selectBaseState, state => state.opportunities )
export const selectOpportunitiesTwoDetail = createSelector(selectBaseState, state => state.opportunitiesTwo )
export const selectOpportunitiesTwoTotal = createSelector(selectBaseState, state => state.opportunitiesTotal )


export const selectEventDetailById = (id:any) => createSelector(
  selectBaseState, state => state.eventDetails[id] || {}
)

export const selectReportYears= createSelector(selectBaseState, state => state.reportYears || [] )
export const selectReportUrl= createSelector(selectBaseState, state => state.reportUrl || '' )
export const selectReportCoreCompetence = createSelector(selectBaseState, state => state.reportCoreCompetence || [] )
export const selectReportFuture= createSelector(selectBaseState, state => state.reportFuture || [] )
export const selectReportCompanyResearch = createSelector(selectBaseState, state => state.companyResearch || {project:{},employees:{},spending:{}} )
export const selectNewCompanyProfile = createSelector(selectBaseState, state => state.newCompanyProfile || {} )
export const selectNewCompanyId = createSelector(selectBaseState, state => state.newCompanyId || '' )
export const selectCompanyBusiness = createSelector(selectBaseState, state => state.business )
export const selectResearchList = createSelector(selectBaseState, state => state.researchList || [] )
export const selectResearchUrl = createSelector(selectBaseState, state => state.researchUrl || '' )
export const selectReportInvestment = createSelector(selectBaseState, state => state.reportInvestment || [] )
export const selectReportCAGR = createSelector(selectBaseState, state => state.reportCAGR || [] )
export const selectReportCrnTab= createSelector(selectBaseState, state => state.reportCrnTab || [] )
export const selectReportCrn = (type:string) => createSelector(selectBaseState, state => state.reportCrn[type] || {});
export const selectPolicyList= createSelector(selectBaseState, state => state.policyList || {dataList:[],total:0} )
export const selectPolicyInfo= createSelector(selectBaseState, state => state.policyInfo || {} )
export const selectTreeData = createSelector(selectBaseState, state => state.treeData || []);
export const selectManyEmpty = createSelector(selectBaseState, state => state.manyEmpty);
export const selectMentionTrends = createSelector(selectBaseState, state => state.mentionTrends);
export const selectFetchOptions = createSelector(selectBaseState, state => state.articleListOptions)
export const selectArticleList = createSelector(selectBaseState, state => state.articleListOptions.articleList || [])
export const selectWordCloud = createSelector(selectBaseState, state => state.wordCloud || [])
export const selectBusinessPerformance = createSelector(selectBaseState, state => state.businessPerformance  || {})
export const selectCheckedArr = createSelector(selectBaseState, state => state.selectArr  || {})
export const selectBarInfo = createSelector(selectBaseState, state => state.barInfo)
export const selectSwId = createSelector(selectBaseState, state => state.swId)
export const selectSwCompany = createSelector(selectBaseState, state => state.swCompany)
export const selectSwFilterIdsValue = createSelector(selectBaseState, state => state.swFilterIdsValue)
export const selectSwFilterIdsObjValue = createSelector(selectBaseState, state => state.swFilterIdsObjValue)
export const selectFilter = createSelector(selectBaseState, state => state.filterObj)
export const selectCompanyCompetitorsData = createSelector(selectBaseState, state => state.companyCompetitorsData)
export const selectBusinessReportId = createSelector(selectBaseState, state => state.businessReportId)
export const selectSliderInfo = createSelector(selectBaseState, state => state.sliderInfo)
export const selectIsSearch = createSelector(selectBaseState, state => state.isSearch)
export const selectSearchCompanyList = createSelector(selectBaseState, state => state.searchCompanyList || [])
export const selectAllAgencyName = createSelector(selectBaseState, state => state.allAgencyName || []);
export const selectAllAgencyType = createSelector(selectBaseState, state => state.allAgencyType || []);
export const selectAllEventType = createSelector(selectBaseState, state => state.allEventType || []);
export const selectPolicyStatistics = createSelector(selectBaseState, state => state.policyStatistics || []);
export const selectArticleDetail = createSelector(selectBaseState, state => state.articleDetail || {});
export const selectReportTextDetail = createSelector(selectBaseState, state => state.reportTextDetail);
export const selectDistributingCompanyEventScore = createSelector(selectBaseState, state => state.distributingCompanyEventScore || []);
export const selectCountCompanyEvent= createSelector(selectBaseState, state => state.countCompanyEvent || {});
export const selectNewTopCompanyEvent= createSelector(selectBaseState, state => state.newTopCompanyEvent || []);
export const selectNewTopCompanyPolicyImportantDetail = createSelector(selectBaseState, state => state.newTopCompanyPolicyImportantDetail || {});
export const selectCompanyPolicyProfile = createSelector(selectBaseState, state => state.companyPolicyProfile || []);
export const selectCompanyPolicyImportant = createSelector(selectBaseState, state => state.companyPolicyImportant || []);
export const selectJumpToPage = createSelector(selectBaseState, state => state.jumpToPage || {});
export const selectJumpActivePage = createSelector(selectBaseState, state => state.jumpActivePage || {});
export const selectCompanyReportAnalysisDetail = createSelector(selectBaseState, state => state.companyReportAnalysisDetail || {});
export const selectCompanyPriceCauseDetail = createSelector(selectBaseState, state => state.companyPriceCauseDetail || {});
export const selectCompanyPriceCauseOvertime = createSelector(selectBaseState, state => state.companyPriceCauseOvertime);
export const selectCompanyReportAnalysisOvertime = createSelector(selectBaseState, state => state.companyReportAnalysisOvertime);
export const selectAliPayQr = createSelector(selectBaseState, state => state.aliPayQrInfo);
export const selectWechatPayQr = createSelector(selectBaseState, state => state.wechatPayQrInfo);
export const selectPaymentStatus = createSelector(selectBaseState, state => state.paymentStatusObj || {});
export const selectCompanyModel= createSelector(selectBaseState, state => state.companyModel);
export const selectUserCouponInfo= createSelector(selectBaseState, state => state.userCouponInfo || {});
export const selectUserCouponStatus= createSelector(selectBaseState, state => state.userCouponStatus);
export const selectAnnouncementList= createSelector(selectBaseState, state => state.announcementList || {dataList:[],total:0});
export const selectAnnouncementDetail = (id:any) => createSelector(
  selectBaseState, state => state.announcementDetail[id] || {}
)
export const selectCompanyNewsList = createSelector(selectBaseState, state => state.companyNewsList || {dataList:[],total:0});
export const selectCompanyNewsDetail = (id:any) => createSelector(
  selectBaseState, state => state.companyNewsDetail[id] || {}
)
export const selectCompanyNewsEvent = (id:any) => createSelector(
  selectBaseState, state => state.companyNewsEvent[id] || []
)
export const selectAnnouncementEvent = (id:any) => createSelector(
  selectBaseState, state => state.announcementEvent[id] || []
)

export default themeMap.reducer as Reducer<typeof initialState>;
