import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import pandora from '../../../api';
import {checkToken} from '../../user/reducer';
const {Map} = require('immutable');


interface StateProps {
  loading: {
    [key: string]: boolean;
  },
  activeSupplyChainId:string,
  supplyChainDetail:{
    [key:string]: any;
  },
  supplyChainGraph:{
    [key:string]: any;
  },
  supplyChainList:Array<any>,
  supplyChainSearch:Array<any>,
  supplyChainQccSearch:Array<any>,
  selectNode:any,
  activeThemeId:number,
  activeCompanyId: number,
  activeCompanyTheme: Array<any>,
  activeEventId: number,
  activeTab: string,
  news:{
    [key:string]: any;
  },
  eventNews:{
    [key:string]: any;
  },
  companyInformations:{
    [key:string]: any
  },
  companyInSights: {
    [key:string]: any
  },
  watched: {
    dataList: Array<any>,
    total: number, 
    hasMore: boolean,
    page?: number
  },
  opportunities: any,
  qccOpportunities: any,
  flowOpportunities: any,
  flowQccOpportunities: any,
  eventDetails: any,
  eventQccDetails: any,
  topCompanyEvent: any,
  mainBusinessMap: any,
  insightsTypes: Array<any>,
  companyAnnouncement:{
    [key:string]: any
  },
  isInfo:number,
  supplyChainGraphRefresh:boolean,
  newsDetails: {
    [key: string]: any
  },
  flowNews:Array<any>,
  flowNewsDetails:any,
  resetFlowTab:boolean,
  supplyFromDate: number,
  supplyEndDate: number,
  allRiskType:Array<any>,
  riskTypedefault:Array<any>,
  operationalRiskType:Array<any>,
  reportRiskRequest:any,
  companyParts:Array<any>,
  userCompanyRelation:any,
  companyInfo:any,
  saveStatus:boolean,
  selectRisk:Array<any>,
}

const initialState: StateProps = {
  loading: {},
  selectNode:{},
  activeSupplyChainId:'',
  activeCompanyId: 0,
  activeCompanyTheme:[],
  activeThemeId: 0,
  activeEventId: 0,
  activeTab: 'event',
  news:{},
  companyInformations:{},
  companyInSights: {},
  watched: {
    dataList: [],
    total: 0, 
    hasMore: false,
  },
  opportunities: {},
  qccOpportunities: {},
  flowOpportunities: {},
  flowQccOpportunities: {},
  eventDetails:{},
  eventQccDetails:{},
  topCompanyEvent: {},
  eventNews: {},
  mainBusinessMap: {},
  insightsTypes: [],
  companyAnnouncement:{},
  supplyChainDetail:{},
  supplyChainGraph:{},
  supplyChainList:[],
  supplyChainSearch:[],
  supplyChainQccSearch:[],
  isInfo:1,
  supplyChainGraphRefresh:false,
  newsDetails: {},
  flowNews:[],
  flowNewsDetails:{},
  resetFlowTab:false,
  supplyFromDate: 0,
  supplyEndDate: 0,
  allRiskType:[],
  riskTypedefault:[],
  operationalRiskType:[],
  reportRiskRequest:{},
  companyParts:[],
  userCompanyRelation:{},
  companyInfo:{},
  saveStatus:false,
  selectRisk:[],
}

export const fetchNewsByCompanyId = createAsyncThunk(
  'supply-chain/fetch-news-by-company-id', 
  (params:any, thunkAPI) => {
    const {companyId} = params;
    return thunkAPI.dispatch(checkToken()).then(async resp => {
      const result = await pandora.fetchGetNewsByCompanyId(params)
      return {
        data: result.data,
        companyId
      }
    })
  }
)

export const fetchFlowNewsByCompanyId = createAsyncThunk(
  'supply-chain/fetch-flow-news-by-company-id', 
  (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async resp => {
      const result = await pandora.fetchGetNewsByCompanyId(params)
      return {
        data: result.data
      }
    })
  }
)

export const fetchGetSupplyChainDetail = createAsyncThunk(
  'supply-chain/fetch-get-supply-chain-detail', 
  (params:any, thunkAPI) => {
    const {id} = params;
    return thunkAPI.dispatch(checkToken()).then(async resp => {
      const result = await pandora.fetchGetSupplyChainDetail(params)
      return {
        data: result.data,
        id
      }
    })
  }
)

export const fetchEventNewsByCompanyId = createAsyncThunk(
  'supply-chain/fetch-get-event-news-by-company-id', 
  (params:any, thunkAPI) => {
    const {eventId} = params;
    return thunkAPI.dispatch(checkToken()).then(async resp => {
      const result = await pandora.fetchGetNewsByCompanyId(params)
      return {
        data: result.data,
        eventId
      }
    })
  }
)

export const fetchCompanyInformation = createAsyncThunk(
  '/supply-chain/fetch-company-information',
  async (params:any, thunkAPI) => {
    const {companyId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyInformation(params);
      return {
        ...result,
        companyId,
      }
    })
  }
)
export const fetchGetCompanyInsights = createAsyncThunk(
  '/theme-map/fetch-company-insights',
  async (params: any):Promise<any> => {
    const { companyId, insightType, ...restProps} = params;
    const pageParams = {
      "endTimestamp": Date.now(),
      "page": 0,
      "size": 10,
    }
    const { data } = await pandora.fetchGetCompanyInsights({...restProps, companyId, ...pageParams});
    return {
      data,
      companyId,
      insightType,
    }
  }
)
export const fetchCompanyList = createAsyncThunk(
  'supply-chain/fetch-company-list',
  (params:any, thunkAPI):Promise<any> => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {data} = await pandora.fetchGetSupplyChainCompanyList(params);
      return {
        ...data,
        ...params
      }
    })
  }
)

export const fetchGetSupplyChainList = createAsyncThunk(
  'supply-chain/fetch-get-supply-chain-list',
  async (args:void, thunkAPI) =>{
    return thunkAPI.dispatch(checkToken()).then(async (resp) =>{
      const result = await pandora.fetchGetSupplyChainList()
        return {
          ...result,
        }
    })
  }
)

export const fetchSupplyChainSearchCompany = createAsyncThunk(
  '/supply-chain/fetch-supply-chain-search-company',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchSupplyChainSearchCompany(params);
      return {
        ...result
      }
    })
  }
)
export const fetchSaveUserSupplyChainOperationData = createAsyncThunk(
  '/supply-chain/fetch-save-user-supply-chain-operation-data',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchSaveUserSupplyChainOperationData(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetUserSupplyChainOperation = createAsyncThunk(
  '/supply-chain/fetch-get-user-supply-chain-operation',
  async () => pandora.fetchGetUserSupplyChainOperation()
);

export const fetchGetSupplyChainCompanyEvent = createAsyncThunk(
  '/supply-chain/fetch-get-supply-chain-company-event',
  async (params:any) => pandora.fetchGetSupplyChainCompanyEvent(params)
);

export const fetchQccRunSpider = createAsyncThunk(
  '/supply-chain/fetch-qcc-run-spider',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchQccRunSpider(params);
      return {
        ...result
      }
    })
  }
)

export const addNewSupplyChainData = createAsyncThunk(
  '/supply-chain/fetch-add-new-supply-chain-data',
  async (params:any) => pandora.fetchSaveSupplyChainData(params)
);

export const getSupplyChainList = createAsyncThunk(
  'supply-chain/get-supply-chain-list',
    async () => pandora.fetchGetSupplyChainList()
)

export const fetchDeleteSupplyChainById = createAsyncThunk(
  'supply-chain/fetch-delete-supply-chain-by-id',
    async (params:any) => pandora.fetchDeleteSupplyChainById(params)
)


export const fetchSaveSupplyChainData = createAsyncThunk(
  '/supply-chain/fetch-get-company-insights-event-type-list',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchSaveSupplyChainData(params);
      return {
        ...result
      }
    })
  }
)

export const fetchSupplyChainSearchQccCompany = createAsyncThunk(
  '/supply-chain/fetch-supply-chain-search-qcc-company',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchSupplyChainSearchCompany(params);
      return {
        ...result
      }
    })
  }
)


export const fetchGetEventDataListByCompany = createAsyncThunk(
  '/supply-chain/fetch-get-event-dataList-by-company',
  async (params:any, thunkAPI) => {
    const {companyId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetEventDataListByCompany(params);
      return {
        ...result,
        companyId,
      }
    })
  }
)
export const fetchGetFlowEventDataListByCompany = createAsyncThunk(
  '/supply-chain/fetch-get-flow-event-dataList-by-company',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetEventDataListByCompany(params);
      return {
        ...result,
      }
    })
  }
)

export const fetchGetCompanyQccEventList = createAsyncThunk(
  '/supply-chain/fetch-get-event-qccdataList-by-company',
  async (params:any, thunkAPI) => {
    const {companyId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyQccEventList(params);
      return {
        ...result,
        companyId,
      }
    })
  }
)

export const fetchGetFlowCompanyQccEventList = createAsyncThunk(
  '/supply-chain/fetch-get-flow-event-qccdataList-by-company',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyQccEventList(params);
      return {
        ...result
      }
    })
  }
)

export const fetchGetTopCompanyEvent = createAsyncThunk(
  '/supply-chain/fetch-get-top-company-event',
  async (params:any, thunkAPI) => {
    const {companyId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetTopCompanyEvent(params);
      return {
        ...result,
        companyId,
      }
    })
  }
)

export const fetchGetEventDetail = createAsyncThunk(
  '/supply-chain/fetch-get-event-detail',
  async (params:any, thunkAPI) => {
    const {eventId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetEventDetail(params);
      return {
        ...result,
        eventId,
      }
    })
  }
)

export const fetchGetQccEventDetail = createAsyncThunk(
  '/supply-chain/fetch-get-event-qcc-detail',
  async (params:any, thunkAPI) => {
    const {eventId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetQccEventDetail(params);
      return {
        ...result,
        eventId,
      }
    })
  }
)

export const fetchGetCompanyAnnouncement = createAsyncThunk(
  '/supply-chain/fetch-get-company-announcement',
  async (params:any, thunkAPI) => {
    const {companyId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyAnnouncement(params);
      return {
        ...result,
        companyId,
      }
    })
  }
)


export const fetchGetCompanyInsightsEventTypeList = createAsyncThunk(
  '/supply-chain/fetch-get-company-insights-event-type-list',
  async (params:any, thunkAPI) => {
    const {companyId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyInsightsEventTypeList(params);
      return {
        ...result,
        companyId,
      }
    })
  }
)

export const fetchGetNewsDetail = createAsyncThunk(
  '/supply-chain/fetch-port-news-details',
  async (params:any, thunkAPI) => {
    const {newsId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetNewsDetail(params);
      return {
        ...result,
        newsId,
      }
    })
  }
)

export const fetchGetFlowNewsDetail = createAsyncThunk(
  '/supply-chain/fetch-flow-news-details',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetNewsDetail(params);
      return {
        ...result,
      }
    })
  }
)
export const fetchGetCompanyNameListByNewsIdOrEventId = createAsyncThunk(
  '/supply-chain/fetch-get-company-name-list',
  async (params:any, thunkAPI) => {
    const {newsIdList} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyNameListByNewsIdOrEventId(params);
      return {
        ...result,
        newsIdList,
      }
    })
  }
)


export const fetchGetCompanyMainBusinessTurnover = createAsyncThunk(
  '/supply-chain/fetch-get-company-main-business-turnover',
  async (params:any, thunkAPI) => {
    const {companyId} = params;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyMainBusinessTurnover(params);
      return {
        ...result,
        companyId,
      }
    })
  }
)

export const fetchGetSupplyChainQueryRiskType = createAsyncThunk(
  '/supply-chain/fetch-get-supply-chain-query-risk-type',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetSupplyChainQueryRiskType(params);
      return {
        ...result,
      }
    })
  }
)
export const fetchGetSupplyChainOperationalRiskType = createAsyncThunk(
  '/supply-chain/fetch-get-supply-chain-operation-risk-type',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetSupplyChainQueryRiskType(params);
      return {
        ...result,
      }
    })
  }
)

export const fetchGetConsultantExpert = createAsyncThunk(
  '/supply-chain/fetch-get-consultant-expert',
  async (params:any) => pandora.fetchGetConsultantExpert(params)
);

export const fetchGetReportEventRisk = createAsyncThunk(
  '/supply-chain/fetch-get-report-event-risk',
  async (params:any) => pandora.fetchGetReportEventRisk(params)
);

export const fetchQueryCompanyParts = createAsyncThunk(
  '/supply-chain/fetch-query-company-parts',
  async (params:any) => pandora.fetchQueryCompanyParts(params)
);
export const fetchSaveUserCompanyRelation = createAsyncThunk(
  '/supply-chain/fetch-save-user-company-relation',
  async (params:any) => pandora.fetchSaveUserCompanyRelation(params)
);

export const fetchUploadSupplyChainExcele = createAsyncThunk(
  '/supply-chain/fetch-upload-supply-chain-excele',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchUploadSupplyChainExcele(params);
      return {
        ...result,
      }
    })
  }
)

export const fetchSearchUserCompanyRelation = createAsyncThunk(
  '/supply-chain/fetch-search-user-company-relation',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchSearchUserCompanyRelation(params);
      return {
        ...result,
      }
    })
  }
)

export const fetchGetCompanyInformation = createAsyncThunk(
  '/supply-chain/fetch-get-company-information',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const result = await pandora.fetchGetCompanyInformation(params);
      return {
        ...result,
      }
    })
  }
)





export const supplyChain = createSlice({
  name:'supplyChain',
  initialState,
  reducers: {
    setSelectNode: (state, {payload}) => {
      state.selectNode = payload;
    },
    setActiveTab: (state, {payload}) => {
      state.activeTab = payload;
    },
    setCompanyTheme: (state, {payload}) => {
      state.activeCompanyTheme = payload;
    },
    setNewIsInfo: (state, {payload}) => {
      state.isInfo = payload;
    },
    setActiveSupplyChainId: (state, {payload}) => {
      state.activeSupplyChainId = payload;
    },
    setSaveStatus: (state, {payload}) => {
      state.saveStatus = payload;
    },
    addCompanyNode: (state, {payload}) => {
      state.supplyChainGraph[state.activeSupplyChainId].nodes.push(payload);
    },
    nodeAddEntity: (state, {payload}) => {
      console.log('payload.id===',payload.id)
      let arr = state.supplyChainGraph[state.activeSupplyChainId].nodes
      let nodeIndex = arr.findIndex((item:any) => item.id === payload.id)
      arr[nodeIndex].data.partList = payload.entity
      state.supplyChainGraph[state.activeSupplyChainId].nodes = arr
      state.saveStatus = true
    },
    nodeAddRisk: (state, {payload}) => {
      let arr = state.supplyChainGraph[state.activeSupplyChainId].nodes
      let nodeIndex = arr.findIndex((item:any) => item.id === payload.id)
      arr[nodeIndex].data.riskTypeList = payload.risk
      state.supplyChainGraph[state.activeSupplyChainId].nodes = arr
      state.saveStatus = true
    },
    deletCompanyNode: (state, {payload}) => {
      let arr = state.supplyChainGraph[state.activeSupplyChainId].nodes
      let edgesArr = state.supplyChainGraph[state.activeSupplyChainId].edges
      var newArr = [] as any[];
      for (var i = 0; i < edgesArr.length; i++) {
          if (edgesArr[i].source !== payload && edgesArr[i].target !== payload) {
            newArr.push(edgesArr[i]);
          }
      }
      arr.splice(arr.findIndex((item:any) => item.id === payload), 1)
      state.supplyChainGraph[state.activeSupplyChainId].nodes = arr
      state.supplyChainGraph[state.activeSupplyChainId].edges = newArr
    },
    updateAllNode: (state, {payload}) => {
      state.supplyChainGraph[state.activeSupplyChainId].nodes = payload
    },
    updateAllEdges: (state, {payload}) => {
      state.supplyChainGraph[state.activeSupplyChainId].edges = payload
    },
    setSupplyChainGraphRefresh: (state, {payload}) => {
      state.supplyChainGraphRefresh =payload
    },
    setSupplyChainSearch: (state, {payload}) => {
      state.supplyChainSearch =payload
    },
    setSupplyChainQccSearch: (state, {payload}) => {
      state.supplyChainQccSearch =payload
    },
    setWatched: (state, {payload}) => {
      state.watched = payload
    },
    setSupplyChainList: (state, {payload}) => {
      state.supplyChainList = payload
    },
    setFlowNews: (state, {payload}) => {
      state.flowNews = payload
    },
    setFlowOpportunities: (state, {payload}) => {
      state.flowOpportunities = payload
    },
    setResetFlowTab: (state, {payload}) => {
      state.resetFlowTab = payload
    },
    setSupplyFromDate: (state, {payload}) => {
      state.supplyFromDate = payload
    },
    setSupplyEndDate: (state, {payload}) => {
      state.supplyEndDate = payload
    },
    setReportRiskRequest: (state, {payload}) => {
      state.reportRiskRequest = payload
    },
    setSelectRisk: (state, {payload}) => {
      state.selectRisk = payload
    },
  },
  extraReducers:(builder) => {
    builder.addCase(fetchNewsByCompanyId.pending, state => {
      state.loading.newsLoading = true
    }).addCase(fetchNewsByCompanyId.fulfilled, (state, {payload}) => {
      const {companyId, data} = payload
      state.news[companyId] = data
    }).addCase(fetchFlowNewsByCompanyId.pending, state => {
      state.loading.fetchFlowNewsByCompanyId = true
    }).addCase(fetchFlowNewsByCompanyId.fulfilled, (state, {payload}) => {
      const {data} = payload
      state.flowNews = data
    }).addCase(fetchEventNewsByCompanyId.pending, state => {
      state.loading.fetchEventNewsByCompanyId = true
    }).addCase(fetchEventNewsByCompanyId.fulfilled, (state, {payload}) => {
      const {eventId, data} = payload
      state.eventNews[eventId] = data
    }).addCase(fetchGetSupplyChainList.pending, state => {
      state.loading.fetchGetSupplyChainList = true
    }).addCase(fetchGetSupplyChainList.fulfilled, (state, {payload}) => {
      const {code,data} = payload
      if(code === '0'){
        state.supplyChainList = data
        state.loading.fetchGetSupplyChainList = false
      }
    }).addCase(fetchGetSupplyChainDetail.pending, state => {
      state.loading.fetchGetSupplyChainDetail = true
    }).addCase(fetchGetSupplyChainDetail.fulfilled, (state, {payload}) => {
      const {id, data} = payload
      state.supplyChainDetail[id] = data
      state.supplyChainGraph[id] = data.supplyChainGraph
      state.supplyChainGraphRefresh = true
      state.loading.fetchGetSupplyChainDetail = false
    })
    .addCase(fetchCompanyInformation.pending, state => {
      state.loading.information = true;
    }).addCase(fetchCompanyInformation.fulfilled, (state, {payload}) => {
      const {companyId, data} = payload;
    
      state.companyInformations[companyId] = data
      state.loading.information = false;
    }).addCase(fetchGetCompanyInsights.pending, state => {
      state.loading.insight = true;
    }).addCase(fetchGetCompanyInsights.fulfilled, (state,{payload}) => {
      state.loading.insight = false;
      const {companyId, insightType, data} = payload;
      state.companyInSights = {
        ...state.companyInSights,
        [companyId]: {
          ...state.companyInSights[companyId],
          [insightType]: data
        }
      }
    }).addCase(fetchCompanyList.pending, state => {
      state.loading.fetchCompanyList = true;
    }).addCase(fetchCompanyList.fulfilled,(state, {payload}) => {
      const {size, page, dataList = [], ...restProps} = payload;
        const refactorCompanyList = dataList.length ? dataList.map((item:any) => ({...item, riskPoint: item.companyImpactScoreData.riskPoint,opportunityPoint: item.companyImpactScoreData.opportunityPoint,impactScore: item.companyImpactScoreData.impactScore,impact: item.companyImpactScoreData.impact})) : []
        state.watched = {
          ...restProps,
          dataList: page > 0 ? state.watched.dataList.concat(refactorCompanyList) : refactorCompanyList,
          hasMore: !Boolean(dataList.length < size),
          page: page + 1,
        }      
      state.loading.fetchCompanyList = false;
    }).addCase(fetchSupplyChainSearchCompany.pending, state => {
      state.loading.fetchSupplyChainSearchCompany = true;
    }).addCase(fetchSupplyChainSearchCompany.fulfilled, (state,{payload}) => {
      const {code,data} = payload;
      if(code === '0'){
        state.supplyChainSearch = data
      }
      state.loading.fetchSupplyChainSearchCompany = false;
    }).addCase(fetchSupplyChainSearchQccCompany.pending, state => {
      state.loading.fetchSupplyChainSearchQccCompany = true;
    }).addCase(fetchSupplyChainSearchQccCompany.fulfilled, (state,{payload}) => {
      const {code,data} = payload;
      if(code === '0'){
        state.supplyChainQccSearch = data
      }
      state.loading.fetchSupplyChainSearchQccCompany = false;
    }).addCase(fetchGetEventDataListByCompany.pending, (state) => {
      state.loading.fetchCompanyEventsLoading = true
    }).addCase(fetchGetEventDataListByCompany.fulfilled, (state, {payload}) => {
      const {code, data, companyId} = payload;
      if(code === '0'){
        state.opportunities[companyId] = data;
        state.loading.fetchCompanyEventsLoading = false
      }
    }).addCase(fetchGetCompanyQccEventList.pending, (state) => {
      state.loading.fetchCompanyQccEventsLoading = true
    }).addCase(fetchGetCompanyQccEventList.fulfilled, (state, {payload}) => {
      const {code, data, companyId} = payload;
      if(code === '0'){
        state.qccOpportunities[companyId] = data.dataList;
        state.loading.fetchCompanyQccEventsLoading = false
      }
      
    }).addCase(fetchGetFlowEventDataListByCompany.pending, (state) => {
      state.loading.fetchGetFlowEventDataListByCompany = true
    }).addCase(fetchGetFlowEventDataListByCompany.fulfilled, (state, {payload}) => {
      const {code, data} = payload;
      if(code === '0'){
        state.flowOpportunities = data;
        state.loading.fetchGetFlowEventDataListByCompany = false
      }
    }).addCase(fetchGetFlowCompanyQccEventList.pending, (state) => {
      state.loading.fetchGetFlowCompanyQccEventList = true
    }).addCase(fetchGetFlowCompanyQccEventList.fulfilled, (state, {payload}) => {
      const {code, data} = payload;
      if(code === '0'){
        state.flowQccOpportunities = data;
        state.loading.fetchGetFlowCompanyQccEventList = false
      }
      
    }).addCase(fetchGetTopCompanyEvent.pending, (state) => {
      state.loading.fetchGetTopCompanyEventLoading = true
    }).addCase(fetchGetTopCompanyEvent.fulfilled, (state, {payload}) => {
      const {code, data, companyId} = payload;
      if(code === '0'){
        state.topCompanyEvent[companyId] = data;
        state.loading.fetchGetTopCompanyEventLoading = false
      }
    }).addCase(fetchGetEventDetail.pending, (state) => {
      state.loading.fetchEventDetailLoading = true
    }).addCase(fetchGetEventDetail.fulfilled, (state, {payload}) => {
      const {code, data, eventId} = payload;
      if(code==='0'){
        const {eventEntity, entityGraph,  ...restResult} = data;
        let entityGraphObj = {
          children:[] as any[],
          name:'',
          id:''
        }
        entityGraph.forEach((item:any) => {
          if(item.master){
            entityGraphObj.id =item.entityId
            entityGraphObj.name = item.entityValueZh
          }else{
            entityGraphObj.children.push({...item, name:item.entityValueZh,type:'child'})
          }
        });
        const normlizedData = eventEntity.reduce((all: {[key: string]: any}, dataItem: any) => {
          const {entityList:currentEntities, type} = dataItem;
          return {
                ...all,
                [type]: currentEntities.map((item:any) => ({...item, company_name_en: item.entityValueEn, company_name_zh: item.entityValueZh})),
              }
        }, {});
        state.eventDetails[eventId] = {
          ...restResult,
          graph:{
            data:entityGraphObj
          },
          entities:{
            data_list: normlizedData
          }
        };
      }
      state.loading.fetchEventDetailLoading = false
    }).addCase(fetchGetQccEventDetail.pending, (state) => {
      state.loading.fetchEventQccDetailLoading = true
    }).addCase(fetchGetQccEventDetail.fulfilled, (state, {payload}) => {
      const {code, data} = payload;
      if(code === '0'){
        state.eventQccDetails = data
      }
      state.loading.fetchEventQccDetailLoading = false
    }).addCase(fetchGetCompanyMainBusinessTurnover.pending, (state) => {
      state.loading.fetchGetCompanyMainBusinessTurnover = true
    }).addCase(fetchGetCompanyMainBusinessTurnover.fulfilled, (state, {payload}) => {
      const {code, data} = payload;
      if(code === '0'){
        let obj = {}  as any
        data.forEach((item:any) => {
          obj[item.year] = item
        });
        state.mainBusinessMap = obj;
      }
    }).addCase(fetchGetCompanyInsightsEventTypeList.pending, (state) => {
      state.loading.fetchGetCompanyInsightsEventTypeList = true
    }).addCase(fetchGetCompanyInsightsEventTypeList.fulfilled, (state, {payload}) => {
      const {code, data} = payload;
      if(code === '0'){
        state.insightsTypes = data;
      }
    }).addCase(fetchGetCompanyAnnouncement.pending, (state) => {
      state.loading.fetchGetCompanyAnnouncement = true
    }).addCase(fetchGetCompanyAnnouncement.fulfilled, (state, {payload}) => {
      const {code, companyId, data} = payload;
      if(code === '0'){
        state.companyAnnouncement[companyId] = data;
      }
    }).addCase(fetchGetNewsDetail.fulfilled, (state, {payload})=>{
      const {code, data, newsId} = payload;
      if(code === '0'){
        data.companyList = []
        state.newsDetails[newsId] = data;
      }
    }).addCase(fetchGetFlowNewsDetail.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.flowNewsDetails = data;
      }
    }).addCase(fetchGetCompanyNameListByNewsIdOrEventId.fulfilled, (state, {payload})=>{
      const {code, data, newsIdList} = payload;
      if(code === '0'){
        state.newsDetails[newsIdList[0]].companyList = data;
      }
    }).addCase(fetchUploadSupplyChainExcele.pending, (state) => {
      state.loading.fetchUploadSupplyChainExcele = true
    }).addCase(fetchUploadSupplyChainExcele.fulfilled, (state, {payload}) => {
      console.log('payload==>',payload)
      // const {code, companyId, data} = payload;
      // if(code === '0'){
     
      // }
    }).addCase(fetchQueryCompanyParts.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.companyParts = data;
      }
    }).addCase(fetchSearchUserCompanyRelation.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        state.userCompanyRelation = data;
      }
    }).addCase(fetchGetSupplyChainQueryRiskType.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        var defaultIdArr = []
        for(var i in data){
          if(data[i].defaultConfigure){
            defaultIdArr.push({ 
              id : data[i].id,
              riskType : data[i].riskType
            })
          }
          var num = 0;
          var idArr = []
          // var defaultIdArr = []
          var idObjArr = []
          num = num + data[i].child.length
          for(var l in  data[i].child){
            if(data[i].child[l].defaultConfigure){
              defaultIdArr.push({ 
                id : data[i].child[l].id,
                riskType : data[i].child[l].riskType
              })
            }
            idObjArr.push({ 
              id : data[i].child[l].id,
              riskType : data[i].child[l].riskType
            })
            idArr.push(data[i].child[l].id)
            if(data[i].child[l].child){
              num = num +data[i].child[l].child.length
              var childIdArr = []
              var childIdObjArr = []
              for(var c in data[i].child[l].child){
                if(data[i].child[l].child[c].defaultConfigure){
                  defaultIdArr.push({ 
                    id : data[i].child[l].child[c].id,
                    riskType : data[i].child[l].child[c].riskType
                  })
                }
                childIdObjArr.push({ 
                  id : data[i].child[l].child[c].id,
                  riskType : data[i].child[l].child[c].riskType
                })
                childIdArr.push(data[i].child[l].child[c].id)
                idArr.push(data[i].child[l].child[c].id)
                idObjArr.push({ 
                  id : data[i].child[l].child[c].id,
                  riskType : data[i].child[l].child[c].riskType
                })
              }

            }
            data[i].child[l].idArr = childIdArr
            data[i].child[l].idObjArr = childIdObjArr
          }
          data[i].total = num
          data[i].idArr = idArr
          data[i].idObjArr = idObjArr
        }
        state.allRiskType = data;
        state.riskTypedefault = defaultIdArr
      }
    }).addCase(fetchGetSupplyChainOperationalRiskType.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
        for(var i in data){
          var num = 0;
          var idArr = []
          num = num + data[i].child.length
          for(var l in  data[i].child){
            idArr.push(data[i].child[l].riskType)
          }
          data[i].total = num
          data[i].idArr = idArr
        }
        state.operationalRiskType = data
      }
    }).addCase(fetchGetCompanyInformation.fulfilled, (state, {payload})=>{
      const {code, data} = payload;
      if(code === '0'){
       
        state.companyInfo = data
      }
    })

  }
})

export const {setSelectRisk, setSaveStatus, nodeAddEntity, setReportRiskRequest, nodeAddRisk, deletCompanyNode, setSupplyFromDate, setSupplyEndDate, setResetFlowTab, setFlowOpportunities, setFlowNews, setSupplyChainList, setWatched, updateAllEdges, setSupplyChainSearch, setSupplyChainQccSearch, updateAllNode, setSupplyChainGraphRefresh, addCompanyNode, setActiveSupplyChainId, setNewIsInfo, setActiveTab, setCompanyTheme, setSelectNode} = supplyChain.actions;

const selectBaseState = (state: RootState)  => state.supplyChain;
export const selectSelectNode = createSelector(selectBaseState, state => state.selectNode || {})
export const selectSupplyChainGraphRefresh = createSelector(selectBaseState, state => state.supplyChainGraphRefresh || false)
export const selectActiveSupplyChainId = createSelector(selectBaseState, state => state.activeSupplyChainId || "")

export const selectIsInfo = createSelector(selectBaseState, state => state.isInfo )
export const selectLoadingByType = (type:string) =>  createSelector(selectBaseState, state => state.loading[type] || false);
export const selectNewsById = (id: any) => createSelector(selectBaseState, state => {
  if(!id){
    return []
  }
  return state.news[id] || []
})
export const selectEventNewsById = (id: any) => createSelector(selectBaseState, state => {
  if(!id){
    return []
  }
  return state.eventNews[id] || []
})
export const selectSupplyChainList = createSelector(selectBaseState, state => state.supplyChainList || []);
// export const selectSupplyChainDetailById = (id: any) => createSelector(selectBaseState, state => {
//   if(!id){
//     return []
//   }
//   return state.supplyChainDetail[id] || {}
// })
export const selectSupplyChainDetailById = createSelector(selectBaseState, state => state.supplyChainDetail[state.activeSupplyChainId] || {});

export const selectSupplyChainGraphById = (id: any) => createSelector(selectBaseState, state => {
  if(!id){
    return []
  }
  return state.supplyChainGraph[id] || {}
})
export const selectCompanyInformation = (companyId: any) => createSelector(selectBaseState, state => {
  if(!companyId){
    return {};
  }
  return state.companyInformations[companyId];
})
export const selectTopCompanyEvent = (companyId: any) => createSelector(selectBaseState, state => {
  if(!companyId){
    return [];
  }
  return state.topCompanyEvent[companyId];
})
export const selectInsightList = (companyId:number, insightType: string) => createSelector(selectBaseState, state => {
  if(!companyId || !insightType){
    return []
  }
  return Map(state.companyInSights).getIn([companyId.toString(), insightType], [])
})
export const selectActiveThemeId = createSelector(selectBaseState, state => state.activeThemeId)
export const selectActiveCompanyTheme = createSelector(selectBaseState, state => state.activeCompanyTheme)
export const selectWatchedList = createSelector(selectBaseState, state => state.watched.dataList);
export const watchedFetchMoreOptions = createSelector(selectBaseState, state => {
  const {dataList, ...othersProps} = state.watched;
  return othersProps;
}) 
export const selectEventDetailById = (id:any) => createSelector(
  selectBaseState, state => state.eventDetails[id] || {}
)
export const selectEventQccDetailById =createSelector(selectBaseState, state => state.eventQccDetails || {});

export const selectOpportunitiesDetailById = (id: any) => createSelector(
  selectBaseState, state => state.opportunities[id] || [] 
)
export const selectQccOpportunitiesDetailById = (id: any) => createSelector(
  selectBaseState, state => state.qccOpportunities[id] || [] 
)
export const selectMainBusinessMap = createSelector(selectBaseState, state => state.mainBusinessMap || {});
export const selectInsightsTypes = createSelector(selectBaseState, state => state.insightsTypes || []);
export const selectCompanyAnnouncement = (companyId: any) => createSelector(selectBaseState, state => {
  if(!companyId){
    return [];
  }
  return state.companyAnnouncement[companyId];
})
export const selectSearchCompany = createSelector(selectBaseState, state => state.supplyChainSearch || []);
export const selectSearchQccCompany = createSelector(selectBaseState, state => state.supplyChainQccSearch || []);
export const selectNewsDetailsById = (id: string) =>  createSelector(selectBaseState, state => {
  if(!id){
    return {};
  }
  return state.newsDetails[id]
});

export const selectFlowNewsList = createSelector(selectBaseState, state => state.flowNews || []);
export const selectFlowNewsDetails = createSelector(selectBaseState, state => state.flowNewsDetails || {});
export const selectFlowOpportunities = createSelector(selectBaseState, state => state.flowOpportunities || {});
export const selectFlowQccOpportunities = createSelector(selectBaseState, state => state.flowQccOpportunities || {});
export const selectResetFlowTab = createSelector(selectBaseState, state => state.resetFlowTab || false);
export const selectSupplyFromDate = createSelector(selectBaseState, state => state.supplyFromDate || 0);
export const selectSupplyEndDate = createSelector(selectBaseState, state => state.supplyEndDate || 0);
export const selectAllRiskType = createSelector(selectBaseState, state => state.allRiskType || []);
export const selectOperationalRiskType = createSelector(selectBaseState, state => state.operationalRiskType || []);
export const selectReportRiskRequest = createSelector(selectBaseState, state => state.reportRiskRequest || {});
export const selectCompanyParts = createSelector(selectBaseState, state => state.companyParts || []);
export const selectUserCompanyRelation = createSelector(selectBaseState, state => state.userCompanyRelation || {});
export const selectCompanyInfo = createSelector(selectBaseState, state => state.companyInfo || {});
export const selectSaveStatus = createSelector(selectBaseState, state => state.saveStatus || false);
export const selectSelectRisk = createSelector(selectBaseState, state => state.selectRisk || []);
export const selectRiskTypedefault = createSelector(selectBaseState, state => state.riskTypedefault || []);

export default supplyChain.reducer;
