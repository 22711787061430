import { createSlice, createSelector, createAsyncThunk} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import pandora from '../../api';
import qs from 'qs';
import {PAGE_SIZE} from '../../types/constant';
import {enqueueSnackbar} from '../snackbar/reducer';
import {checkToken} from '../user/reducer';

interface FetchedListProps{
  result?: Array<any>, 
  dataList: Array<any>,
  page_no?: number,
  page_size?: number,
  total?: number,
  total_page?: number,
  data_recommend?: {
    [key:string]: Array<any>
  },
  data_recormend?: any,
  hasMore?: boolean,
}

interface WatchListProps {
  layout: string,
  watched: {
    dataList: Array<any>,
    total: number, 
    hasMore: boolean,
    page?: number
  },
  popularIpo:{
    IPO:Array<any>,
    popular:Array<any>
  },
  recommend: Array<any>, 
  search: FetchedListProps,
  isSearching: boolean,
  loading: {
    [key: string]: boolean
  }
}

const initialState: WatchListProps = {
  layout:'row',
  watched: {
    dataList: [],
    total: 0, 
    hasMore: true,
  },
  recommend: [],
  popularIpo:{
    IPO:[],
    popular:[]
  },
  search: {
    dataList: []
  },
  isSearching: false,
  loading:{}
}

export const searchCompany = createAsyncThunk(
  'search/search-company',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp:any) => pandora.searchCompany(params))
  }
)

export const fetchCompanyList = createAsyncThunk(
  '/watch-list/fetch-company',
  (queryObj:any, thunkAPI):Promise<any> => {
    const {fetchType = 'watched', ...requestProps} = queryObj;
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const response = await pandora.fetchCompanyList(qs.stringify(requestProps));
      return {
        fetchType: fetchType,
        ...response
      }
    } )
  }
)

export const fetchWatchedList = createAsyncThunk(
  'watch-list/fetch-watched-company',
  (params:any, thunkAPI):Promise<any> => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {data} = await pandora.fetchWatchList(params);
      return {
        ...data,
        ...params
      }
    })
  }
)
export const starCompany = createAsyncThunk(
  '/watch-list/star-company',
  (params: {companyId?: number | string, entityId?: number | string}, thunkAPI):Promise<any> => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {code} = await pandora.startedCompany(params);
      if(!code){
        thunkAPI.dispatch(enqueueSnackbar({message: 'stared !', key:'staredCompany',options:{variant: 'success'}}))
        return thunkAPI.dispatch(fetchWatchedList({size: PAGE_SIZE*2, page: 0}))
      }
    })
  }
)
  
export const unstarCompany = createAsyncThunk(
  '/watch-list/star-company',
  (params: {companyId?: number | string, entityId?: number | string}, thunkAPI):Promise<any> => {
    return thunkAPI.dispatch(checkToken()).then(async (resp) => {
      const {code} = await pandora.unstarCompany(params);
      if(!code){
        thunkAPI.dispatch(enqueueSnackbar({message: 'unstared!', key:'unstarCompany',options:{variant: 'success'}}))
        thunkAPI.dispatch(fetchWatchedList({size: PAGE_SIZE*2, page: 0}))
        return thunkAPI.dispatch(removeWatchedById({id: params.companyId}))
      }
    })
  }
)

export const addOrCancelUserWatchList = createAsyncThunk(
  'watch-list/add-or-cancel-user-watch-list',
  async (params:any, thunkAPI) => {
    return thunkAPI.dispatch(checkToken()).then(async (resp:any) => pandora.addOrCancelUserWatchList(params))
  }
)

export const watchList = createSlice({
  name:'watchList',
  initialState,
  reducers: {
    setSearchListStatus: (state, {payload}) => {
      const item = state.search.dataList.findIndex(item => item.company_id === payload);
      if(typeof item === 'number'){
        state.search.dataList[item] = {
          ...state.search.dataList[item],
          is_watching: !state.search.dataList[item].is_watching
        }
      }
    },
    setSearching: (state, {payload}) => {
      state.isSearching = payload
    },
    setListLayout: (state, {payload}) => {
      state.layout = payload;
    },
    removeWatchedById: (state, {payload}) => {
      const refactorList = state.watched.dataList.filter(item => Number(item.companyId) !== Number(payload.id));
      state.watched.dataList = refactorList;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompanyList.pending, state => {
      state.loading.fetchList = true
    }).addCase(fetchCompanyList.fulfilled, (state,{ payload }) => {
      const {success, fetchType, data_recommend = {}} = payload;
      if(success){
        if(fetchType === 'recommend'){
          state.popularIpo = data_recommend
          state.recommend = Object.entries(data_recommend).map((recommend: [string, any]) => (recommend[1] as Array<any>).map(item => ({
            ...item, 
            type: recommend[0], 
            companyNameEn: item.company_name_en, 
            companyNameZh: item.company_name_zh, 
            exchangeCode: item.exchange_number,
            companyId: item.company_id, 
            exchangeEn: item.exchange_en,
            exchangeZh: item.exchange_zh,
          }))).flat();
        }
      }
    }).addCase(searchCompany.fulfilled, (state, {payload}) => {
      const {code, companyList, watchList} = payload.data;
      if(!code){
        companyList.forEach((item:any) => {
          item.companyNameEn = item.companyNameEn.toLowerCase()
        });
        console.log('companyList',companyList)
        var refactorList = companyList.map((item:any) => watchList.includes(item.companyId) ? ({...item, is_watching: 1}) : ({...item, is_watching: 0}));
        console.log('refactorList',refactorList)
        state.search = {
          dataList: refactorList
        }
      }
    }).addCase(fetchWatchedList.pending, state => {
      state.loading.fetchWatchedList = true;
    }).addCase(fetchWatchedList.fulfilled,(state, {payload}) => {
      const {code, size, page, companyList = [], ...restProps} = payload;
      if(!code){
        const refactorCompanyList = companyList.length ? companyList.map((item:any) => ({...item, impact_score: item.impactScore})) : []
        state.watched = {
          ...restProps,
          dataList: page > 0 ? state.watched.dataList.concat(refactorCompanyList) : refactorCompanyList,
          hasMore: !Boolean(companyList.length < size),
          page: page + 1,
        }
      }
      state.loading.fetchWatchedList = false;
    }).addCase(addOrCancelUserWatchList.fulfilled,(state, {payload}) =>{
      const {code} = payload;
      if(!code){
        console.log("code",code)
      }
    })
  }
})

export const {setSearchListStatus, setSearching, setListLayout, removeWatchedById} = watchList.actions;

const selectBaseState =  (state: RootState)  => state.watchList;
export const selectWatchedList = createSelector(selectBaseState, state => state.watched.dataList);
export const selectListLayout = createSelector(selectBaseState, state => state.layout);
export const selectRecommendList = createSelector(selectBaseState, state => state.recommend || []);
export const selectPopularIpo = createSelector(selectBaseState, state => state.popularIpo || {});
export const selectSearchList = createSelector(selectBaseState, state => state.search.dataList);
export const watchedFetchMoreOptions = createSelector(selectBaseState, state => {
  const {dataList, ...othersProps} = state.watched;
  return othersProps;
}) 
export const selectIsSearching = createSelector(selectBaseState, state => state.isSearching)
export const selectLoadingByType = (type: string) => createSelector(selectBaseState, state => state.loading[type] || false)

export default watchList.reducer;
